import React from 'react';

const ThumbComponent = props => {

    const { thumbs, handleClick } = props;

    return (
        <div className="thumbs">
            {thumbs.map((image, index) => {
                return (
                    <div onClick={() => handleClick(index)} className={`thumb ${(image.video || image.video_url) && !image.picture_thumbnail ? 'video' : ''}`} key={index}>
                        <img src={image.picture_thumbnail} alt="" />
                    </div>
                )
            })}
        </div>
    );
};

export default ThumbComponent;