import React from "react";
import "./styles.scss";
import Loading from "../Loading";

export default function ButtonBgProfile(props) {
  const { text, onclick, w, margin, disabled } = props;
  return (
    <button
      disabled={disabled}
      style={{ maxWidth: w + "px", margin: margin }}
      type="button"
      onClick={() => onclick()}
      className="buttonWithBgProfile"
    >
      {(disabled && <Loading />) || text || "Save"}
    </button>
  );
}
