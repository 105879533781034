import React, { Component } from 'react';
const Style = require("../style/TitleShadowStyle.scss");

class TitleShadowComponent extends Component {

    typesTag(tag, children, className = "") {
        return `<${tag} class="${className}" dangerouslySetInnerHTML={{__html: ${children} }}><span>${children}</span></${tag}>`;
    }

    render() {
        const { children, position, type, disableMargin, tagClass } = this.props;

        return (
            <div
                className={`${Style.titleShadow} ${position || ''} ${disableMargin ? 'disable-margin' : ''} `}
                dangerouslySetInnerHTML={{ __html: this.typesTag(type, children, tagClass) }} />
        );
    }
}

export default TitleShadowComponent;