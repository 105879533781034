import React, { Component } from "react";
import MenuComponent from "../../../menu/components/MenuComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import MessagesSidebarComponent from "./MessagesSidebarComponent";
import MessagesChatComponent from "./MessagesChatComponent";
import "../../styles/MessagesView/MessagesViewComponent.scss";
import { withRouter } from "react-router";
import MessagesFilterComponent from "./MessagesFilterComponent";

class MessagesViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFilter: "unread-messages",
    };
  }

  handleMessagesFilter(currentFilter) {
    this.setState({
      currentFilter: currentFilter,
    });
  }

  render() {
    const { params } = this.props.match;

    return (
      <div id="messages-view-component">
        <MenuComponent />
        <section id="messages-filter-container">
          <MessagesFilterComponent
            onFilter={(currentFilter) =>
              this.handleMessagesFilter(currentFilter)
            }
          />
        </section>
        <section id="messages">
          <MessagesSidebarComponent filter={this.state.currentFilter} />
          {params.subjectId && <MessagesChatComponent />}
        </section>
        <FooterComponent />
      </div>
    );
  }
}

export default withRouter(MessagesViewComponent);
