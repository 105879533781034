import Axios from "axios";
import React from "react";
import { createPortal } from "react-dom";

import iconError from "../../../../imgs/icon-error.svg";
import iconSucess from "../../../../imgs/icon-success.svg";
import closeEye from "../../../../imgs/eye-closed.svg";
import openEye from "../../../../imgs/eye-open.svg";

import "./styles.scss";
import Loading from "../../../../components/Loading";

export default function NewPassRoute() {
  const [valuesForm, setValuesForm] = React.useState({
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [errorState, setErrorState] = React.useState({
    email: false,
    password: false,
    ConfirmPassowrd: false,
  });
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [messageRequest, setMessageRequest] = React.useState("");
  const [lookPass, setLookPass] = React.useState(false);
  const [lookConfirmPass, setLookConfirmPass] = React.useState(false);

  const urlQueryParams = window.location.search;
  const queryParams = new URLSearchParams(urlQueryParams);
  const hash = queryParams.get("hash");

  if (!hash) {
    window.location.replace("/");
  }

  function changeValuesForm(inputName, newValue) {
    const values = { ...valuesForm };
    values[inputName] = newValue;

    setValuesForm(values);
  }

  async function updatePass() {
    var validate = [];
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(valuesForm.email)) {
      setErrorState((state) => ({ ...state, email: true }));
      validate.push("email");
    } else {
      setErrorState((state) => ({ ...state, email: false }));
    }

    if (valuesForm.password === "") {
      setErrorState((state) => ({ ...state, password: true }));
      validate.push("password");
    } else {
      setErrorState((state) => ({ ...state, password: false }));
    }

    if (valuesForm.password_confirmation !== valuesForm.password) {
      setErrorState((state) => ({ ...state, ConfirmPassowrd: true }));
      validate.push("confirmPassword");
    } else {
      setErrorState((state) => ({ ...state, ConfirmPassowrd: false }));
    }

    if (validate.length > 0) {
      return;
    }

    setDisabledItem(true);

    const data = {
      hash: hash,
      email: valuesForm.email,
      password: valuesForm.password,
      password_confirmation: valuesForm.password_confirmation,
    };

    Axios.defaults.baseURL = process.env.REACT_APP_NEW_API_URL;

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/auth/define-password`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    try {
      let response = await Axios(config);
      setMessageRequest({ msg: response.data.message, id: 1 });
      setTimeout(() => {
        window.location.href = window.location.origin + "/";
      }, 1500);
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    } finally {
      setDisabledItem(false);
    }
  }

  return (
    <>
      {createPortal(
        <div className="container-change-pass">
          <div className="box">
            <h3>PASSWORD CHANGE</h3>
            <div>
              <div className="input">
                <p>Email</p>
                <input
                  type="text"
                  onChange={(e) => changeValuesForm("email", e.target.value)}
                  value={valuesForm.email}
                />
                {errorState.email && <small>Email must be valid.</small>}
              </div>
              <div className="input">
                <p>New password</p>
                <div className="input-box">
                  <input
                    disabled={disabledItem}
                    value={valuesForm.password}
                    onChange={(e) =>
                      changeValuesForm("password", e.target.value)
                    }
                    type={lookPass ? "text" : "password"}
                  />
                  <button onClick={() => setLookPass(!lookPass)}>
                    {lookPass ? (
                      <img src={closeEye} alt="" />
                    ) : (
                      <img src={openEye} alt="" />
                    )}
                  </button>
                </div>
                {errorState.password && <small>Password must be valid.</small>}
              </div>

              <div className="input">
                <p>Type again the new password:</p>
                <div className="input-box">
                  <input
                    disabled={disabledItem}
                    value={valuesForm.password_confirmation}
                    onChange={(e) =>
                      changeValuesForm("password_confirmation", e.target.value)
                    }
                    type={lookConfirmPass ? "text" : "password"}
                  />
                  <button onClick={() => setLookConfirmPass(!lookConfirmPass)}>
                    {lookConfirmPass ? (
                      <img src={closeEye} alt="" />
                    ) : (
                      <img src={openEye} alt="" />
                    )}
                  </button>
                </div>
                {errorState.ConfirmPassowrd && (
                  <small>Password not match.</small>
                )}
              </div>

              {messageRequest && (
                <p
                  className="container-renew-pass__message-request"
                  style={{
                    color: `${messageRequest.id === 1 ? "green" : "red"}`,
                  }}
                >
                  {messageRequest.msg}
                  <img
                    src={messageRequest.id === 1 ? iconSucess : iconError}
                    alt="icone de feedback da request"
                  />
                </p>
              )}
            </div>
            <button onClick={updatePass} disabled={disabledItem}>
              {disabledItem ? <Loading /> : "Save"}
            </button>
          </div>
        </div>,
        document.body
      )}
    </>
  );
}
