import Axios from "axios";
import React from "react";
import { createPortal } from "react-dom";
import iconError from "../../imgs/icon-error.svg";
import iconSucess from "../../imgs/icon-success.svg";
import ButtonBgProfile from "../buttonWithBgProfile";
import "./styles.scss";

import closeEye from "../../imgs/eye-closed.svg";
import openEye from "../../imgs/eye-open.svg";

export default function NewPass(props) {
  const { email, changeStep } = props;
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [lookPass, setLookPass] = React.useState(false);
  const [newLookPass, setNewLookPass] = React.useState(false);
  const [errorState, setErrorState] = React.useState({
    password: false,
    confPassword: false,
  });

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => (document.body.style.overflow = "auto");
  }, []);

  const [valuesForm, setValuesForm] = React.useState({
    email: email,
    password: "",
    password_confirmation: "",
    code: localStorage.getItem("codePassChange"),
  });
  const [messageRequest, setMessageRequest] = React.useState("");

  function changeValuesForm(inputName, newValue) {
    const values = { ...valuesForm };
    values[inputName] = newValue;

    setValuesForm(values);
  }

  async function updateUser() {
    var validate = [];

    if (valuesForm.password.length < 8) {
      setErrorState((state) => ({ ...state, password: true }));
      validate.push("password");
    } else {
      setErrorState((state) => ({ ...state, confPassword: false }));
    }

    if (valuesForm.password_confirmation !== valuesForm.password) {
      setErrorState((state) => ({ ...state, confPassword: true }));
      validate.push("confPassword");
    } else {
      setErrorState((state) => ({ ...state, confPassword: false }));
    }

    if (validate.length > 0) {
      return;
    }

    setDisabledItem(true);

    const data = {
      password: valuesForm.password,
      password_confirmation: valuesForm.password_confirmation,
      code: valuesForm.code,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/auth/change-password`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    try {
      let response = await Axios(config);
      setMessageRequest({ msg: response.data.message, id: 1 });
      setDisabledItem(true);
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
      setDisabledItem(false);
    } finally {
      setTimeout(() => {
        changeStep("");
      }, 3200);
    }
  }

  return (
    <>
      {createPortal(
        <div className="container-change-pass">
          <div className="box-change-password">
            <div onClick={() => changeStep("")} className="closed-box">
              X
            </div>
            <h3>PASSWORD CHANGE</h3>
            <p>New password</p>

            <div className="input-div">
              <button onClick={() => setLookPass(!lookPass)}>
                {lookPass ? (
                  <img src={closeEye} alt="" />
                ) : (
                  <img src={openEye} alt="" />
                )}
              </button>

              <input
                disabled={disabledItem}
                value={valuesForm.password}
                onChange={(e) => changeValuesForm("password", e.target.value)}
                type={lookPass ? "text" : "password"}
              />
            </div>
            <p className="error">
              {errorState.password && "Password must be 8 character at least."}
            </p>

            <p>Type again the new password:</p>
            <div className="input-div">
              <button onClick={() => setNewLookPass(!newLookPass)}>
                {newLookPass ? <img src={closeEye} alt=""/> : <img src={openEye} alt=""/>}
              </button>

              <input
                type={newLookPass ? "text" : "password"}
                disabled={disabledItem}
                value={valuesForm.password_confirmation}
                onChange={(e) =>
                  changeValuesForm("password_confirmation", e.target.value)
                }
              />
            </div>
            <p className="error">
              {errorState.confPassword && "Confirm password not match."}
            </p>

            {messageRequest && (
              <p
                className="container-renew-pass__message-request"
                style={{
                  color: `${messageRequest.id === 1 ? "green" : "red"}`,
                }}
              >
                {messageRequest.msg}
                <img
                  src={messageRequest.id === 1 ? iconSucess : iconError}
                  alt="icone de feedback da request"
                />
              </p>
            )}

            {!disabledItem && (
              <ButtonBgProfile
                disabled={disabledItem}
                onclick={updateUser}
                w="133"
                margin="15px 0 0 0 "
              />
            )}
          </div>
        </div>,
        document.body
      )}
    </>
  );
}
