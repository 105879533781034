import React, { Component } from "react";
import "../style/MenuCollection.scss";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router'
import LightboxHalfScreenComponent from "../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import md5 from "blueimp-md5";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import mixpanel from "mixpanel-browser";


class MenuCollectionComponent extends Component {
  constructor(props) {
    super(props);

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const pricelist = params.get("pricelist");

    this.state = {
      showModal: false,
      showModalImport: false,
      showModalReplaceCart: false,
      loadingExport: false,
      showModalGoToCart: false,
      operationWord: 'created',
      cartId: "",
      loadingImport: false,
      loadingReplace: false,
      loadingAddToCart: false,
      purchaseOrderDraft: {},
      importFile: "",
      pricelist: pricelist
    };
  }

  componentDidUpdate(prevProps) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const newPricelist = params.get("pricelist");
    if (newPricelist !== this.state.pricelist) {
      this.setState({
        pricelist: newPricelist,
      });
    }
  }

  goHref(url) {
    window.open(`${url}`, "_blank");
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  openModal() {
    this.setState((state) => ({
      showModal: !state.showModal,
    }));
  }

  handleImportInput(e) {
    e.target.parentElement.setAttribute('data-text', e.target.files[0].name);
    this.setState({
      importFile: e.target.files[0]
    })
  }

  sendImport(e) {
    e.preventDefault();
    this.setState({ loadingImport: true })
    const form = new FormData()
    form.append('collection', this.props.fullCollection.id)
    form.append('file', this.state.importFile)

    axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/pre-import`, form).then(({ data }) => {

      if (data.productsNotFound.length > 0) {
        Swal.fire({
          title: "Products not found",
          text: `Products not found in collection`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }
      if (data.productsVariationOrSizeNotFound.length > 0) {
        Swal.fire({
          title: "Products variation or size not found",
          text: `Products variation or size not found in collection`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }
      if (data.productsWithNoSize.length > 0) {
        Swal.fire({
          title: "Products with no size",
          text: `Products without size in the system`,
          icon: "error",
          confirmButtonColor: "#000"
        })
        this.setState({ loadingImport: false })
        return
      }

      this.setState({ purchaseOrderDraft: data.purchaseOrderDraft })

      if (data.hasOpenedCart) {
        this.setState({
          loadingImport: false,
          showModalImport: false,
          showModalReplaceCart: true
        })
      } else {
        axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, data.purchaseOrderDraft)
          .then(({ data }) => {
            this.setState({
              loadingImport: false,
              operationWord: 'created',
              cartId: data.data.id,
              showModalImport: false,
              showModalGoToCart: true
            })
          })
          .catch((error) => {
            this.setState({
              loadingImport: false,
            })
            Swal.fire({
              title: "Error",
              text: error.response.data.cartProducts,
              icon: "error",
              confirmButtonColor: "#000"
            })
          })
      }

    }).catch((error) => {
      if (error.response.data.file) {
        Swal.fire({
          title: "Invalid extension!",
          text: 'Only XLSX and XLS files are accepted.',
          icon: "error",
          confirmButtonColor: "#000"
        })
      } else {
        Swal.fire({
          title: "Invalid file!",
          text: 'The uploaded file is invalid.',
          icon: "error",
          confirmButtonColor: "#000"
        })
      }
      this.setState({ loadingImport: false })
    })
  }

  sendReplace(replace = true) {
    if (replace) {
      this.setState({ loadingReplace: true })
      axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, {
        ...this.state.purchaseOrderDraft,
        createNewCart: true
      }).then(({ data }) => {
        this.setState({
          loadingReplace: false,
          operationWord: 'replaced',
          cartId: data.data.id,
          showModalReplaceCart: false,
          showModalGoToCart: true
        })
      })
        .catch((error) => {
          this.setState({
            loadingReplace: false,
          })
          Swal.fire({
            title: "Error",
            text: error.response.data.cartProducts,
            icon: "error",
            confirmButtonColor: "#000"
          })
        })
    } else {
      this.setState({ loadingAddToCart: true })
      axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/purchase-order-draft/confirm-import`, {
        ...this.state.purchaseOrderDraft,
        createNewCart: false
      }).then(({ data }) => {
        this.setState({
          loadingAddToCart: false,
          operationWord: 'updated',
          cartId: data.data.id,
          showModalReplaceCart: false,
          showModalGoToCart: true
        })
      })
        .catch((error) => {
          this.setState({
            loadingAddToCart: false,
          })
          Swal.fire({
            title: "Error",
            text: error.response.data.cartProducts,
            icon: "error",
            confirmButtonColor: "#000"
          })
        })
    }
  }

  generateFiltersCreateMyOwnList() {
    const filters = this.props.getFilters();
    const urlSearchParams = new URLSearchParams();
    if (filters.categories) {
      const categories = filters.categories.join(",");
      urlSearchParams.set("categories", categories);
    }

    if (this.props.selectedSegment) {
      urlSearchParams.set("segments", this.props.selectedSegment);
    }

    return urlSearchParams.toString();
  }

  downloadLinesheet() {
    this.mixpanelTrackDownload('linesheet')

    const { fullCollection } = this.props;
    const { pricelist } = this.state;

    if ([null, '', undefined].includes(pricelist)) {
      return this.goHref(fullCollection.lookbook_url);
    }


    const linesheetUrls = fullCollection.linesheet || {};

    if (!Object.keys(linesheetUrls).includes(pricelist)) {
      return this.goHref(fullCollection.lookbook_url);
    }


    return this.goHref(linesheetUrls[pricelist]);
  }

  downloadEditableList() {
    this.mixpanelTrackDownload('editable_linesheet')

    window.location.assign(this.props.fullCollection.purchase_order_draft[this.state.pricelist])
  }

  downloadListOfProduct() {
    this.mixpanelTrackDownload('product_list')

    window.open(this.props.fullCollection.list_of_product[this.state.pricelist], '_blank');
  }

  mixpanelTrackDownload(content) {
    if (![true, 'true'].includes(process.env.REACT_APP_MIXPANEL_ENABLED)) {
      return
    }

    const seller = this.props.collections.selected_seller;
    const buyer = JSON.parse(window.localStorage.getItem("buyerData"))

    if (window.localStorage.getItem("userData")) {
      mixpanel.identify(`${JSON.parse(window.localStorage.getItem("userData")).id}`);
    }
    mixpanel.track("Download", {
      seller: seller.name,
      seller_id: seller.id,
      buyer: buyer.name,
      buyer_id: buyer.id,
      content,
    });
  }

  render() {
    const { fullCollection, t } = this.props;
    
    return (
      <div>
        {fullCollection && (
          <ul
            id="menuCollection"
            className={fullCollection.press_release ? "justify-center" : ""}
          >
            {fullCollection.lookbook_url &&
              <li onClick={() => this.downloadLinesheet()}>
                {t("Download linesheet", "Download linesheet")}
              </li>
            }

            {this.state.pricelist &&
              <>
                {fullCollection.list_of_product && fullCollection.list_of_product[this.state.pricelist] &&
                  <li onClick={() => this.downloadListOfProduct()}>
                    {this.state.loadingExport ? (
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    ) : t("Download list of products", "Download list of products")}
                  </li>
                }
              </>
            }

            <li>
              <Link
                to={`/createmyownlist/${fullCollection.id}?${this.generateFiltersCreateMyOwnList()}`}>
                {t("Create my list of products", "Create my list of products")}
              </Link>
            </li>

            {this.state.pricelist &&
              <>
                {fullCollection.purchase_order_draft && fullCollection.purchase_order_draft[this.state.pricelist] &&
                  <li onClick={() => this.downloadEditableList()}>
                    {this.state.loadingExport ? (
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    ) : t("Download Editable List", "Download Editable List")}
                  </li>
                }

                <li onClick={() => this.setState({ showModalImport: true })}>
                  {t("Import Edited List", "Import Edited List")}
                </li>
              </>
            }

            {fullCollection.press_release && (
              <li onClick={() => this.openModal()}>
                {t("Press release", "Press release")}
              </li>
            )}
          </ul>
        )}
        {this.state.showModal && (
          <LightboxHalfScreenComponent close={this.closeModal.bind(this)}>
            <h1>{t("Press release", "Press release")}</h1>
            <div
              dangerouslySetInnerHTML={{ __html: fullCollection.press_release }}
            />
          </LightboxHalfScreenComponent>
        )}
        {this.state.showModalImport && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showModalImport: false })}
            extraClass="modal-import"
          >
            <h1><strong>IMPORT EDITED LIST OF PRODUCTS</strong></h1>
            <div className="modal-content">
              <h2>SELECT FILE FOR IMPORT IN COLLECTION</h2>
              <h3>{this.props.fullCollection.name}</h3>
              <br />
              <form onSubmit={this.sendImport.bind(this)}>

                <div class="file-upload-wrapper" data-text="Select your file!">
                  <input required type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.handleImportInput.bind(this)} />
                </div>

                <button disabled={this.state.loadingImport} type="submit" className="btn-import">
                  {this.state.loadingImport ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "IMPORT"}
                </button>
              </form>
            </div>
          </LightboxHalfScreenComponent>
        )}
        {this.state.showModalReplaceCart && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showModalReplaceCart: false })}
            extraClass="modal-replace"
          >
            <h2>You've already initiated a cart for</h2>
            <div className="modal-content">
              <h1><strong>{this.props.fullCollection.seller.name} - {this.props.fullCollection.name}</strong></h1>
              <br />
              <div className="replace-buttons">
                <button disabled={this.state.loadingReplace || this.state.loadingAddToCart} onClick={this.sendReplace.bind(this)} className="btn-replace">
                  {this.state.loadingReplace ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "REPLACE IT"}
                </button>
                <button disabled={this.state.loadingReplace || this.state.loadingAddToCart} onClick={this.sendReplace.bind(this, false)} className="btn-add">
                  {this.state.loadingAddToCart ? (
                    <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                  ) : "ADD PRODUCTS TO THE EXISTING CART"}
                </button>
              </div>

            </div>
          </LightboxHalfScreenComponent>
        )}
        {this.state.showModalGoToCart && (
          <LightboxHalfScreenComponent
            close={() => this.setState({ showModalGoToCart: false })}
            extraClass="modal-go-to-cart"
          >
            <h1><strong>Your cart was successfully {this.state.operationWord}</strong></h1>
            <div className="modal-content">
              <Link to={`/cart/${this.state.cartId}`}>
                <button className="btn-go-to-cart">
                  Go to CART to place the order
                </button>
              </Link>
            </div>
          </LightboxHalfScreenComponent>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(MenuCollectionComponent));
