import React, {Component} from 'react';
import './style/HighlightG.scss';
import { withTranslation } from 'react-i18next';
import Buttons from '../buttons/Buttons';

class HighlightG extends Component {
    render() {
        return (
            <section id="HeaderHighLightG" className={this.props.id}>
                <div className="texture2" style={{
                    '--image': `url(${this.props.data.image})`,
                    '--image-mobile': `url(${this.props.data.image_mobile || this.props.data.image})`,
                }} />
                <div className="logoAndText">
                    <h1>{this.props.data.title || 'THE DIGITAL PLATFORM BUILT FOR THE FUTURE OF FASHION'}</h1>
                    <p>{this.props.data.text}</p>
                    <Buttons
                        buttons={this.props.data.buttons}
                        openRegister={this.props.openRegister}
                        open={this.props.open}
                    />
                </div>
            </section>
        );
    }
}

export default withTranslation()(HighlightG);
