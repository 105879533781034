import React from 'react';
import CollectionComponent from "./CollectionComponent";
import BaseComponent from "../../../shared/BaseComponent";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import { withRouter } from 'react-router-dom';

class CollectionViewComponent extends BaseComponent {
    headers = {
        headers: {Authorization: `Bearer ${window.localStorage.getItem('access_token')}`},
    };

    // url = `${process.env.REACT_APP_API_URL}/api/products/`;
    url = `${process.env.REACT_APP_NEW_API_URL}/v1/products`;

    endPoint = `?sellers=${this.props.sellerSlug}&collections=${this.props.collectionId}&pricelist=${window.localStorage.getItem("user.pricelist") || new URLSearchParams(this.props.location.search).get("pricelist") || ""}`;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            render: false,
            loading: true,
            loadingProduct: true,
            param: ""
        };
    }

    setCurrentBrand(key) {
        this.setState({
            stateBrand: key
        });
    }

    componentDidMount() {
        const pricelistParam = new URLSearchParams(this.props.location.search).get("pricelist")
        const pricelistLocalStorage = window.localStorage.getItem("user.pricelist") !== null ? window.localStorage.getItem("user.pricelist") : ""
        
        if(window.localStorage.getItem("user.pricelist") === "null" || pricelistParam === "null"){
            window.localStorage.removeItem("user.pricelist")
            const params = new URLSearchParams(window.location.search);
            params.set('pricelist', "");
            window.location.href = window.location.origin + window.location.pathname + "?" + params.toString();
        }
        if(pricelistParam && !["null", null, 0, '0'].includes(pricelistParam)){
            localStorage.setItem('user.pricelist', pricelistParam)
        } else {
            if(pricelistLocalStorage && pricelistLocalStorage !== "null"){
                const params = new URLSearchParams(window.location.search);
                params.set('pricelist', pricelistLocalStorage);
                this.props.history.replace({ search: params.toString() });
            }
        }
        this.getProducts(this.endPoint);
    }
    
    render() {
        if(this.state.loading) {
            return (<LoadingComponent />)
        }
        
        return (
            <div>
                <CollectionComponent
                    loading={this.state.loadingProduct}
                    results={this.state.products}
                    isCollection={true}
                    fullCollection={this.props.fullCollection}
                    isBrand={true}
                    isBrandPage={true}
                    isSegment={true}
                    isMenuCollection={true}
                    getFilters={this.getFilters.bind(this)}
                    endPoint={this.endPoint}
                    setFilters={this.setFilters.bind(this)}
                    getProducts={this.getProducts.bind(this)}
                    handleClick={this.clearAllFilter.bind(this)}
                    clearAllFilter={this.clearAllFilter.bind(this)}
                    brandData={this.props.brandData}
                />
            </div>
        );
    }
}

export default withRouter(CollectionViewComponent);
