import React, { Component } from "react";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import LightBoxComponent from "../../shared/lightbox/components/LightBoxComponent";
import { withTranslation } from "react-i18next";
import axios from "axios";
import { withRouter } from "react-router-dom";

class VincularEventSegmentViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorVincular: false,
    };
  }

  componentDidMount() {
    const data = {
      event_segment: this.props.match.params.segment_slug,
    };

    let userEventSegments = [];

    if (window.localStorage.getItem("user.event_segments")) {
      userEventSegments = userEventSegments.concat(
        JSON.parse(window.localStorage.getItem("user.event_segments"))
      );
    }

    if (
      userEventSegments.filter((item) => item === data.event_segment).length > 0
    ) {
      return this.props.history.push("/" + data.event_segment);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/vincular-event-segment/`,
        data
      )
      .then(() => {
        userEventSegments = userEventSegments.concat([data.event_segment]);

        window.localStorage.setItem(
          "user.event_segments",
          JSON.stringify(userEventSegments)
        );

        if (localStorage.getItem("redirectBrand")) {
          window.location.href = "/" + localStorage.getItem("redirectBrand");
          localStorage.removeItem("redirectBrand");
        } else {
          this.props.history.push("/" + data.event_segment);
        }
      })
      .catch(() => {
        this.setState({
          errorVincular: true,
        });
      });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <LightBoxComponent
          isOpen={this.state.errorVincular}
          handleEventClose={() => this.props.history.push("/")}
        >
          {t(
            "An error ocurred trying to tag the event. Please, try again later.",
            "An error ocurred trying to tag the event. Please, try again later."
          )}
        </LightBoxComponent>
        <LoadingComponent />
      </div>
    );
  }
}

export default withTranslation()(withRouter(VincularEventSegmentViewComponent));
