import React from 'react';
import '../style/BrandBoxComponentStyle.scss';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

const BrandBoxComponent = (props) => {
    const {brand} = props;
    const logo = brand.list_image_thumbnail || brand.list_image;

    return (
        <>
            {brand.has_active_collection ? (
                <div className='brand-box brand-box-component'>
                    <div className={brand.name}/>
                    <div
                        className='containerImage'
                        key={brand.id}
                        style={{
                            backgroundImage: logo ? `url(${logo})` : 'none',
                        }}
                        onClick={() => props.history.push(`/${brand.slug}`)}>
                        <Link className='brand--name' to={`/${brand.slug}`}>
                            {brand.name}
                        </Link>
                    </div>
                </div>
            ) : (
                <div className='brand-box brand-box-component'>
                    {/* <button onClick={() => props.history.push(`/${brand.slug}`)}>NO ACTIVE COLLECTION</button> */}
                    <div className={brand.name}/>
                    <Link className='brand--name' to={`/${brand.slug}`}>
                        {brand.name}
                    </Link>
                    <div  onClick={() => props.history.push(`/${brand.slug}`)}></div>
                    <div
                        className='containerImage'
                        key={brand.id}
                        style={{
                            backgroundImage: logo ? `url(${logo})` : 'none',
                        }}
                        onClick={() => props.history.push(`/${brand.slug}`)}></div>
                </div>
            )}
        </>
    );
};

export default withRouter(BrandBoxComponent);
