import React, { Component } from 'react';
import "../style/InfoStyle.scss"

class InfoComponent extends Component {
    render() {
        const { info, meta } = this.props;

        if (info === undefined) {
            return <div>&nbsp;</div>
        }

        return (
            <div className="containerInfo">
                {info.map((item, i) => {
                    return (
                        <div className={`${info.length > 3 ? "blockInfo four" : "blockInfo"} ${(meta && meta.draft_changes.details.hasOwnProperty(`${item.id}`)) ? "border-marker " : ""}`} key={i}>
                            <h4 className="title"> {item.title} </h4>
                            <p className="text">
                                {item.text}
                            </p>
                        </div>)
                })}
            </div>
        );
    }
};

export default InfoComponent;