import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pt_BR from "./languages/pt_BR/translation.json";

i18n.use(initReactI18next).init({
  resources: {
    pt_BR: {
      translation: pt_BR,
    },
  },
  lng: localStorage.getItem("xAccountLanguageCode") || "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
