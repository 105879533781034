import React from "react";
import InputProfile from "../../../../../components/InputProfile";
import iconError from "../../../../../imgs/icon-error.svg";
import iconSucess from "../../../../../imgs/icon-success.svg";

import Axios from "axios";
import PhoneInput from "react-phone-input-2";
import ButtonBgProfile from "../../../../../components/buttonWithBgProfile/index.jsx";
import CardAddress from "../../../../../components/cardAddress/index.jsx";
import { testForm } from "../../../../../utils/formValidate.js";
import "./styles.scss";

export default function FormAdress(props) {
  const [valuesForm, setValuesForm] = React.useState({
    recipient: "",
    contactName: "",
    address: "",
    city: "",
    state: "",
    zipCodeXX: "",
    country: "",
    type_adress: "",
    taxId: "",
    phone: "",
  });
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [openAdress, setOpenAdress] = React.useState(false);
  const [messageRequest, setMessageRequest] = React.useState("");
  const [errorState, setErrorState] = React.useState({
    type: false,
    contactName: false,
    recipient: false,
    address: false,
    city: false,
    state: false,
    zipCodeXX: false,
    country: false,
    type_adress: false,
    phone: false,
  });
  const [data, setData] = React.useState({
    contactNameBilling: props.data.buyer.billing_contact,
    recipientBilling: props.data.buyer.billing_to,
    addressBilling: props.data.buyer.billing_address,
    cityBilling: props.data.buyer.billing_city,
    stateBilling: props.data.buyer.billing_state,
    zipCodeBilling: props.data.buyer.billing_zip_code,
    countryBilling: props.data.buyer.billing_country,
    phoneBilling: props.data.buyer.billing_phone_number,
    taxIdBilling: props.data.buyer.billing_tax_id,

    contactNameShipping: props.data.buyer.shipping_contact,
    recipientShipping: props.data.buyer.shipping_to,
    addresSshipping: props.data.buyer.shipping_address,
    cityShipping: props.data.buyer.shipping_city,
    stateShipping: props.data.buyer.shipping_state,
    zipCodeShipping: props.data.buyer.shipping_zip_code,
    countryShipping: props.data.buyer.shipping_country,
    phoneShipping: props.data.buyer.shipping_phone_number,
  });

  function changeValuesForm(inputName, newValue) {
    const values = { ...valuesForm };
    values[inputName] = newValue;

    setValuesForm(values);
  }

  async function updateUser() {
    var validate = [];

    if (!testForm("text1", valuesForm.recipient)) {
      setErrorState((state) => ({ ...state, recipient: true }));
      validate.push("recipient");
    } else {
      setErrorState((state) => ({ ...state, recipient: false }));
    }

    if (!testForm("text1", valuesForm.contactName)) {
      setErrorState((state) => ({ ...state, contactName: true }));
      validate.push("contactName");
    } else {
      setErrorState((state) => ({ ...state, contactName: false }));
    }

    if (!testForm("text1", valuesForm.address)) {
      setErrorState((state) => ({ ...state, address: true }));
      validate.push("address");
    } else {
      setErrorState((state) => ({ ...state, address: false }));
    }

    if (!testForm("text1", valuesForm.city)) {
      setErrorState((state) => ({ ...state, city: true }));
      validate.push("city");
    } else {
      setErrorState((state) => ({ ...state, city: false }));
    }

    if (!testForm("text3", valuesForm.state)) {
      setErrorState((state) => ({ ...state, state: true }));
      validate.push("state");
    } else {
      setErrorState((state) => ({ ...state, state: false }));
    }

    if (!testForm("text1", valuesForm.zipCodeXX)) {
      setErrorState((state) => ({ ...state, zipCodeXX: true }));
      validate.push("zipCodeXX");
    } else {
      setErrorState((state) => ({ ...state, zipCodeXX: false }));
    }

    if (!testForm("text1", valuesForm.country)) {
      setErrorState((state) => ({ ...state, country: true }));
      validate.push("country");
    } else {
      setErrorState((state) => ({ ...state, country: false }));
    }

    if (validate.length > 0) {
      return;
    }

    setDisabledItem(true);

    let data = {
      type: valuesForm.type_adress[0] === 1 ? "billing" : "shipping",
      to: valuesForm.recipient,
      contact: valuesForm.contactName,
      tax_id: valuesForm.taxId,
      phone_number: valuesForm.phone,
      address: valuesForm.address,
      city: valuesForm.city,
      state: valuesForm.state,
      zip_code: valuesForm.zipCodeXX,
      country: valuesForm.country,
    };

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/buyer/address`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    try {
      const response = await Axios(config);
      setMessageRequest({ msg: response.data.message, id: 1 });

      if (response.data.data) {
        setData({
          addressBilling: response.data.data.billing_address,
          addresSshipping: response.data.data.shipping_address,
          cityBilling: response.data.data.billing_city,
          cityShipping: response.data.data.shipping_city,
          contactNameBilling: response.data.data.billing_contact,
          contactNameShipping: response.data.data.shipping_contact,
          recipientBilling: response.data.data.billing_to,
          recipientShipping: response.data.data.shipping_to,
          countryBilling: response.data.data.billing_country,
          countryShipping: response.data.data.shipping_country,
          phoneBilling: response.data.data.billing_phone_number,
          phoneShipping: response.data.data.shipping_phone_number,
          stateBilling: response.data.data.billing_state,
          stateShipping: response.data.data.shipping_state,
          zipCodeBilling: response.data.data.billing_zip_code,
          zipCodeShipping: response.data.data.shipping_zip_code,
          taxIdBilling: response.data.data.billing_tax_id,
        });
      }
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    } finally {
      setDisabledItem(false);
    }
  }

  return (
    <div className="container-form-adress">
      <h1 className="container-form-adress__title">Address</h1>

      {data && (
        <div className="container-form-adress__box-cards">
          <>
            {data && (
              <CardAddress
                type="Billing: "
                recipient={data.recipientBilling}
                contactName={data.contactNameBilling}
                address={data.addressBilling}
                city={data.cityBilling}
                state={data.stateBilling}
                zipCodeXX={data.zipCodeBilling}
                country={data.countryBilling}
                numberPhone={data.phoneBilling}
                taxId={data.taxIdBilling}
                type_adress={[1]}
                onclick={setValuesForm}
                setOpenAdress={setOpenAdress}
              />
            )}
          </>

          <>
            {data && (
              <CardAddress
                type="Shipping: "
                recipient={data.recipientShipping}
                contactName={data.contactNameShipping}
                address={data.addresSshipping}
                city={data.cityShipping}
                state={data.stateShipping}
                zipCodeXX={data.zipCodeShipping}
                country={data.countryShipping}
                numberPhone={data.phoneShipping}
                type_adress={[0]}
                onclick={setValuesForm}
                setOpenAdress={setOpenAdress}
              />
            )}
          </>
        </div>
      )}

      {openAdress && (
        <>
          <h2 className="container-form-adress__title">Add new address</h2>
          <div className="container-form-adress__box-inputs">
            <InputProfile
              value={valuesForm.recipient}
              onchange={changeValuesForm}
              typeValidation="text1"
              nameValue="recipient"
              typeInput="text"
              titleInput="Recipient"
              nameInput="recipient"
              isRequired
              error={errorState.recipient && "Recipient field is required."}
            />
            <InputProfile
              value={valuesForm.contactName}
              onchange={changeValuesForm}
              typeValidation="text1"
              nameValue="contactName"
              typeInput="text"
              titleInput="Contact name"
              nameInput="contactName"
              isRequired
              error={errorState.contactName && "Contact name is required."}
            />
            {valuesForm &&
              valuesForm.type_adress &&
              valuesForm.type_adress[0] === 1 && (
                <InputProfile
                  value={valuesForm.taxId}
                  onchange={changeValuesForm}
                  typeValidation="text1"
                  nameValue="taxId"
                  typeInput="text"
                  titleInput="Tax ID"
                  nameInput="taxId"
                />
              )}
            <div className="component-phone">
              <p className="input-required-phone">Phone</p>
              <PhoneInput
                className="select-phone"
                country={"br"}
                localization={"pt"}
                value={valuesForm.phone}
                onChange={(phone, infos) =>
                  changeValuesForm("phone", `${phone}`)
                }
              />
              <p className="box-input-phone-validation"></p>
            </div>
            <div className="adress-input">
              <InputProfile
                value={valuesForm.address}
                wd="100%"
                onchange={changeValuesForm}
                typeValidation="text1"
                typeInput="text"
                nameValue="address"
                titleInput="Address"
                nameInput="address"
                isRequired
                error={
                  errorState.address && "Address is required."
                }
              />
            </div>
            <InputProfile
              value={valuesForm.city}
              onchange={changeValuesForm}
              nameValue="city"
              typeValidation="text1"
              typeInput="text"
              titleInput="City"
              nameInput="city"
              isRequired
              error={
                errorState.city && "City must have to be 3 character at least."
              }
            />
            <InputProfile
              value={valuesForm.state}
              onchange={changeValuesForm}
              nameValue="state"
              typeValidation="text1"
              typeInput="text"
              titleInput="State"
              nameInput="state"
              isRequired
              error={errorState.state && "State field is required."}
            />
            <InputProfile
              value={valuesForm.zipCodeXX}
              onchange={changeValuesForm}
              nameValue="zipCodeXX"
              typeValidation="text1"
              typeInput="text"
              titleInput="Zip code"
              nameInput="zipCod"
              isRequired
              error={errorState.zipCodeXX && "ZipCode field is required."}
            />
            <InputProfile
              value={valuesForm.country}
              onchange={changeValuesForm}
              nameValue="country"
              typeValidation="text1"
              typeInput="text"
              titleInput="Country"
              nameInput="country"
              isRequired
              error={errorState.country && "Country field is required."}
            />
          </div>

          <p
            className="container-form-company__message-request"
            style={{ color: `${messageRequest.id === 1 ? "green" : "red"}` }}
          >
            {messageRequest && (
              <>
                {messageRequest.msg}
                <img
                  src={messageRequest.id === 1 ? iconSucess : iconError}
                  alt="icone de feedback da request"
                />
              </>
            )}
          </p>

          <div className="container-form__personal-btns">
            <ButtonBgProfile disabled={disabledItem} onclick={updateUser} />
          </div>
        </>
      )}
    </div>
  );
}
