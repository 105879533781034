import React, {Component} from 'react';
import "./style/HighlightC.scss";
// import {Parallax, Background} from 'react-parallax';
import { withTranslation } from 'react-i18next';
import Buttons from '../buttons/Buttons';


class HighlightC extends Component {

    render() {
        return (
            <div>
                <section id="SecondHeader" className={this.props.id}>
                    <div className="texture2" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/landing/header/bg-texture-02.png)`}}/>
                    <div className="logoAndText">
                        <div>
                            <img src={this.props.data.logo} alt=""/>
                            <p>{this.props.data.text}</p>
                            <Buttons
                                buttons={this.props.data.buttons}
                                openRegister={this.props.openRegister}
                                open={this.props.open}
                            />
                        </div>
                    </div>
                    <div className="headerImages">
                        <div className="image">
                            <img src={this.props.data.image} alt=""/>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(HighlightC);
