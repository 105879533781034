import React from 'react';
import "../style/BrandBoxStyle.scss"
import {Link} from "react-router-dom";


const CategoryBrandsBoxComponent = props => {

    const { brands } = props;

    return (
        <div className="boxBrands">

            { brands.map( (item, index) => {
                return (
                    <div className="containerImage" key={index}>
                      <Link className="blockBrand" to={`brands/?categories=${item.id}`} key={index} style={{ backgroundImage: `url(${item.image_thumbnail})`}}>
                        <div>{item.name}</div>
                      </Link>
                    </div>
                )
            })}

        </div>
    );
};

export default CategoryBrandsBoxComponent;
