import React from "react";
import Slider from 'react-slick';

class SlideComponent extends React.Component {

    render() {
        const { children,  dots, infinite, speed, slidetoshow, slideScroll, slidesPerRow, initialSlide, extraSettings } = this.props;

        let settings = {
            dots: dots,
            infinite: infinite,
            speed: speed,
            slidesToShow: slidetoshow,
            slidesToScroll: slideScroll,
            slidesPerRow: slidesPerRow,
            initialSlide: initialSlide !== undefined ? initialSlide : 0
        };

        return (
            <Slider className="slide" {...settings} {...extraSettings}>
                {children}
            </Slider>
        );

    }
}

export default SlideComponent;
