import React from "react";
import { withTranslation } from "react-i18next";
import "../style/SizeChartStyle.scss";

const SizeChartComponent = (props) => {
  const { t, i18n } = props;
  const currentLang = i18n.language;
  return currentLang === "en" ? (
    <div>
      <div className="__title">
        {t(
          "INTERNATIONAL SIZE COMPARATIVE TABLE",
          "INTERNATIONAL SIZE COMPARATIVE TABLE"
        )}
      </div>
      <div className="__international">
        <table>
          <thead>
            <tr className="__sizes">
              <td>{t("SIZE", "SIZE")} (US)</td>
              <td>{t("NUMERIC SIZE", "NUMERIC SIZE")} (US)</td>
              <td>{t("UK", "UK")}</td>
              <td>{t("GERMANY", "GERMANY")}</td>
              <td>{t("FRANCE", "FRANCE")}</td>
              <td>{t("ITALY", "ITALY")}</td>
              <td>{t("SPAIN", "SPAIN")}</td>
              <td>{t("JAPAN", "JAPAN")}</td>
              <td>{t("AUSTRALIA", "AUSTRALIA")}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="__measurements">XXXS</td>
              <td className="__values">0</td>
              <td className="__values">0</td>
              <td className="__values">24</td>
              <td className="__values">28</td>
              <td className="__values">32</td>
              <td className="__values">26</td>
              <td className="__values">1</td>
              <td className="__values">0</td>
            </tr>
            <tr>
              <td className="__measurements">XXS</td>
              <td className="__values">0</td>
              <td className="__values">2</td>
              <td className="__values">26</td>
              <td className="__values">30</td>
              <td className="__values">34</td>
              <td className="__values">28</td>
              <td className="__values">1</td>
              <td className="__values">2</td>
            </tr>
            <tr>
              <td className="__measurements">XS</td>
              <td className="__values">0</td>
              <td className="__values">4</td>
              <td className="__values">28</td>
              <td className="__values">32</td>
              <td className="__values">36</td>
              <td className="__values">30</td>
              <td className="__values">3</td>
              <td className="__values">4</td>
            </tr>
            <tr>
              <td className="__measurements">XS</td>
              <td className="__values">2</td>
              <td className="__values">6</td>
              <td className="__values">30</td>
              <td className="__values">34</td>
              <td className="__values">38</td>
              <td className="__values">32</td>
              <td className="__values">5</td>
              <td className="__values">6</td>
            </tr>
            <tr>
              <td className="__measurements">S</td>
              <td className="__values">4</td>
              <td className="__values">8</td>
              <td className="__values">34</td>
              <td className="__values">36</td>
              <td className="__values">40</td>
              <td className="__values">34</td>
              <td className="__values">7</td>
              <td className="__values">8</td>
            </tr>
            <tr>
              <td className="__measurements">S</td>
              <td className="__values">6</td>
              <td className="__values">10</td>
              <td className="__values">34</td>
              <td className="__values">38</td>
              <td className="__values">42</td>
              <td className="__values">36</td>
              <td className="__values">9</td>
              <td className="__values">10</td>
            </tr>
            <tr>
              <td className="__measurements">M</td>
              <td className="__values">8</td>
              <td className="__values">12</td>
              <td className="__values">36</td>
              <td className="__values">40</td>
              <td className="__values">44</td>
              <td className="__values">38</td>
              <td className="__values">11</td>
              <td className="__values">12</td>
            </tr>
            <tr>
              <td className="__measurements">M</td>
              <td className="__values">10</td>
              <td className="__values">14</td>
              <td className="__values">38</td>
              <td className="__values">42</td>
              <td className="__values">46</td>
              <td className="__values">40</td>
              <td className="__values">13</td>
              <td className="__values">14</td>
            </tr>
            <tr>
              <td className="__measurements">L</td>
              <td className="__values">12</td>
              <td className="__values">16</td>
              <td className="__values">40</td>
              <td className="__values">44</td>
              <td className="__values">48</td>
              <td className="__values">42</td>
              <td className="__values">15</td>
              <td className="__values">16</td>
            </tr>
            <tr>
              <td className="__measurements">L</td>
              <td className="__values">14</td>
              <td className="__values">18</td>
              <td className="__values">42</td>
              <td className="__values">46</td>
              <td className="__values">50</td>
              <td className="__values">44</td>
              <td className="__values">17</td>
              <td className="__values">18</td>
            </tr>
            <tr>
              <td className="__measurements">XL</td>
              <td className="__values">16</td>
              <td className="__values">20</td>
              <td className="__values">44</td>
              <td className="__values">48</td>
              <td className="__values">52</td>
              <td className="__values">46</td>
              <td className="__values">19</td>
              <td className="__values">20</td>
            </tr>
            <tr>
              <td className="__measurements">XL</td>
              <td className="__values">18</td>
              <td className="__values">22</td>
              <td className="__values">46</td>
              <td className="__values">50</td>
              <td className="__values">54</td>
              <td className="__values">48</td>
              <td className="__values">21</td>
              <td className="__values">22</td>
            </tr>
            <tr>
              <td className="__measurements">XXL</td>
              <td className="__values">20</td>
              <td className="__values">24</td>
              <td className="__values">48</td>
              <td className="__values">52</td>
              <td className="__values">56</td>
              <td className="__values">50</td>
              <td className="__values">23</td>
              <td className="__values">24</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
};

export default withTranslation()(SizeChartComponent);
