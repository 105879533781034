import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class OrderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newMessage: null,
    };
  }

  componentDidMount() {
    const userTypeSeller = localStorage.getItem("user_stype") === "seller";
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const { item, t } = this.props;
    const messageUnread = item.messages.find(
      (message) =>
        (userTypeSeller
          ? message.sent_from.seller &&
            message.sent_from.seller.id !== userData.entity_id
          : message.sent_from.seller) && !message.read
    );
    if (messageUnread) {
      this.setState({
        newMessage: t(" - NEW MESSAGE!", " - NEW MESSAGE!"),
      });
    }
  }

  render() {
    const { item, isActive, selectOrder, t, i18n } = this.props;
    const userTypeSeller = localStorage.getItem("user_stype") === "seller";

    return (
      <div
        onClick={() => selectOrder(item.id)}
        className={`brand ${isActive ? "active" : ""}`}
      >
        {item.status === "b" && (
          <span className="order-status status-gray">
            {t("Pending Analysis", "Pending Analysis")} {this.state.newMessage}
          </span>
        )}
        {item.status === "s" && (
          <span className="order-status status-green">
            {t("Waiting Brand", "Waiting Brand")} {this.state.newMessage}
          </span>
        )}
        {item.status === "r" && (
          <span className="order-status status-red">
            {t("Retailer Pending", "Retailer Pending")} {this.state.newMessage}
          </span>
        )}
        {item.status === "p" && (
          <span className="order-status status-green">
            {t("Processing", "Processing")} {this.state.newMessage}
          </span>
        )}
        {item.status === "i" && (
          <span className="order-status status-green">
            {t("In transit", "In transit")} {this.state.newMessage}
          </span>
        )}
        {item.status === "f" && (
          <span className="order-status status-gray">
            {t("Finished", "Finished")} {this.state.newMessage}
          </span>
        )}

        <span
          className={`brand-name ${userTypeSeller ? "brand-name--buyer" : ""}`}
        >
          {userTypeSeller ? item.buyer.name : item.seller.name}
        </span>

        <div className="brand-order-info">
          <span className="brand-item-statistic">
            <strong>{item.total_items}</strong>{" "}
            {item.total_items > 1 ? t("units", "units") : t("unit", "unit")} de{" "}
            <strong>{item.total_products}</strong> {t("product", "product")}
            {item.total_products > 1 ? "s" : ""}
          </span>
          <span className="brand-item-date">
            {t("from", "from")}{" "}
            {i18n.language === "en"
              ? moment(item.created).format("MM/DD/YYYY")
              : moment(item.created).format("DD/MM/YYYY")}
          </span>
          <div className="idOrder">
            {t("Order", "Order")} #{item.id}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(OrderItem);
