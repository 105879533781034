import React, { Component } from "react";
import Button from "../../button/components/ButtonComponent";
import isMobile from "is-mobile";
import RegisterModalComponent from "../../../../shared/registermodal/components/RegisterModalComponent";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const Style = require("../styles/NavbarComponent.scss");

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      showRegister: false,
      selectedIndexMenu: 0,
    };
    this.openMenu = this.openMenu.bind(this);
  }

  openMenu() {
    if (isMobile()) {
      this.setState({ openMenu: !this.state.openMenu });
    }
  }

  back() {
    history.goBack();
  }

  render() {
    const { navEvento, t } = this.props;
    const accountPoweredByBlanc = Number(
      localStorage.getItem("xAccountPoweredByBlanc")
    );
    const xAccountLogo = localStorage.getItem("xAccountLogo");
    const xAccountTitle = localStorage.getItem("xAccountTitle");
    const showPoweredByBlanc =
      navEvento || accountPoweredByBlanc ? true : false;

    return (
      <>
        <nav
          style={{
            justifyContent: showPoweredByBlanc ? "flex-end" : undefined,
          }}
          className={Style.nav}
          id="navLanding"
        >
          <div
            style={{
              justifyContent: showPoweredByBlanc ? "flex-end" : undefined,
            }}
            className={`MenuResponsive show-mb ${
              this.state.openMenu ? "showMenu" : ""
            }`}
          >
            <div
              style={{ display: showPoweredByBlanc ? "none" : undefined }}
              className="open-menu-wrapper"
            >
              <div className="openMenuMobile" onClick={this.openMenu}>
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              className="logo-wrapper logo-wrapper--xAccount"
              style={{
                display:
                  showPoweredByBlanc && !accountPoweredByBlanc
                    ? "none"
                    : undefined,
              }}
            >
              <img
                src={xAccountLogo}
                className="logo-desk"
                alt={xAccountTitle}
                onClick={() => {
                  return window.location.pathname !== "/" ? this.back() : "";
                }}
              />
            </div>
            <div className="login-button-wrapper">
              <Button
                onClick={this.props.showLogin}
                text={t("Login", "Login")}
              />
            </div>
          </div>

          <div
            className="logo-wrapper"
            style={{
              display:
                showPoweredByBlanc && !accountPoweredByBlanc
                  ? "none"
                  : undefined,
            }}
          >
            <img
              src={xAccountLogo}
              className="logo-desk"
              alt={xAccountTitle}
              onClick={() => {
                return window.location.pathname !== "/" ? this.back() : "";
              }}
            />
          </div>

          <h1
            style={{ display: !showPoweredByBlanc ? "none" : undefined }}
            className={`logo ${
              accountPoweredByBlanc ? "logo--powered-by" : ""
            }`}
          >
            <span>{t('Powered by', 'Powered by')}</span>
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo.svg`}
                alt="logo"
              />
            </Link>
          </h1>

          <div
            style={{ display: showPoweredByBlanc ? "none" : undefined }}
            className={`itens ${this.state.openMenu ? "showMenu" : ""}`}
          >
            <ul>
              <li>
                <a
                  onClick={() =>
                    this.setState({ openMenu: false, selectedIndexMenu: 2 })
                  }
                  href="/#ourplatform"
                  className={
                    this.state.selectedIndexMenu === 2 ? "selected" : ""
                  }
                >
                  {t("Our Platform", "Our Platform")}
                </a>
              </li>
              <li>
                <a
                  onClick={() =>
                    this.setState({ openMenu: false, selectedIndexMenu: 1 })
                  }
                  href="/brands"
                  className={
                    this.state.selectedIndexMenu === 1 ? "selected" : ""
                  }
                >
                  {t("Our Brands", "Our Brands")}
                </a>
              </li>
              {t("lang") !== "pt_BR" ? (
                <li>
                  <a
                    onClick={() =>
                      this.setState({ openMenu: false, selectedIndexMenu: 3 })
                    }
                    href="/#ourretailers"
                    className={
                      this.state.selectedIndexMenu === 3 ? "selected" : ""
                    }
                  >
                    {t("Our Retailers", "Our Retailers")}
                  </a>
                </li>
              ) : (
                ""
              )}
              <li>
                <a href="https://news.blancfashion.com/">{t("News and Views", "News and Views")}</a>
              </li>
              <li>
                <Link to="/whoweare">{t("About BLANC*", "About BLANC*")}</Link>
              </li>

            </ul>
          </div>

          <div className="login-button">
            <img
              src={`${process.env.PUBLIC_URL}/images/landing/header/person.svg`}
              className="person-login"
              alt="person"
            />
            <ul className="dropdown">
              <li onClick={this.props.showLogin}>{t("LOGIN", "LOGIN")}</li>
              <li onClick={() => this.setState({ showRegister: true })}>
                {t("REGISTER", "REGISTER")}
              </li>
            </ul>
          </div>
        </nav>
        <div id="shadowNav"></div>
        {this.state.showRegister && (
          <RegisterModalComponent
            close={() => this.setState({ showRegister: false })}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(NavbarComponent);
