import React, { Component } from "react";
import "./style/OrderCartAuthenticationStyle.scss";
import { Link } from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";
import { connect } from "react-redux";

import CartInterector from "../../../domain/pages/cart/CartInterector";
import CartService from "../../../data/pages/cart/CartService";
import axios from "axios";
import ConnectionsBlockComponent from "../../menu/components/ConnectionsBlockComponent";
import MessageBlockComponent from "../../views/components/MessagesPage/MessageBlockComponent";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import { Translation } from "react-i18next";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productCount: 0,
      connections: [],
      messages: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem('acess_token')) {
      this.getCart();
      this.getConnections();
      this.getMessages();
    }
  }

  async getCart() {
    new CartInterector(new CartService().getData());
  }

  getConnections() {
    axios
      .get(`${process.env.REACT_APP_NEW_API_URL}/v1/connections?pending=1`)
      .then((response) => {
        if (response.data.data) {
          this.setState({
            connections: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  getMessages() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages?filter=unread-messages&page=1`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            messages: response.data.data,
            loading: false,
          });
        }
      });
  }

  renderConnections() {
    return this.state.connections.map((connection) => (
      <li key={connection.id}>
        <ConnectionsBlockComponent
          connection={connection}
          popConnection={() => this.popConnection(connection)}
        />
      </li>
    ));
  }

  renderMessages() {
    return this.state.messages.map((message) => (
      <li key={message.id}>
        <MessageBlockComponent message={message} />
      </li>
    ));
  }

  popConnection(connection) {
    this.setState((prevState) => {
      const { connections } = prevState;
      return {
        ...prevState,
        connections: connections.filter(
          (item) => Number(item.id) !== Number(connection.id)
        ),
      };
    });
  }

  render() {
    const { handleClick, isLogged, cartData, showNotificationItemAdded } =
      this.props;

    // Inverted isLogged = !isLogged
    // if (isLogged) {
    //   localStorage.clear();
    //   window.location.href = "/";
    // }

    return (
      <Translation>
        {(t) => (
          <div className="containerLogin">
            {isLogged ? (
              <></>
            ) : (
              <div className="settings">
                <div className="connections menu-item">
                  {this.state.connections.length > 0 && (
                    <span className="connectionsCount"></span>
                  )}
                  <Link to="/connections">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/menu/connections.svg`}
                      alt="ico connections"
                    />
                  </Link>
                  <ul
                    className={`messages-menu menu-item--sub ${this.state.connections.length > 0
                      ? "menu-item--sub--with-content"
                      : ""
                      }`}
                  >
                    {this.renderConnections()}
                    <li className="see-all-connections button-see-all">
                      <Link to="/connections">
                        {t("SEE ALL CONNECTIONS", "SEE ALL CONNECTIONS")}
                      </Link>
                    </li>
                    <span className="top-arrow--border"></span>
                    <span className="top-arrow"></span>
                  </ul>
                </div>
                <div className="messages menu-item">
                  {this.state.messages.length > 0 && (
                    <span className="messagesCount"></span>
                  )}
                  <Link to="/messages">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/menu/messages.svg`}
                      alt="ico messages"
                    />
                  </Link>
                  <ul
                    className={`messages-menu menu-item--sub ${this.state.messages.length > 0
                      ? "menu-item--sub--with-content"
                      : ""
                      }`}
                  >
                    {this.renderMessages()}
                    <li className="see-all-messages button-see-all">
                      <Link to="/messages">
                        {t("SEE YOUR MESSAGES", "SEE YOUR MESSAGES")}
                      </Link>
                    </li>
                    <span className="top-arrow--border"></span>
                    <span className="top-arrow"></span>
                  </ul>
                </div>
                <div className="cart menu-item">
                  {cartData[0] && cartData[0].products_count > 0 && (
                    <span className="productCount"></span>
                  )}
                  <Link to="/cart">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/menu/bag.svg`}
                      alt="ico user"
                    />
                  </Link>
                  <ul
                    className={`messages-menu menu-item--sub ${showNotificationItemAdded
                      ? "menu-item--sub--show-notification"
                      : ""
                      }`}
                  >
                    <span className="top-arrow--border"></span>
                    <span className="top-arrow"></span>
                    <li className="item-added-notification notification">
                      {showNotificationItemAdded === "isProductInCart"
                        ? t("ITEM ALREADY IN BAG!", "ITEM ALREADY IN BAG!")
                        : t("ITEM ADDED TO BAG!", "ITEM ADDED TO BAG!")}
                    </li>
                    <li className="see-all-messages button-see-all">
                      <Link to="/cart">
                        {t("CHECK YOUR CART", "CHECK YOUR CART")}
                      </Link>
                    </li>
                  </ul>
                </div>
                {
                  window.localStorage.getItem("user_stype") !== "seller" ? (
                    <div className="user">
                      <a href="/profile">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/menu/person.svg`}
                          alt="ico user"
                        />
                      </a>
                    </div>
                  ) : (
                    <></>
                  )
                }
                {/* <div className="dropdownSettings-wrapper">
                  <div className="dropdownSettings">
                    <div className="hello">
                      <small>{t("Hello", "Hello")}, </small>&nbsp;
                      {window.localStorage.getItem("user")}
                    </div>
                    <ul className="dropdownSettings-menu">
                      <li>
                        <Link to="/cart">{t("Cart", "Cart")}</Link>
                      </li>
                      <li>
                        <Link to="/order">{t("Orders", "Orders")}</Link>
                      </li>
                      {window.localStorage.getItem("user_stype") ===
                        "buyer" && (
                          <li>
                            <Link to="/profile">
                              {t("My Profile", "My Profile")}
                            </Link>
                          </li>
                        )}
                      {window.localStorage.getItem("user_stype") !== "buyer" && (
                        <li>
                          <a href="https://selleradmin.blancfashion.com/" target="_blank">
                            {t("Seller Admin", "Seller Admin")}
                          </a>
                        </li>
                      )}
                      <li className="logout">
                        <ButtonComponent
                          action={() => AuthenticationService.logout()}
                        >
                          {t("Logout", "Logout")}
                        </ButtonComponent>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        )}
      </Translation>
    );
  }
}

class OrderCartAuthentication extends Component {
  static isLogged() {
    return window.localStorage.getItem("user") === null;
  }

  componentDidMount() { }

  render() {
    const { handleClick, cartData, showNotificationItemAdded } = this.props;

    return (
      <div className="orderCartAuthentication">
        <ul>
          <li>
            <div className="authentication">
              <Authentication
                cartData={cartData}
                handleClick={handleClick}
                isLogged={OrderCartAuthentication.isLogged()}
                showNotificationItemAdded={showNotificationItemAdded}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartData: state.cartEntity,
    showNotificationItemAdded: state.showNotificationItemAdded,
  };
}

export default connect(mapStateToProps)(OrderCartAuthentication);
