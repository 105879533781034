import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import Slider from "react-slick";
import HighlightA from "../../../../shared/highlights/components/highlightA/HighlightA";
import HighlightB from "../../../../shared/highlights/components/highlightB/HighlightB";
import HighlightC from "../../../../shared/highlights/components/highlightC/HighlightC";
import HighlightD from "../../../../shared/highlights/components/highlightD/HighlightD";
import HighlightE from "../../../../shared/highlights/components/highlightE/HighlightE";
import HighlightF from "../../../../shared/highlights/components/highlightF/HighlightF";
import HighlightG from "../../../../shared/highlights/components/highlightG/HighlightG";
import "./Hero.scss";

class Hero extends Component {
  render() {
    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
    return (
      <Slider className="slide hero" {...settings}>
        {this.props.data.map((item, index) => {
          return (
            <>
              <Helmet>
                <style id="HIGHLIGHT STYLE">
                  {`.highlight-${index} ${item.styles}`}
                </style>
              </Helmet>
              {
                {
                  A: (
                    <HighlightA
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  B: (
                    <HighlightB
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  C: (
                    <HighlightC
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  D: (
                    <HighlightD
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                      link={this.props.link || null}
                    />
                  ),
                  E: (
                    <HighlightE
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  F: (
                    <HighlightF
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  G: (
                    <HighlightG
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      data={item}
                      slug={item.slug && item.slug}
                      eventPage={this.props.eventPage}
                    />
                  ),
                  default: (
                    <HighlightC
                      id={`highlight-${index}`}
                      key={index}
                      openRegister={this.props.openRegister}
                      open={this.props.open}
                      hideMargin={this.props.hideMargin}
                      slug={item.slug && item.slug}
                      data={item}
                    />
                  ),
                }[item.template]
              }
            </>
          );
        })}
      </Slider>
    );
  }
}

export default Hero;
