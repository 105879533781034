export const countriesPhones = [
    {
      country: "Afeganistão",
      flag: "🇦🇫",
      code: "AF",
      dialCode: "+93",
      mask: "999 999 9999",
    },
    {
      country: "Ilhas Aland",
      flag: "🇦🇽",
      code: "AX",
      dialCode: "+358",
      mask: "999 9999999",
    },
    {
      country: "Albânia",
      flag: "🇦🇱",
      code: "AL",
      dialCode: "+355",
      mask: "999 999 9999",
    },
    {
      country: "Argélia",
      flag: "🇩🇿",
      code: "DZ",
      dialCode: "+213",
      mask: "9999 99 99 99",
    },
    {
      country: "Samoa Americana",
      flag: "🇦🇸",
      code: "AS",
      dialCode: "+1684",
      mask: "(999) 999-9999",
    },
    {
      country: "Andorra",
      flag: "🇦🇩",
      code: "AD",
      dialCode: "+376",
      mask: "999 999",
    },
    {
      country: "Angola",
      flag: "🇦🇴",
      code: "AO",
      dialCode: "+244",
      mask: "999 999 999",
    },
    {
      country: "Anguilla",
      flag: "🇦🇮",
      code: "AI",
      dialCode: "+1264",
      mask: "(999) 999-9999",
    },
    {
      country: "Antigua e Barbuda",
      flag: "🇦🇬",
      code: "AG",
      dialCode: "+1268",
      mask: "(999) 999-9999",
    },
    {
      country: "Argentina",
      flag: "🇦🇷",
      code: "AR",
      dialCode: "+54",
      mask: "999 99-9999-9999",
    },
    {
      country: "Armênia",
      flag: "🇦🇲",
      code: "AM",
      dialCode: "+374",
      mask: "999 999999",
    },
    {
      country: "Aruba",
      flag: "🇦🇼",
      code: "AW",
      dialCode: "+297",
      mask: "999 9999",
    },
    {
      country: "Austrália",
      flag: "🇦🇺",
      code: "AU",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      country: "Áustria",
      flag: "🇦🇹",
      code: "AT",
      dialCode: "+43",
      mask: "9999 999999",
    },
    {
      country: "Azerbaijão",
      flag: "🇦🇿",
      code: "AZ",
      dialCode: "+994",
      mask: "999 999 99 99",
    },
    {
      country: "Bahamas",
      flag: "🇧🇸",
      code: "BS",
      dialCode: "+1242",
      mask: "(999) 999-999",
    },
    {
      country: "Bahrain",
      flag: "🇧🇭",
      code: "BH",
      dialCode: "+973",
      mask: "9999 9999",
    },
    {
      country: "Bangladesh",
      flag: "🇧🇩",
      code: "BD",
      dialCode: "+880",
      mask: "99999-999999",
    },
    {
      country: "Barbados",
      flag: "🇧🇧",
      code: "BB",
      dialCode: "+1246",
      mask: "(999) 999-9999",
    },
    {
      country: "Bielo-Rússia",
      flag: "🇧🇾",
      code: "BY",
      dialCode: "+375",
      mask: "9 999 999-99-99",
    },
    {
      country: "Bélgica",
      flag: "🇧🇪",
      code: "BE",
      dialCode: "+32",
      mask: "9999 99 99 99",
    },
    {
      country: "Belize",
      flag: "🇧🇿",
      code: "BZ",
      dialCode: "+501",
      mask: "999-9999",
    },
    {
      country: "Benin",
      flag: "🇧🇯",
      code: "BJ",
      dialCode: "+229",
      mask: "99 99 99 99",
    },
    {
      country: "Bermudas",
      flag: "🇧🇲",
      code: "BM",
      dialCode: "+1441",
      mask: "(999) 999-9999",
    },
    {
      country: "Butão",
      flag: "🇧🇹",
      code: "BT",
      dialCode: "+975",
      mask: "99 99 99 99",
    },
    {
      country: "Bolívia, Estado Plurinacional da Bolívia",
      flag: "🇧🇴",
      code: "BO",
      dialCode: "+591",
      mask: "99999999",
    },
    {
      country: "Bósnia e Herzegovina",
      flag: "🇧🇦",
      code: "BA",
      dialCode: "+387",
      mask: "999 999 999",
    },
    {
      country: "Botswana",
      flag: "🇧🇼",
      code: "BW",
      dialCode: "+267",
      mask: "99 999 999",
    },
    {
      country: "Ilha Bouvet",
      flag: "🇧🇻",
      code: "BV",
      dialCode: "+47",
      mask: "(99) 99999-9999",
    },
    {
      country: "Brasil",
      flag: "🇧🇷",
      code: "BR",
      dialCode: "+55",
      mask: "(99) 99999 9999",
    },
    {
      country: "Território Britânico do Oceano Índico",
      flag: "🇮🇴",
      code: "IO",
      dialCode: "+246",
      mask: "(999) 999-9999",
    },
    {
      country: "Brunei Darussalam",
      flag: "🇧🇳",
      code: "BN",
      dialCode: "+673",
      mask: "999 9999",
    },
    {
      country: "Bulgária",
      flag: "🇧🇬",
      code: "BG",
      dialCode: "+359",
      mask: "999 999 999",
    },
    {
      country: "Burkina Faso",
      flag: "🇧🇫",
      code: "BF",
      dialCode: "+226",
      mask: "99 99 99 99",
    },
    {
      country: "Burundi",
      flag: "🇧🇮",
      code: "BI",
      dialCode: "+257",
      mask: "99 99 99 99",
    },
    {
      country: "Camboja",
      flag: "🇰🇭",
      code: "KH",
      dialCode: "+855",
      mask: "999 999 999",
    },
    {
      country: "Camarões",
      flag: "🇨🇲",
      code: "CM",
      dialCode: "+237",
      mask: "9 99 99 99 99",
    },
    {
      country: "Canadá",
      flag: "🇨🇦",
      code: "CA",
      dialCode: "+1",
      mask: "(999) 999-9999",
    },
    {
      country: "Cabo Verde",
      flag: "🇨🇻",
      code: "CV",
      dialCode: "+238",
      mask: "999 99 99",
    },
    {
      country: "Ilhas Cayman",
      flag: "🇰🇾",
      code: "KY",
      dialCode: "+345",
      mask: "999 9999",
    },
    {
      country: "República Centro-Africana",
      flag: "🇨🇫",
      code: "CF",
      dialCode: "+236",
      mask: "99 99 99 99",
    },
    {
      country: "Chade",
      flag: "🇹🇩",
      code: "TD",
      dialCode: "+235",
      mask: "99 99 99 99",
    },
    {
      country: "Chile",
      flag: "🇨🇱",
      code: "CL",
      dialCode: "+56",
      mask: "(9) 9999 9999",
    },
    {
      country: "China",
      flag: "🇨🇳",
      code: "CN",
      dialCode: "+86",
      mask: "999 9999 9999",
    },
    {
      country: "Ilha do Natal",
      flag: "🇨🇽",
      code: "CX",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      country: "Ilhas Cocos (Keeling)",
      flag: "🇨🇨",
      code: "CC",
      dialCode: "+61",
      mask: "9999 999 999",
    },
    {
      country: "Colômbia",
      flag: "🇨🇴",
      code: "CO",
      dialCode: "+57",
      mask: "999 9999999",
    },
    {
      country: "Comores",
      flag: "🇰🇲",
      code: "KM",
      dialCode: "+269",
      mask: "999 99 99",
    },
    {
      country: "Congo",
      flag: "🇨🇬",
      code: "CG",
      dialCode: "+242",
      mask: "9999 999 999",
    },
    {
      country: "Congo, República Democrática do Congo",
      flag: "🇨🇩",
      code: "CD",
      dialCode: "+243",
      mask: "99 999 9999",
    },
    {
      country: "Ilhas Cook",
      flag: "🇨🇰",
      code: "CK",
      dialCode: "+682",
      mask: "99 999",
    },
    {
      country: "Costa Rica",
      flag: "🇨🇷",
      code: "CR",
      dialCode: "+506",
      mask: "9999 9999",
    },
    {
      country: "Costa do Marfim",
      flag: "🇨🇮",
      code: "CI",
      dialCode: "+225",
      mask: "99 99 99 99",
    },
    {
      country: "Croácia",
      flag: "🇭🇷",
      code: "HR",
      dialCode: "+385",
      mask: "999 999 9999",
    },
    {
      country: "Cuba",
      flag: "🇨🇺",
      code: "CU",
      dialCode: "+53",
      mask: "99 999 999",
    },
    {
      country: "Chipre",
      flag: "🇨🇾",
      code: "CY",
      dialCode: "+357",
      mask: "99 999999",
    },
    {
      country: "República Checa",
      flag: "🇨🇿",
      code: "CZ",
      dialCode: "+420",
      mask: "999 999 999",
    },
    {
      country: "Dinamarca",
      flag: "🇩🇰",
      code: "DK",
      dialCode: "+45",
      mask: "99 99 99 99",
    },
    {
      country: "Djibouti",
      flag: "🇩🇯",
      code: "DJ",
      dialCode: "+253",
      mask: "99 99 99 99",
    },
    {
      country: "Dominica",
      flag: "🇩🇲",
      code: "DM",
      dialCode: "+1767",
      mask: "(999) 999-9999",
    },
    {
      country: "Equador",
      flag: "🇪🇨",
      code: "EC",
      dialCode: "+593",
      mask: "999 999 9999",
    },
    {
      country: "Egito",
      flag: "🇪🇬",
      code: "EG",
      dialCode: "+20",
      mask: "9999 999 9999",
    },
    {
      country: "El Salvador",
      flag: "🇸🇻",
      code: "SV",
      dialCode: "+503",
      mask: "9999 9999",
    },
    {
      country: "Guiné Equatorial",
      flag: "🇬🇶",
      code: "GQ",
      dialCode: "+240",
      mask: "999 999 999",
    },
    {
      country: "Eritreia",
      flag: "🇪🇷",
      code: "ER",
      dialCode: "+291",
      mask: "99 999 999",
    },
    {
      country: "Estônia",
      flag: "🇪🇪",
      code: "EE",
      dialCode: "+372",
      mask: "9999 9999",
    },
    {
      country: "Etiópia",
      flag: "🇪🇹",
      code: "ET",
      dialCode: "+251",
      mask: "999 999 9999",
    },
    {
      country: "Ilhas Falkland (Malvinas)",
      flag: "🇫🇰",
      code: "FK",
      dialCode: "+500",
      mask: "99999",
    },
    {
      country: "Ilhas Faroe",
      flag: "🇫🇴",
      code: "FO",
      dialCode: "+298",
      mask: "999999",
    },
    {
      country: "Fiji",
      flag: "🇫🇯",
      code: "FJ",
      dialCode: "+679",
      mask: "999 9999",
    },
    {
      country: "Finlândia",
      flag: "🇫🇮",
      code: "FI",
      dialCode: "+358",
      mask: "999 9999999",
    },
    {
      country: "França",
      flag: "🇫🇷",
      code: "FR",
      dialCode: "+33",
      mask: "99 99 99 99 99",
    },
    {
      country: "Guiana Francesa",
      flag: "🇬🇫",
      code: "GF",
      dialCode: "+594",
      mask: "9999 99 99 99",
    },
    {
      country: "Polinésia Francesa",
      flag: "🇵🇫",
      code: "PF",
      dialCode: "+689",
      mask: "99 99 99 99",
    },
    {
      country: "Gabão",
      flag: "🇬🇦",
      code: "GA",
      dialCode: "+241",
      mask: "99 99 99 99",
    },
    {
      country: "Gâmbia",
      flag: "🇬🇲",
      code: "GM",
      dialCode: "+220",
      mask: "999 9999",
    },
    {
      country: "Georgia",
      flag: "🇬🇪",
      code: "GE",
      dialCode: "+995",
      mask: "999 99 99 99",
    },
    {
      country: "Alemanha",
      flag: "🇩🇪",
      code: "DE",
      dialCode: "+49",
      mask: "99999 9999999",
    },
    {
      country: "Gana",
      flag: "🇬🇭",
      code: "GH",
      dialCode: "+233",
      mask: "999 999 9999",
    },
    {
      country: "Gibraltar",
      flag: "🇬🇮",
      code: "GI",
      dialCode: "+350",
      mask: "99999999",
    },
    {
      country: "Grécia",
      flag: "🇬🇷",
      code: "GR",
      dialCode: "+30",
      mask: "999 999 9999",
    },
    {
      country: "Groenlândia",
      flag: "🇬🇱",
      code: "GL",
      dialCode: "+299",
      mask: "99 99 99",
    },
    {
      country: "Grenada",
      flag: "🇬🇩",
      code: "GD",
      dialCode: "+1473",
      mask: "(999) 999-9999",
    },
    {
      country: "Guadalupe",
      flag: "🇬🇵",
      code: "GP",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      country: "Guam",
      flag: "🇬🇺",
      code: "GU",
      dialCode: "+1671",
      mask: "(999) 999-9999",
    },
    {
      country: "Guatemala",
      flag: "🇬🇹",
      code: "GT",
      dialCode: "+502",
      mask: "9999 9999",
    },
    {
      country: "Guernsey",
      flag: "🇬🇬",
      code: "GG",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      country: "Guiné",
      flag: "🇬🇳",
      code: "GN",
      dialCode: "+224",
      mask: "999 99 99 99",
    },
    {
      country: "Guiné-bissau",
      flag: "🇬🇼",
      code: "GW",
      dialCode: "+245",
      mask: "999 999 999",
    },
    {
      country: "Guiana",
      flag: "🇬🇾",
      code: "GY",
      dialCode: "+592",
      mask: "999 9999",
    },
    {
      country: "Haiti",
      flag: "🇭🇹",
      code: "HT",
      dialCode: "+509",
      mask: "99 99 9999",
    },
    {
      country: "Honduras",
      flag: "🇭🇳",
      code: "HN",
      dialCode: "+504",
      mask: "9999-9999",
    },
    {
      country: "Hong Kong",
      flag: "🇭🇰",
      code: "HK",
      dialCode: "+852",
      mask: "9999 9999",
    },
    {
      country: "Hungria",
      flag: "🇭🇺",
      code: "HU",
      dialCode: "+36",
      mask: "(99) 999 9999",
    },
    {
      country: "Islândia",
      flag: "🇮🇸",
      code: "IS",
      dialCode: "+354",
      mask: "999 9999",
    },
    {
      country: "India",
      flag: "🇮🇳",
      code: "IN",
      dialCode: "+91",
      mask: "99999 99999",
    },
    {
      country: "Indonésia",
      flag: "🇮🇩",
      code: "ID",
      dialCode: "+62",
      mask: "9999-999-999",
    },
    {
      country: "Irã, República Islâmica do Golfo Pérsico",
      flag: "🇮🇷",
      code: "IR",
      dialCode: "+98",
      mask: "9999 999 9999",
    },
    {
      country: "Iraque",
      flag: "🇮🇶",
      code: "IQ",
      dialCode: "+964",
      mask: "9999 999 9999",
    },
    {
      country: "Irlanda",
      flag: "🇮🇪",
      code: "IE",
      dialCode: "+353",
      mask: "999 999 9999",
    },
    {
      country: "Ilha de Man",
      flag: "🇮🇲",
      code: "IM",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      country: "Israel",
      flag: "🇮🇱",
      code: "IL",
      dialCode: "+972",
      mask: "999-999-9999",
    },
    {
      country: "Itália",
      flag: "🇮🇹",
      code: "IT",
      dialCode: "+39",
      mask: "999 999 9999",
    },
    {
      country: "Jamaica",
      flag: "🇯🇲",
      code: "JM",
      dialCode: "+1876",
      mask: "(999) 999-9999",
    },
    {
      country: "Japão",
      flag: "🇯🇵",
      code: "JP",
      dialCode: "+81",
      mask: "999-9999-9999",
    },
    {
      country: "Jersey",
      flag: "🇯🇪",
      code: "JE",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      country: "Jordânia",
      flag: "🇯🇴",
      code: "JO",
      dialCode: "+962",
      mask: "99 9999 9999",
    },
    {
      country: "Cazaquistão",
      flag: "🇰🇿",
      code: "KZ",
      dialCode: "+7",
      mask: "(999) 999 9999",
    },
    {
      country: "Quênia",
      flag: "🇰🇪",
      code: "KE",
      dialCode: "+254",
      mask: "9999 999999",
    },
    {
      country: "Kiribati",
      flag: "🇰🇮",
      code: "KI",
      dialCode: "+686",
      mask: "99999999",
    },
    {
      country: "Coreia, República Popular Democrática da Coreia",
      flag: "🇰🇵",
      code: "KP",
      dialCode: "+850",
      mask: "(9) 99999",
    },
    {
      country: "Coreia, República da Coreia do Sul",
      flag: "🇰🇷",
      code: "KR",
      dialCode: "+82",
      mask: "999-9999-9999",
    },
    {
      country: "Kosovo",
      flag: "🇽🇰",
      code: "XK",
      dialCode: "+383",
      mask: "999 999 999",
    },
    {
      country: "Kuwait",
      flag: "🇰🇼",
      code: "KW",
      dialCode: "+965",
      mask: "999 99999",
    },
    {
      country: "Quirguistão",
      flag: "🇰🇬",
      code: "KG",
      dialCode: "+996",
      mask: "9999 999 999",
    },
    {
      country: "Laos",
      flag: "🇱🇦",
      code: "LA",
      dialCode: "+856",
      mask: "999 99 999 999",
    },
    {
      country: "Letônia",
      flag: "🇱🇻",
      code: "LV",
      dialCode: "+371",
      mask: "99 999 999",
    },
    {
      country: "Líbano",
      flag: "🇱🇧",
      code: "LB",
      dialCode: "+961",
      mask: "99 999 999",
    },
    {
      country: "Lesoto",
      flag: "🇱🇸",
      code: "LS",
      dialCode: "+266",
      mask: "9999 9999",
    },
    {
      country: "Libéria",
      flag: "🇱🇷",
      code: "LR",
      dialCode: "+231",
      mask: "999 999 9999",
    },
    {
      country: "Jamahiriya Árabe da Líbia",
      flag: "🇱🇾",
      code: "LY",
      dialCode: "+218",
      mask: "999-9999999",
    },
    {
      country: "Liechtenstein",
      flag: "🇱🇮",
      code: "LI",
      dialCode: "+423",
      mask: "999 999 999",
    },
    {
      country: "Lituânia",
      flag: "🇱🇹",
      code: "LT",
      dialCode: "+370",
      mask: "(9-999) 9999",
    },
    {
      country: "Luxemburgo",
      flag: "🇱🇺",
      code: "LU",
      dialCode: "+352",
      mask: "999 999 999",
    },
    {
      country: "Macau",
      flag: "🇲🇴",
      code: "MO",
      dialCode: "+853",
      mask: "9999 9999",
    },
    {
      country: "Macedonia",
      flag: "🇲🇰",
      code: "MK",
      dialCode: "+389",
      mask: "999 999 999",
    },
    {
      country: "Madagáscar",
      flag: "🇲🇬",
      code: "MG",
      dialCode: "+261",
      mask: "999 99 999 99",
    },
    {
      country: "Malawi",
      flag: "🇲🇼",
      code: "MW",
      dialCode: "+265",
      mask: "9999 99 99 99",
    },
    {
      country: "Malásia",
      flag: "🇲🇾",
      code: "MY",
      dialCode: "+60",
      mask: "999-999 9999",
    },
    {
      country: "Maldivas",
      flag: "🇲🇻",
      code: "MV",
      dialCode: "+960",
      mask: "999-9999",
    },
    {
      country: "Mali",
      flag: "🇲🇱",
      code: "ML",
      dialCode: "+223",
      mask: "99 99 99 99",
    },
    {
      country: "Malta",
      flag: "🇲🇹",
      code: "MT",
      dialCode: "+356",
      mask: "9999 9999",
    },
    {
      country: "Ilhas Marshall",
      flag: "🇲🇭",
      code: "MH",
      dialCode: "+692",
      mask: "999-9999",
    },
    {
      country: "Martinica",
      flag: "🇲🇶",
      code: "MQ",
      dialCode: "+596",
      mask: "9999 99 99 99",
    },
    {
      country: "Mauritânia",
      flag: "🇲🇷",
      code: "MR",
      dialCode: "+222",
      mask: "99 99 99 99",
    },
    {
      country: "Maurícia",
      flag: "🇲🇺",
      code: "MU",
      dialCode: "+230",
      mask: "9999 9999",
    },
    {
      country: "Mayotte",
      flag: "🇾🇹",
      code: "YT",
      dialCode: "+262",
      mask: "9999 99 99 99",
    },
    {
      country: "México",
      flag: "🇲🇽",
      code: "MX",
      dialCode: "+52",
      mask: "999-999 9999",
    },
    {
      country: "Micronésia, Estados Federados da Micronésia",
      flag: "🇫🇲",
      code: "FM",
      dialCode: "+691",
      mask: "999 9999",
    },
    {
      country: "Moldova",
      flag: "🇲🇩",
      code: "MD",
      dialCode: "+373",
      mask: "9999 99 999",
    },
    {
      country: "Mônaco",
      flag: "🇲🇨",
      code: "MC",
      dialCode: "+377",
      mask: "99 99 99 99 99",
    },
    {
      country: "Mongólia",
      flag: "🇲🇳",
      code: "MN",
      dialCode: "+976",
      mask: "9999 9999",
    },
    {
      country: "Montenegro",
      flag: "🇲🇪",
      code: "ME",
      dialCode: "+382",
      mask: "999 999 999",
    },
    {
      country: "Montserrat",
      flag: "🇲🇸",
      code: "MS",
      dialCode: "+1664",
      mask: "(999) 999-9999",
    },
    {
      country: "Marrocos",
      flag: "🇲🇦",
      code: "MA",
      dialCode: "+212",
      mask: "9999-999999",
    },
    {
      country: "Moçambique",
      flag: "🇲🇿",
      code: "MZ",
      dialCode: "+258",
      mask: "99 999 9999",
    },
    {
      country: "Myanmar",
      flag: "🇲🇲",
      code: "MM",
      dialCode: "+95",
      mask: "99 999 9999",
    },
    {
      country: "Namibia",
      flag: "🇳🇦",
      code: "NA",
      dialCode: "+264",
      mask: "999 999 99999",
    },
    {
      country: "Nauru",
      flag: "🇳🇷",
      code: "NR",
      dialCode: "+674",
      mask: "999 9999",
    },
    {
      country: "Nepal",
      flag: "🇳🇵",
      code: "NP",
      dialCode: "+977",
      mask: "999-9999999",
    },
    {
      country: "Holanda",
      flag: "🇳🇱",
      code: "NL",
      dialCode: "+31",
      mask: "99 99999999",
    },
    {
      country: "Nova Caledônia",
      flag: "🇳🇨",
      code: "NC",
      dialCode: "+687",
      mask: "99.99.99",
    },
    {
      country: "Nova Zelândia",
      flag: "🇳🇿",
      code: "NZ",
      dialCode: "+64",
      mask: "999 999 9999",
    },
    {
      country: "Nicarágua",
      flag: "🇳🇮",
      code: "NI",
      dialCode: "+505",
      mask: "9999 9999",
    },
    {
      country: "Níger",
      flag: "🇳🇪",
      code: "NE",
      dialCode: "+227",
      mask: "99 99 99 99",
    },
    {
      country: "Nigéria",
      flag: "🇳🇬",
      code: "NG",
      dialCode: "+234",
      mask: "9999 999 9999",
    },
    {
      country: "Niue",
      flag: "🇳🇺",
      code: "NU",
      dialCode: "+683",
      mask: "999 9999",
    },
    {
      country: "Ilha Norfolk",
      flag: "🇳🇫",
      code: "NF",
      dialCode: "+672",
      mask: "9 99999",
    },
    {
      country: "Ilhas Marianas do Norte",
      flag: "🇲🇵",
      code: "MP",
      dialCode: "+1670",
      mask: "(999) 999-9999",
    },
    {
      country: "Noruega",
      flag: "🇳🇴",
      code: "NO",
      dialCode: "+47",
      mask: "999 99 999",
    },
    {
      country: "Omã",
      flag: "🇴🇲",
      code: "OM",
      dialCode: "+968",
      mask: "9999 9999",
    },
    {
      country: "Paquistão",
      flag: "🇵🇰",
      code: "PK",
      dialCode: "+92",
      mask: "9999 9999999",
    },
    {
      country: "Palau",
      flag: "🇵🇼",
      code: "PW",
      dialCode: "+680",
      mask: "999 9999",
    },
    {
      country: "Território Palestino, Ocupado",
      flag: "🇵🇸",
      code: "PS",
      dialCode: "+970",
      mask: "9999 999 999",
    },
    {
      country: "Panamá",
      flag: "🇵🇦",
      code: "PA",
      dialCode: "+507",
      mask: "9999-9999",
    },
    {
      country: "Papua Nova Guiné",
      flag: "🇵🇬",
      code: "PG",
      dialCode: "+675",
      mask: "9999 9999",
    },
    {
      country: "Paraguai",
      flag: "🇵🇾",
      code: "PY",
      dialCode: "+595",
      mask: "9999 999999",
    },
    {
      country: "Peru",
      flag: "🇵🇪",
      code: "PE",
      dialCode: "+51",
      mask: "999 999 999",
    },
    {
      country: "Filipinas",
      flag: "🇵🇭",
      code: "PH",
      dialCode: "+63",
      mask: "9999 999 9999",
    },
    {
      country: "Polônia",
      flag: "🇵🇱",
      code: "PL",
      dialCode: "+48",
      mask: "999 999 999",
    },
    {
      country: "Portugal",
      flag: "🇵🇹",
      code: "PT",
      dialCode: "+351",
      mask: "999 999 999",
    },
    {
      country: "Porto Rico",
      flag: "🇵🇷",
      code: "PR",
      dialCode: "+1939",
      mask: "(999) 999-9999",
    },
    {
      country: "Catar",
      flag: "🇶🇦",
      code: "QA",
      dialCode: "+974",
      mask: "9999 9999",
    },
    {
      country: "Romênia",
      flag: "🇷🇴",
      code: "RO",
      dialCode: "+40",
      mask: "9999 999 999",
    },
    {
      country: "Rússia",
      flag: "🇷🇺",
      code: "RU",
      dialCode: "+7",
      mask: "(999) 999-99-99",
    },
    {
      country: "Ruanda",
      flag: "🇷🇼",
      code: "RW",
      dialCode: "+250",
      mask: "9999 999 999",
    },
    {
      country: "São Bartolomeu",
      flag: "🇧🇱",
      code: "BL",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      country: "Santa Helena, Ascensão e Tristão da Cunha",
      flag: "🇸🇭",
      code: "SH",
      dialCode: "+290",
      mask: "99999",
    },
    {
      country: "São Cristóvão e Neves",
      flag: "🇰🇳",
      code: "KN",
      dialCode: "+1869",
      mask: "(999) 999-9999",
    },
    {
      country: "Santa Lúcia",
      flag: "🇱🇨",
      code: "LC",
      dialCode: "+1758",
      mask: "(999) 999-9999",
    },
    {
      country: "São Martinho",
      flag: "🇲🇫",
      code: "MF",
      dialCode: "+590",
      mask: "9999 99 99 99",
    },
    {
      country: "São Pedro e Miquelão",
      flag: "🇵🇲",
      code: "PM",
      dialCode: "+508",
      mask: "999 99 99",
    },
    {
      country: "São Vicente e Granadinas",
      flag: "🇻🇨",
      code: "VC",
      dialCode: "+1784",
      mask: "(999) 999-9999",
    },
    {
      country: "Samoa",
      flag: "🇼🇸",
      code: "WS",
      dialCode: "+685",
      mask: "99 99999",
    },
    {
      country: "San Marino",
      flag: "🇸🇲",
      code: "SM",
      dialCode: "+378",
      mask: "99 99 99 99",
    },
    {
      country: "São Tomé e Príncipe",
      flag: "🇸🇹",
      code: "ST",
      dialCode: "+239",
      mask: "999 9999",
    },
    {
      country: "Arábia Saudita",
      flag: "🇸🇦",
      code: "SA",
      dialCode: "+966",
      mask: "999 999 9999",
    },
    {
      country: "Senegal",
      flag: "🇸🇳",
      code: "SN",
      dialCode: "+221",
      mask: "99 999 99 99",
    },
    {
      country: "Sérvia",
      flag: "🇷🇸",
      code: "RS",
      dialCode: "+381",
      mask: "999 9999999",
    },
    {
      country: "Seychelles",
      flag: "🇸🇨",
      code: "SC",
      dialCode: "+248",
      mask: "9 999 999",
    },
    {
      country: "Serra Leoa",
      flag: "🇸🇱",
      code: "SL",
      dialCode: "+232",
      mask: "(999) 999999",
    },
    {
      country: "Cingapura",
      flag: "🇸🇬",
      code: "SG",
      dialCode: "+65",
      mask: "9999 9999",
    },
    {
      country: "Eslováquia",
      flag: "🇸🇰",
      code: "SK",
      dialCode: "+421",
      mask: "9999 999 999",
    },
    {
      country: "Eslovênia",
      flag: "🇸🇮",
      code: "SI",
      dialCode: "+386",
      mask: "999 999 999",
    },
    {
      country: "Ilhas Salomão",
      flag: "🇸🇧",
      code: "SB",
      dialCode: "+677",
      mask: "99 99999",
    },
    {
      country: "Somália",
      flag: "🇸🇴",
      code: "SO",
      dialCode: "+252",
      mask: "9 9999999",
    },
    {
      country: "África do Sul",
      flag: "🇿🇦",
      code: "ZA",
      dialCode: "+27",
      mask: "999 999 9999",
    },
    {
      country: "Sudão do Sul",
      flag: "🇸🇸",
      code: "SS",
      dialCode: "+211",
      mask: "9999 999 999",
    },
    {
      country: "Espanha",
      flag: "🇪🇸",
      code: "ES",
      dialCode: "+34",
      mask: "999 99 99 99",
    },
    {
      country: "Sri Lanka",
      flag: "🇱🇰",
      code: "LK",
      dialCode: "+94",
      mask: "999 999 9999",
    },
    {
      country: "Sudão",
      flag: "🇸🇩",
      code: "SD",
      dialCode: "+249",
      mask: "999 999 9999",
    },
    {
      country: "Suriname",
      flag: "🇸🇷",
      code: "SR",
      dialCode: "+597",
      mask: "999-9999",
    },
    {
      country: "Svalbard e Jan Mayen",
      flag: "🇸🇯",
      code: "SJ",
      dialCode: "+47",
      mask: "999 99 999",
    },
    {
      country: "Suazilândia",
      flag: "🇸🇿",
      code: "SZ",
      dialCode: "+268",
      mask: "9999 9999",
    },
    {
      country: "Suécia",
      flag: "🇸🇪",
      code: "SE",
      dialCode: "+46",
      mask: "999-999 99 99",
    },
    {
      country: "Suíça",
      flag: "🇨🇭",
      code: "CH",
      dialCode: "+41",
      mask: "999 999 99 99",
    },
    {
      country: "República Árabe da Síria",
      flag: "🇸🇾",
      code: "SY",
      dialCode: "+963",
      mask: "9999 999 999",
    },
    {
      country: "Taiwan",
      flag: "🇹🇼",
      code: "TW",
      dialCode: "+886",
      mask: "9999 999 999",
    },
    {
      country: "Tajiquistão",
      flag: "🇹🇯",
      code: "TJ",
      dialCode: "+992",
      mask: "999 99 9999",
    },
    {
      country: "Tanzânia, República Unida da Tanzânia",
      flag: "🇹🇿",
      code: "TZ",
      dialCode: "+255",
      mask: "9999 999 999",
    },
    {
      country: "Tailândia",
      flag: "🇹🇭",
      code: "TH",
      dialCode: "+66",
      mask: "999 999 9999",
    },
    {
      country: "Timor-Leste",
      flag: "🇹🇱",
      code: "TL",
      dialCode: "+670",
      mask: "9999 9999",
    },
    {
      country: "Togo",
      flag: "🇹🇬",
      code: "TG",
      dialCode: "+228",
      mask: "99 99 99 99",
    },
    {
      country: "Tokelau",
      flag: "🇹🇰",
      code: "TK",
      dialCode: "+690",
      mask: "9999",
    },
    {
      country: "Tonga",
      flag: "🇹🇴",
      code: "TO",
      dialCode: "+676",
      mask: "999 9999",
    },
    {
      country: "Trinidad e Tobago",
      flag: "🇹🇹",
      code: "TT",
      dialCode: "+1868",
      mask: "(999) 999-9999",
    },
    {
      country: "Tunísia",
      flag: "🇹🇳",
      code: "TN",
      dialCode: "+216",
      mask: "99 999 999",
    },
    {
      country: "Turquia",
      flag: "🇹🇷",
      code: "TR",
      dialCode: "+90",
      mask: "(999) 999 9999",
    },
    {
      country: "Turcomenistão",
      flag: "🇹🇲",
      code: "TM",
      dialCode: "+993",
      mask: "9 99 999999",
    },
    {
      country: "Ilhas Turcas e Caicos",
      flag: "🇹🇨",
      code: "TC",
      dialCode: "+1649",
      mask: "(999) 999-9999",
    },
    {
      country: "Tuvalu",
      flag: "🇹🇻",
      code: "TV",
      dialCode: "+688",
      mask: "999999",
    },
    {
      country: "Uganda",
      flag: "🇺🇬",
      code: "UG",
      dialCode: "+256",
      mask: "9999 999999",
    },
    {
      country: "Ucrânia",
      flag: "🇺🇦",
      code: "UA",
      dialCode: "+380",
      mask: "999 999 9999",
    },
    {
      country: "Emirados Árabes Unidos",
      flag: "🇦🇪",
      code: "AE",
      dialCode: "+971",
      mask: "999 999 9999",
    },
    {
      country: "Reino Unido",
      flag: "🇬🇧",
      code: "GB",
      dialCode: "+44",
      mask: "99999 999999",
    },
    {
      country: "Estados Unidos",
      flag: "🇺🇸",
      code: "US",
      dialCode: "+1",
      mask: "(999) 999-9999",
    },
    {
      country: "Uruguai",
      flag: "🇺🇾",
      code: "UY",
      dialCode: "+598",
      mask: "99 999 999",
    },
    {
      country: "Uzbequistão",
      flag: "🇺🇿",
      code: "UZ",
      dialCode: "+998",
      mask: "9 99 999 99 99",
    },
    {
      country: "Vanuatu",
      flag: "🇻🇺",
      code: "VU",
      dialCode: "+678",
      mask: "999 9999",
    },
    {
      country: "Venezuela, República Bolivariana da Venezuela",
      flag: "🇻🇪",
      code: "VE",
      dialCode: "+58",
      mask: "9999-9999999",
    },
    {
      country: "Vietnã",
      flag: "🇻🇳",
      code: "VN",
      dialCode: "+84",
      mask: "999 999 99 99",
    },
    {
      country: "Wallis e Futuna",
      flag: "🇼🇫",
      code: "WF",
      dialCode: "+681",
      mask: "99 99 99",
    },
    {
      country: "Iémen",
      flag: "🇾🇪",
      code: "YE",
      dialCode: "+967",
      mask: "999 999 999",
    },
    {
      country: "Zâmbia",
      flag: "🇿🇲",
      code: "ZM",
      dialCode: "+260",
      mask: "999 9999999",
    },
    {
      country: "Zimbábue",
      flag: "🇿🇼",
      code: "ZW",
      dialCode: "+263",
      mask: "999 999 9999",
    },
  ];