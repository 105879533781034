import React from "react";
import imgBus from "../../imgs/icon-frete.svg";
import imgMoney from "../../imgs/icon-money.svg";
import imgEdit from "../../imgs/pen-solid.svg";
import plusIcon from "../../imgs/plus.svg";
// import imgRemove from "../../imgs/remove-address.svg";

import "./styles.scss";

export default function CardAddress(props) {
  const {
    recipient,
    contactName,
    address,
    city,
    state,
    zipCodeXX,
    country,
    numberPhone,
    onclick,
    type_adress,
    setOpenAdress,
    taxId,
    type,
  } = props;

  const fields = [
    recipient,
    contactName,
    address,
    city,
    state,
    zipCodeXX,
    country,
    numberPhone,
    type_adress,
    taxId,
  ];
  const hasOneField = fields.some((item) => item !== "");

  return (
    <div className="box-card-address">
      {hasOneField ? (
        <>
          <div className="box-card-address__box-left">
            <div className="box-card-address__box-left__infos">
              <p>{`${type}`}</p>
              <p>
                {`${recipient}`} - {`${contactName}`}
              </p>
              <p>
                {`${taxId || ""}`} {`${numberPhone}`}
              </p>
              <p>{`${address}`}</p>
            </div>
            <button
              onClick={() => {
                setOpenAdress(true);
                onclick({
                  recipient: recipient || "",
                  contactName: contactName || "",
                  address: address || "",
                  city: city || "",
                  state: state || "",
                  zipCodeXX: zipCodeXX || "",
                  country: country || "",
                  type_adress: type_adress || "",
                  phone: numberPhone || "",
                  taxId: taxId || "",
                });
              }}
              className="box-card-address__box-left__btn-edit-address"
              type="button"
            >
              <img
                src={imgEdit}
                alt="imagem em forto de circulo em cor preta e um x em branco"
              />
            </button>
            <button
              className="box-card-address__box-left__btn-delete-address"
              type="button"
            >
              {/* <img src={imgRemove} alt="imagem em forto de circulo em cor preta e um x em branco" /> */}
            </button>
          </div>
          <div className="box-card-address__box-rigth">
            <div className="box-card-address__box-rigth__infos">
              <p>
                {`${city}`} / {`${state}`}
              </p>
              <p>
                {`${zipCodeXX}`} / {`${country}`}
              </p>
            </div>
            <div className="box-card-address__box-rigth__infos__box-icons">
              {type_adress.includes(0) && (
                <img src={imgBus} alt="imagem de caminhao" />
              )}
              {type_adress.includes(1) && (
                <img src={imgMoney} alt="imagem cifrão" />
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="add-address">
          <h3>{type}</h3>
          <button
            onClick={() => {
              setOpenAdress(true);
              onclick({
                type_adress: type_adress,
              });
            }}
          >
            <img src={plusIcon} alt="" />
          </button>
        </div>
      )}
    </div>
  );
}
