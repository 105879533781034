import React, {Component} from 'react';

const Style = require("../style/PatternStyle.scss");

class PatternContainerComponent extends Component {
    render() {
        const { children } = this.props;
        return (
            <div className={Style.container}>{children}</div>
        );
    }
}

export default PatternContainerComponent;