import React, { Component } from "react";
import "react-id-swiper/lib/styles/scss/swiper.scss";
import "./RegisterBrandViewComponent.scss";
import axios from "axios";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LightBoxComponent from "../../../shared/lightbox/components/LightBoxComponent";
import LoginComponent from "../../../shared/Login/components/LoginComponent";
import RegisterModalComponent from "../../../shared/registermodal/components/RegisterModalComponent";
import { Helmet } from "react-helmet-async";

const RenderLogin = (props) => {
  const { show, handleClick, email, openRegister } = props;

  return (
    <LightBoxComponent
      isOpen={show}
      handleClick={handleClick}
      handleEventClose={handleClick}
    >
      <LoginComponent email={email} OpenRegister={openRegister} />
    </LightBoxComponent>
  );
};

class RegisterBrandViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandData: [],
      loading: true,
      showLightBox: false,
      showRegister: false,
      email: "",
      error: false,
    };

    this.closeRegister = this.closeRegister.bind(this);
    this.OpenRegister = this.OpenRegister.bind(this);
  }

  timer = null;

  OpenRegister() {
    this.setState({
      showRegister: true,
    });
  }

  closeRegister() {
    this.setState({
      showRegister: false,
    });
  }

  loadPageData() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/${this.props.seller}/brand-page`
      )
      .then((response) => {
        this.setState({ brandData: response.data, loading: false });
      });
  }

  componentDidMount() {
    this.loadPageData();
  }

  defineEmail(email) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ email: email });
    }, 500);
  }

  validateEmail(email) {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  verifyEmail = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.validateEmail(this.state.email)) {
        this.setState({ error: true });
        return;
      }

      axios
        .post(`${process.env.REACT_APP_NEW_API_URL}/v1/auth/check-email`, {
          email: this.state.email,
        })
        .then((response) => {
          if (response.data) {
            this.setState({
              showLightBox: true,
            });
          } else {
            this.setState({
              showRegister: true,
            });
          }
        });
    }, 1000);
  };

  render() {
    const { brandData, loading } = this.state;
    const { t } = this.props;

    if (loading) {
      return <LoadingComponent />;
    }

    return (
      <div className="register-acount">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t(`${brandData.name} | Register`)}</title>
          <meta
            name="keywords"
            content="register, login, brands, fashion, buy, sell"
          />
          <meta http-equiv="content-language" content="en" />
          <meta
            name="description"
            content="Register for exclusive access to promotions, new collections and personalized features."
          />
        </Helmet>
        <div className="register-acount--container">
          <div className="register-acount--container__topContent">
            {brandData.the_brand.brand_style_images[0] && (
              <img
                src={brandData.the_brand.brand_style_images[0].image_original}
                className="register-acount--container__topContent__img"
                alt=""
              />
            )}
            <div className="register-acount--container__topContent__left">
              {brandData.the_brand.brand_style_images[0] ? (
                <div className="register-acount--container__topContent__left--unlockText">
                  <span>
                    {t(
                      `Unlock ${brandData.name} wholesale prices and collections`,
                      `Unlock ${brandData.name} wholesale prices and collections`
                    )}
                  </span>
                </div>
              ) : (
                <div className="register-acount--container__topContent__left--unlockText__noImg">
                  <span>
                    {t(
                      `Unlock ${brandData.name} wholesale prices and collections`,
                      `Unlock ${brandData.name} wholesale prices and collections`
                    )}
                  </span>
                </div>
              )}

              <div className="register-acount--container__topContent__left--infoData">
                <span className="register-acount--container__topContent__left--infoData__text">
                  {t(
                    `Discover emerging and fantastic brands, find all information you need and buy their products online to receive them wherever you are, through a seamless ordering process.`,
                    `Discover emerging and fantastic brands, find all information you need and buy their products online to receive them wherever you are, through a seamless ordering process.`
                  )}
                </span>
                <div className="register-acount--container__topContent__left--infoData__create">
                  <input
                    placeholder={t(
                      "Business email adress",
                      "Business email adress"
                    )}
                    onChange={(e) => {
                      this.defineEmail(e.target.value);
                    }}
                  />
                  <button
                    className="register-acount--container__topContent__left--infoData__btn"
                    type="button"
                    onClick={this.verifyEmail}
                  >
                    {t("CREATE ACCOUNT", "CREATE ACCOUNT")}
                  </button>
                </div>
                {this.state.error ? (
                  <p className="error-email">
                    {t("Check email field", "Check email field")}
                  </p>
                ) : (
                  <></>
                )}
                <div className="register-acount--container__topContent__left--infoData__bar" />
                <div className="register-acount--container__topContent__left--infoData__registered">
                  <span>{t("Already registered?", "Already registered?")}</span>
                  <button
                    onClick={() =>
                      this.setState({
                        showLightBox: true,
                      })
                    }
                    className="register-acount--container__topContent__left--infoData__btn__registered"
                  >
                    {t("LOGIN", "LOGIN")}
                  </button>
                  {this.state.showLightBox && (
                    <RenderLogin
                      show={this.state.showLightBox}
                      handleClick={() =>
                        this.setState({
                          showLightBox: false,
                        })
                      }
                      openRegister={this.OpenRegister}
                      email={this.state.email}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="register-acount--container__bottomContent">
            <span>
              {t("Are you a brand?", "Are you a brand?")}{" "}
              <a
                onClick={() => {
                  this.setState({
                    showRegister: true,
                  });
                }}
              >
                {t(
                  "Register for a Seller Account",
                  "Register for a Seller Account"
                )}
              </a>
            </span>
            {this.state.showRegister && (
              <RegisterModalComponent
                close={() => this.setState({ showRegister: false })}
                email={this.state.email}
              />
            )}
          </div>

          <Link to="/brands" className="register-acount--container__back">
            <span>{t("<< Back to brands", "<< Back to brands")}</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(RegisterBrandViewComponent));
