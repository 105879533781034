import React, {Component} from 'react';
import ReactDOMServer from 'react-dom/server';

const Style = require("../style/PatternStyle.scss");

class PatternContainerComponent extends Component {
    render() {
        const { children, title } = this.props;
        return (
            <div className={Style.containerComponent}>
                {title ? <div className={'titleComponent'}>{title}</div> : null}
                <div className={'content'}>{children}</div>
                <div className={'contentCode'}>
                    <pre>{ReactDOMServer.renderToStaticMarkup(children)}</pre>
                </div>
            </div>
        );
    }
}

export default PatternContainerComponent;