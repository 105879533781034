import React from "react";
import "./styles.scss";

export default function ButtonNoBg(props){
    const { onclick, disabled } = props
    return(
        <button disabled={disabled} type="button" onClick={onclick} className="buttonNoBg">
            change password
        </button>
    )
}