import React, { Component } from "react";

import ForgotPasswordComponent from "../../ForgotPassword/components/ForgotPasswordComponent";
import LightBoxComponent from "../../lightbox/components/LightBoxComponent";
import AuthenticationService from "../../../../services/AuthenticationService";
import { withTranslation } from "react-i18next";

import "../style/LoginStyle.scss";
import ChangePasswordComponent from "../../ChangePassword/components/ChangePasswordComponent";
import SpinnerComponent from "../../spinner";

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showForgotPasswordModal: false,
      showChangePasswordModal: false,
      changePasswordData: null,
      isLoading: false,
    };
  }


  componentDidMount() {
    if(this.props.email) {
      this.setState({
        email: this.props.email,
      })
    }
  }
  onChangeEmail(ev) {
    this.setState({
      email: ev.target.value,
    });
  }

  onChangePassword(ev) {
    this.setState({
      password: ev.target.value,
    });
  }

  authUser() {
    this.setState({
      isLoading: true,
    });

    if (this.props.evento) {
      localStorage.setItem("vincular_evento", `${window.location.pathname}`);
    }

    AuthenticationService.login(this.state).catch((ex) => {
      this.setState({
        isLoading: false,
      });
      if (ex.error === "invalid_grant") {
        this.setState({ showForgotPasswordModal: true });
      } else if (ex.error === "password_not_changed") {
        this.setState({
          showChangePasswordModal: true,
          changePasswordData: ex,
        });
      } else if (ex.error) {
        this.setState({
          showChangePasswordModal: true,
          changePasswordData: ex.error,
        });
      }
    });
  }

  closeForgotPasswordModal() {
    this.setState({
      showForgotPassword: false,
      showForgotPasswordModal: false,
    });
  }

  closeChangePasswordModalSuccess(self, newPassword) {
    self.setState(
      {
        changePasswordData: null,
        password: newPassword,
      },
      () => {
        self.authUser();
      }
    );
  }

  closeChangePasswordModal() {
    this.setState({
      showChangePasswordModal: false,
      changePasswordData: null,
    });
  }

  closeModal() {
    this.setState({
      showForgotPasswordModal: false,
    });
  }

  render() {
    const {t} = this.props
    return (
      <div>
        <div id="contentLogin">
          <h2>{t("WELCOME BACK", "WELCOME BACK")}!</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.authUser();
            }}
          >
            <div className="formItem">
              <input
                placeholder={t("Email","Email")}
                type="email"
                id="email"
                value={this.state.email}
                onChange={(ev) => this.onChangeEmail(ev)}
              />
            </div>
            <div className="formItem">
              <input
                placeholder={t("Password", "Password")}
                type="password"
                id="password"
                value={this.state.password}
                onChange={(ev) => this.onChangePassword(ev)}
              />
            </div>

            <div className="formItem textRight sign-in-buttons">
              <span
                onClick={() => this.setState({ showForgotPasswordModal: true })}
              >
                {t('Forgot your password?','Forgot your password?')}
              </span>
              <button type="submit" className="button">
                {this.state.isLoading ? <SpinnerComponent /> : t("Sign In", "Sign In")}
              </button>
            </div>
          </form>
          <div className="not-registered-box">
            <span>{t("Not registered yet", "Not registered yet")}?</span>
            <button
              className="button"
              onClick={() => this.props.OpenRegister()}
            >
              {t("Register for BLANC", "Register for BLANC")}
            </button>
          </div>
        </div>
        <LightBoxComponent
          extraClass="forgot-password"
          isOpen={this.state.showForgotPasswordModal}
          handleClick={this.closeModal.bind(this)}
          handleEventClose={this.closeModal.bind(this)}
        >
          <ForgotPasswordComponent
            closeForgotPasswordModal={this.closeForgotPasswordModal.bind(this)}
          />
        </LightBoxComponent>
        <LightBoxComponent
          isOpen={this.state.showChangePasswordModal}
          handleClick={this.closeChangePasswordModal.bind(this)}
          handleEventClose={this.closeChangePasswordModal.bind(this)}
        >
          <ChangePasswordComponent
            closeForgotPasswordModal={(newPassword) =>
              this.closeChangePasswordModalSuccess(this, newPassword)
            }
            loginData={this.state.changePasswordData}
          />
        </LightBoxComponent>
      </div>
    );
  }
}

export default withTranslation()(LoginComponent);
