import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getCurrentPriceByPriceList } from "../../../../../utils/productHelper";
import { withTranslation } from "react-i18next";

import LightboxHalfScreenComponent from "../../../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";

const PricesProductComponent = (props) => {
  const { prices, t } = props;

  const [openPricelistPopup, setOpenPricelistPopup] = useState(false);
  const [pricelist, setPricelist] = useState("");

  const pricelistStoraged = Number(localStorage.getItem("user.pricelist"));
  const pricelistSelected = prices.pricelists.filter(
    (pricelist) => pricelist.pricelist.id === pricelistStoraged
  );

  useEffect(() => {
    if (pricelistSelected.length === 0 || !pricelistStoraged) {
      setOpenPricelistPopup(true);
    }
  }, []);

  const handlePricelist = () => {
    localStorage.setItem("user.pricelist", pricelist);
    window.location.reload();
  };

  return (
    <>
      <div className="pricesProducts">
        <p>
          <span
            data-tip={
              pricelistSelected.length >= 1
                ? pricelistSelected[0].pricelist.name
                : "Select pricelist"
            }
            data-for={`wholesale_price_${prices.id}`}
          >
            {t("PRICELIST", "PRICELIST")} |{" "}
          </span>
          {pricelistSelected.length >= 1
            ? pricelistSelected[0].pricelist.name
            : "Select pricelist"}
          <span onClick={() => setOpenPricelistPopup(true)}>
            <FontAwesomeIcon icon={faPen} className="texttoggledit-icon" />
          </span>
        </p>

        <p>
          <span
            data-tip={
              pricelistSelected.length >= 1
                ? pricelistSelected[0].pricelist.name
                : "Select pricelist"
            }
            data-for={`wholesale_price_${prices.id}`}
          >
            {t("WHOLESALE", "WHOLESALE")} |{" "}
          </span>
          <ReactTooltip
            className="variation-tooltip"
            id={`wholesale_price_${prices.id}`}
            place="top"
            type="light"
            effect="solid"
          />
          {getCurrentPriceByPriceList(prices).wholesale_price.currency}{" "}
          {getCurrentPriceByPriceList(prices).wholesale_price.amount.replace(
            /^([0-9]+)\.([0-9]{2}).*/,
            "$1.$2"
          )}
        </p>

        <p>
          <span
            data-tip={prices.retailer_price_tip}
            data-for={`retailer_price_${prices.id}`}
          >
            {t("SUGGESTED RETAIL", "SUGGESTED RETAIL")} |{" "}
          </span>
          <ReactTooltip
            className="variation-tooltip"
            id={`retailer_price_${prices.id}`}
            place="top"
            type="light"
            effect="solid"
          />
          {getCurrentPriceByPriceList(prices).retailer_price.currency}{" "}
          {getCurrentPriceByPriceList(prices).retailer_price.amount.replace(
            /^([0-9]+)\.([0-9]{2}).*/,
            "$1.$2"
          )}
        </p>

        <p>
          <span>{t("EXPECTED TO SHIP", "EXPECTED TO SHIP")} | </span>{" "}
          {prices.ship_msg}
        </p>
      </div>

      {openPricelistPopup && (
        <div className="brandLightBox pricelistModal">
          <LightboxHalfScreenComponent
            close={() => setOpenPricelistPopup(false)}
          >
            <h1 style={{}}>Select pricelist</h1>

            {prices.pricelists && prices.pricelists.length > 0 ? (
              <div className="selectContainer">
                <select
                  value={pricelist !== "" ? pricelist : pricelistStoraged}
                  onChange={(e) =>
                    setPricelist(
                      e.target.value !== "" ? e.target.value : pricelistStoraged
                    )
                  }
                >
                  <option value="">Select pricelist</option>
                  {prices.pricelists.map((pricelist) => (
                    <option
                      value={pricelist.pricelist.id}
                      key={pricelist.pricelist.id}
                    >
                      {pricelist.pricelist.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            <button onClick={handlePricelist} disabled={pricelist === ""}>
              Set pricelist
            </button>
          </LightboxHalfScreenComponent>
        </div>
      )}
    </>
  );
};

export default withTranslation()(PricesProductComponent);
