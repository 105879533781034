const SAVE = "SAVE_BUYER";
const SAVE_LOCATIONS = "SAVE_LOCATIONS";

export function save(data) {
  return {
    type: SAVE,
    payload: data,
  };
}

export function saveLocations(data) {
  return {
    type: SAVE_LOCATIONS,
    payload: data,
  };
}
