const SAVE = 'SAVE_BRANDS';

export function brandsReducer (state = [], action) {
    switch (action.type) {
        case SAVE:
            return action.payload;
        default:
            return state;
    }
}
