import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class NavCategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.checkeds = [];
  }

  getChecked(ev) {
    const filters = this.props.getFilters();
    this.checkeds =
      typeof filters.categories != "undefined" ? filters.categories : [];
    const index = this.checkeds.indexOf(ev.target.id);
    if (ev.target.checked && index === -1) {
      this.checkeds.push(ev.target.id);
    } else if (!ev.target.checked && index >= 0) {
      this.checkeds.splice(index, 1);
    }

    this.props.setFilters("categories", this.checkeds);
    this.props.setCurrentPageOne();
  }

  render() {
    const { categories, t } = this.props;

    const filters = this.props.getFilters();

    return (
      <div className="contentFilter checkCategory">
        <p>{t("SUBCATEGORY", "SUBCATEGORY")}</p>

        <ul>
          {categories.map((category, index) => {
            const checked =
              filters.categories &&
              filters.categories.includes(category.id.toString());

            return (
              <li key={index}>
                <div className="checkbox-group">
                  <label className="filter-checkbox" htmlFor={category.id}>
                    {" "}
                    {category.name}
                    <input
                      checked={checked ? checked : false}
                      type="checkbox"
                      id={category.id}
                      onChange={(ev) => this.getChecked(ev)}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default withTranslation()(NavCategoryComponent);
