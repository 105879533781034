import React from "react";
import InputProfile from "../../../../../components/InputProfile";

import ButtonNoBg from "../../../../../components/buttonNoBg/index.jsx";

import Axios from "axios";
import "react-phone-input-2/lib/style.css";
import ButtonBgProfile from "../../../../../components/buttonWithBgProfile/index.jsx";
import { testForm } from "../../../../../utils/formValidate.js";
import "./styles.scss";

import PhoneInput from "react-phone-input-2";
import NewPass from "../../../../../components/changePass/index.jsx";
import VerifyCode from "../../../../../components/verifyCode/index.jsx";
import iconError from "../../../../../imgs/icon-error.svg";
import iconSucess from "../../../../../imgs/icon-success.svg";
import { countriesPhones } from "../../../../../utils/countryPhones.js";

export default function FormPersonal(props) {
  const [valuesForm, setValuesForm] = React.useState([]);
  let [countryPhoneSelected, setCountryPhoneSelected] = React.useState();

  React.useEffect(() => {
    setValuesForm(JSON.parse(localStorage.getItem("data0")).data);
  }, []);

  React.useEffect(() => {
    setCountryPhoneSelected(
      valuesForm.phone &&
        countriesPhones.find((el) =>
          el.dialCode.startsWith(`+${valuesForm.phone.slice(0, 2)}`)
        )
    );
  }, [valuesForm]);

  const [messageRequest, setMessageRequest] = React.useState("");
  const [renderNewPass, setRenderNewPass] = React.useState("");
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [errorState, setErrorState] = React.useState({
    firstName: false,
    lastName: false,
    phone: false,
  });

  function changeValuesForm(inputName, newValue) {
    const values = { ...valuesForm };
    values[inputName] = newValue;

    setValuesForm(values);
  }

  async function updateUser() {
    const validate = [];
    if (!testForm("text1", valuesForm.first_name)) {
      setErrorState((state) => ({ ...state, firstName: true }));
      validate.push("firstName");
    } else {
      setErrorState((state) => ({ ...state, firstName: false }));
    }

    if (!testForm("text1", valuesForm.last_name)) {
      setErrorState((state) => ({ ...state, lastName: true }));
      validate.push("lastName");
    } else {
      setErrorState((state) => ({ ...state, lastName: false }));
    }

    if (valuesForm.phone.length < 5) {
      setErrorState((state) => ({ ...state, phone: true }));
      validate.push("phone");
    } else {
      setErrorState((state) => ({ ...state, phone: false }));
    }

    if (validate.length > 0) {
      return;
    }

    setDisabledItem(true);

    const data = {
      first_name: valuesForm.first_name,
      last_name: valuesForm.last_name,
      phone: valuesForm.phone,
    };

    Axios.defaults.baseURL = process.env.REACT_APP_NEW_API_URL;

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/user/`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    try {
      let response = await Axios(config);

      localStorage.setItem("data0", JSON.stringify(response.data));
      setMessageRequest({ msg: "Success", id: 1 });
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    } finally {
      setInterval(() => {
        setMessageRequest("");
      }, 4200);
      setDisabledItem(false);
    }
  }

  async function requestCode() {
    const data = {
      email: valuesForm.email,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/auth/change-password/generate-code`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    try {
      let response = await Axios(config);
      setRenderNewPass(0);
    } catch (error) {
      setRenderNewPass(0);
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    }
  }

  return (
    <div className="container-form-personal">
      {renderNewPass === 0 && (
        <VerifyCode email={props.data.email} changeStep={setRenderNewPass} />
      )}
      {renderNewPass === 1 && (
        <NewPass email={props.data.email} changeStep={setRenderNewPass} />
      )}

      <div className="container-form-personal__box-inputs">
        <InputProfile
          value={valuesForm.first_name || ''}
          onchange={changeValuesForm}
          nameValue="first_name"
          typeValidation="text1"
          typeInput="text"
          titleInput="First name"
          nameInput="name"
          isRequired
          error={
            errorState.firstName &&
            "Name must have to be 3 characters at least."
          }
        />
        <InputProfile
          value={valuesForm.last_name || ''}
          typeValidation="text1"
          nameValue="last_name"
          onchange={changeValuesForm}
          typeInput="text"
          titleInput="Last name"
          nameInput="sobrenome"
          isRequired
          error={
            errorState.lastName && "Name must have to be 3 characters at least."
          }
        />
        <div className="component-phone">
          <p className="input-required-phone">Phone</p>
          {countryPhoneSelected ? (
            <PhoneInput
              className="select-phone"
              country={
                countryPhoneSelected === null ? "br" : countryPhoneSelected.flag
              }
              localization={"pt"}
              value={valuesForm.phone || ''}
              onChange={(phone, infos) => changeValuesForm("phone", `${phone}`)}
            />
          ) : (
            <PhoneInput
              className="select-phone"
              localization={"pt"}
              value={valuesForm.phone || ''}
              onChange={(phone, infos) => changeValuesForm("phone", `${phone}`)}
            />
          )}
          <p className="box-input-phone-validation">
            {errorState.phone && "Phone field is required."}
          </p>
        </div>
        <InputProfile
          value={valuesForm.email || ''}
          disabled
          typeValidation="email"
          nameValue="email"
          onchange={changeValuesForm}
          typeInput="email"
          titleInput="Email"
          nameInput="email"
        />
      </div>
      <p
        className="container-form-personal__message-request"
        style={{ color: `${messageRequest.id === 1 ? "green" : "red"}` }}
      >
        {messageRequest && (
          <>
            {messageRequest.msg}
            <img
              src={messageRequest.id === 1 ? iconSucess : iconError}
              alt="icone de feedback da request"
            />
          </>
        )}
      </p>
      <div className="container-form__personal-btns">
        <ButtonBgProfile disabled={disabledItem} onclick={updateUser} />
        <ButtonNoBg disabled={disabledItem} onclick={() => requestCode()} />
      </div>
    </div>
  );
}
