import React, { Component } from "react";
import ButtonComponent from "../../../shared/button/components/ButtonComponent";
import "../../styles/MessagesView/MessagesFilterComponent.scss";
import { withTranslation } from "react-i18next";

class MessagesFilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterActive: "unread-messages",
    };
  }
  handleShowAllMessagesClick() {
    this.props.onFilter("show-all-messages");
    this.setState({
      filterActive: "show-all-messages",
    });
  }

  handleUnreadMessagesClick() {
    this.props.onFilter("unread-messages");
    this.setState({
      filterActive: "unread-messages",
    });
  }

  handleFlaggedMessagesClick() {
    this.props.onFilter("flagged-messages");
    this.setState({
      filterActive: "flagged-messages",
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div id="messages-filter">
        <div id="buttons">
          <span className="title">{t("Messages", "Messages")}</span>
          <ButtonComponent
            action={() => this.handleShowAllMessagesClick()}
            className={
              this.state.filterActive === "show-all-messages"
                ? "button--active"
                : ""
            }
          >
            {t("SHOW ALL MESSAGES", "SHOW ALL MESSAGES")}
          </ButtonComponent>
          <ButtonComponent
            action={() => this.handleUnreadMessagesClick()}
            className={
              this.state.filterActive === "unread-messages"
                ? "button--active"
                : ""
            }
          >
            {t("UNREAD MESSAGES", "UNREAD MESSAGES")}
          </ButtonComponent>
          <ButtonComponent
            action={() => this.handleFlaggedMessagesClick()}
            className={
              this.state.filterActive === "flagged-messages"
                ? "button--active"
                : ""
            }
          >
            {t("FLAGGED MESSAGES", "FLAGGED MESSAGES")}
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MessagesFilterComponent);
