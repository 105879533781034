import React, { Component } from "react";
import "./style/HighlightD.scss";
import { withTranslation } from "react-i18next";
import Buttons from "../buttons/Buttons";

class HighlightD extends Component {
  render() {
    return (
      <div>
        <section id="SecondHeader" className={`${this.props.id} template-d`}>
          <div className="texture2" style={{
              '--image': `url(${this.props.data.image})`,
              '--image-mobile': `url(${this.props.data.image_mobile || this.props.data.image})`,
          }} />
          <div className="logoAndText">
            <div>
              {this.props.data.logo ? (
                <img src={this.props.data.logo} alt="" />
              ) : (
                <div style={{ minHeight: "220px" }} />
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.data.text
                    ? this.props.data.text
                    : "&nbsp;",
                }}
              />
              <Buttons
                buttons={this.props.data.buttons}
                openRegister={this.props.openRegister}
                open={this.props.open}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(HighlightD);
