import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../styles/LocationsComponent.scss";

class LocationsComponent extends Component {
  renderLocations() {
    const { t } = this.props;
    if (this.props.locations.length <= 1) {
      return this.props.locations[0];
    }

    let locations = this.props.locations.join(", ");
    const lastIndexOfComma = locations.lastIndexOf(",");
    locations =
      locations.substring(0, lastIndexOfComma) +
      " " +
      t("and", "and") +
      " " +
      locations.substring(lastIndexOfComma + 1);
    return locations;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="LocationsComponent">
        <div className="title">{t("LOCATION", "LOCATION")}</div>
        <div className="locations-wrapper">{this.renderLocations()}</div>
      </div>
    );
  }
}

export default withTranslation()(LocationsComponent);
