import { save } from "./retailersActions";
import store from "../../store";
import RetailerEntity from "../../../domain/pages/retailers/RetailersEntity";

export default class RetailersService {
  headers = {
    Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    "X-Account-Hash": localStorage.getItem("xAccountHash"),
  };

  dispatch = store.dispatch;

  async getData() {
    const INIT = { headers: this.headers };
    try {
      let result = await fetch(
        `${process.env.REACT_APP_NEW_API_URL}/v1/buyers/landing`,
        INIT
      );
      let response = await result.json();
      let data = [];
      data.push(...response.featured);
      data.push(...response.random);

      this.entityMount(data);

      if (response.detail === "Authentication credentials were not provided.") {
        localStorage.clear();
      }
    } catch (err) {
      localStorage.clear();
      window.console.log(`[Erro] ${err}`);
      return Promise.reject(400);
    }
  }

  entityMount(response) {
    let entityRetailers = [];

    response.forEach((item) => {
      entityRetailers.push(new RetailerEntity(item));
    });

    this.dispatchSave(entityRetailers);
  }

  dispatchSave(entityList) {
    this.dispatch(save(entityList));
  }
}
