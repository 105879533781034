const SAVE = 'SAVE_LANDING_BRANDS';

export function landingBrandsReducer (state = [], action) {
    switch (action.type) {
        case SAVE:
            return action.payload;
        default:
            return state;
    }
}
