import React from 'react';
import RegisterBrandViewComponent from '../../../views/components/RegisterBrand/RegisterBrandViewComponent';

const RegisterBrandComponent = ({seller}) => (
    <div>
        <RegisterBrandViewComponent seller={seller} />
    </div>
);

export default RegisterBrandComponent;
