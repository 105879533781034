import React, { Component } from "react";
import "./UpdateAddressModal.scss";

import axios from "axios";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import LightboxHalfScreenComponent from "../../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import OrderService from "../../../../data/pages/order/OrderService";

class UpdateAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      loadingAddress: true,
      billingAddress: {},
      shippingAddress: {},
    };
  }

  componentDidMount() {
    const response = OrderService.getDefaultAddress()
      .then((response) => response.data.data)
      .then((data) => {
        console.log(data);
        this.setState({
          loadingAddress: false,
          billingAddress: data.billing,
          shippingAddress: data.shipping || {},
        });
      });
  }

  previousStep() {
    this.setState({
      currentStep: 1,
    });
  }

  nextStep() {
    if (this.state.currentStep === 1) {
      this.setState({
        loadingAddress: true,
      });
      OrderService.updateBillingAddress(
        this.props.orderId,
        this.state.billingAddress
      )
        .then(() => {
          this.setState(
            {
              currentStep: 2,
            },
            () => {
              document.querySelector(".lh-modal-body").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          );
        })
        .finally(() => {
          this.setState({
            loadingAddress: false,
          });
        });
    } else {
      this.setState({
        loadingAddress: true,
      });
      OrderService.updateShippingAddress(
        this.props.orderId,
        this.state.shippingAddress
      )
        .then(() => {
          this.props.close();
        })
        .finally(() => {
          this.setState({
            loadingAddress: false,
          });
        });
    }
  }

  handleCopyBillingAddress() {
    this.setState({
      shippingAddress: { ...this.state.billingAddress },
    });
  }

  handleChangeBillingAddress(event) {
    this.setState({
      billingAddress: {
        ...this.state.billingAddress,
        [event.target.name]: event.target.value,
      },
    });
  }

  handleChangeShippingAddress(event) {
    this.setState({
      shippingAddress: {
        ...this.state.shippingAddress,
        [event.target.name]: event.target.value,
      },
    });
  }

  render() {
    return (
      <LightboxHalfScreenComponent
        extraClass="update-address-modal-lh-modal"
        close={this.props.close}
      >
        <div id="UpdateAddressModal">
          {this.state.loadingAddress ? (
            <LoadingComponent />
          ) : (
            <>
              {this.state.currentStep === 1 ? (
                <div className="step-1">
                  <div className="text-container">
                    <h4>BILLING</h4>
                    <div>
                      <label>Billing to:</label>
                      <input
                        name="to"
                        value={this.state.billingAddress.to}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Address:</label>
                      <input
                        name="address"
                        value={this.state.billingAddress.address}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Zip Code:</label>
                      <input
                        name="zip_code"
                        value={this.state.billingAddress.zip_code}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>City:</label>
                      <input
                        name="city"
                        value={this.state.billingAddress.city}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>State:</label>
                      <input
                        name="state"
                        value={this.state.billingAddress.state}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Country:</label>
                      <input
                        name="country"
                        value={this.state.billingAddress.country}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Contact:</label>
                      <input
                        name="contact"
                        value={this.state.billingAddress.contact}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Phone Number:</label>
                      <input
                        name="phone_number"
                        value={this.state.billingAddress.phone_number}
                        onChange={this.handleChangeBillingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="step-1">
                  <div className="text-container">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'flex-end' }}>
                      <h4>SHIPPING</h4>
                      <div
                        onClick={this.handleCopyBillingAddress.bind(this)}
                        className="copy-from-billing"
                      >
                        Copy from Billing
                      </div>
                    </div>
                    <div>
                      <label>Shipping to:</label>
                      <input
                        name="to"
                        value={this.state.shippingAddress.to}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Address:</label>
                      <input
                        name="address"
                        value={this.state.shippingAddress.address}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Zip Code:</label>
                      <input
                        name="zip_code"
                        value={this.state.shippingAddress.zip_code}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>City:</label>
                      <input
                        name="city"
                        value={this.state.shippingAddress.city}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>State:</label>
                      <input
                        name="state"
                        value={this.state.shippingAddress.state}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Country:</label>
                      <input
                        name="country"
                        value={this.state.shippingAddress.country}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Contact:</label>
                      <input
                        name="contact"
                        value={this.state.shippingAddress.contact}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                    <div>
                      <label>Phone Number:</label>
                      <input
                        name="phone_number"
                        value={this.state.shippingAddress.phone_number}
                        onChange={this.handleChangeShippingAddress.bind(this)}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          <div
            className="modal-footer ng-scope"
            style={{ display: "flex", justifyContent: "center", gap: '2rem', marginTop: '2rem' }}
          >
            {!this.state.loadingAddress ? (
              <>
                {this.state.currentStep === 2 ? (
                  <button
                    onClick={this.previousStep.bind(this)}
                    type="button"
                    className="btn btn-warning"
                  >
                    Previous
                  </button>
                ) : null}
                {!this.state.loadingAddress ? (
                  <button
                    onClick={this.nextStep.bind(this)}
                    type="button"
                    className="btn btn-warning"
                  >
                    {this.state.currentStep === 2 ? "Finalize" : "Next"}
                  </button>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </LightboxHalfScreenComponent>
    );
  }
}

export default UpdateAddressModal;
