import React, {Fragment} from 'react';
import "../style/ConnectedCollaboration.scss";
import NavbarComponent from "../../landing/shared/navbar/components/NavbarComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import TeamImage from "../images/ourstory-team-2.jpg";
import NotebookImage from "../images/laptop.png";

const ConnectedCollaboration = () => {
    return (
        <Fragment>
            <NavbarComponent />
            <div className="subContainer">
                <div className="mainBox">
                    <span className="boxTitle">Connected Collaboration</span>
                    <span className="boxText">We want to change the way we do business <br/> in the fashion industry</span>
                </div>

                <section className="textContainer"> 
                    <p>
                        Considering all the rational attributes of the digital environment - capillarity, 24x7 customer service, Business Intelligence, among others - what makes us different from traditional companies in the industry is that we want to use technology for the sake of relationships avoiding manually and boring stuff that technology can address.
                    </p>

                    <p>
                        Not only for the lasting relationships we wish to establish between retailers and authentic Brazilian brands, but also for the sake of our daily evolution.
                    </p>

                    <p>
                        How was this put into practice? Since we know that we do not know everything (and we will never know!), we chose to launch our platform in a BETA version, making the rational decision to come into the world smaller than we actually can be.
                    </p>

                    <img className="teamImage" src={TeamImage} alt="Team" />

                    <p>
                        We are small when it comes to the number of brands we've selected - which, nevertheless, exemply our careful choice in showcasing the best of Brazil's industry - and when it comes to our number of participating retailers. Smaller numbers means we can create an active group that dives into our business model and that helps us evolve and grow, hand in hand.
                    </p>

                    <img className="notebookImage" src={NotebookImage} alt="Notebook" />

                    <p>
                        And throughout these 6 months of BETA phase, we will learn from the constant exchange of ideas and feedbacks, thus allowing us to adjust our platform, automating more and more processes, always in search for the best results.
                    </p>
                    <p>
                        <span className="emphasized">Always led by technology, uniting cultures and creating relationships.</span>
                    </p>
                    <p>
                        We will do this without losing our essence to keep things simple, to have attention to detail, to be transparent. To not to make the digital medium something cold or mechanized, but rather to maintain the joy and enthusiasm of creating and preserving the best in human relations.
                    </p>
                    <p>
                        Nice to meet you, we are <span className="emphasized">BLANC</span>!
                    </p>
                </section>
                
            </div>
            <FooterComponent />
        </Fragment>
    );
};

export default ConnectedCollaboration;
