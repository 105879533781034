import React, { Component } from "react";
import TermsAndPrivacyPolicyComponent from "../../termsandprivacypolicy/components/TermsAndPrivacyPolicyComponent";
import MenuFooterComponent from "../../menufooter/components/MenuFooterComponent";
import "../style/FooterStyle.scss";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

import RegisterModalComponent from "../../registermodal/components/RegisterModalComponent";

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      showRegister: false,
    };

    this.closeRegister = this.closeRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    
    this.firstMenu = [
      {
        title: t("Our Platform", "Our Platform"),
        isAnchor: true,
        link: "/#ourplatform",
      },
      {
        title: t("Our Brands", "Our Brands"),
        isAnchor: false,
        link: "/brands",
      },
      {
        title: t("Our Retailers", "Our Retailers"),
        isAnchor: true,
        link: "/#ourretailers",
      },
      {
        title: t("News and Views", "News and Views"),
        isAnchor: false,
        isOpen: true,
        link: "https://news.blancfashion.com/",
      },
      {
        title: t("About BLANC*", "About BLANC*"),
        isAction: window.location.pathname === "/whoweare",
        action: "#",
        link: "/whoweare",
      },
      {
        title: t("Register", "Register"),
        link: "/#register",
        isOpen: true,
        onClick: (e) => {
          e.preventDefault();
          return this.openRegister();
        },
      },
    ];
    this.secondMenu = [
      {
        title: t("Get in touch", "Get in touch"),
        email: "hello@blancfashion.com",
        isEmail: true,
      },
      {
        title: "Facebook",
        blank: true,
        link: "https://www.facebook.com/blancfashionbr/",
      },
      {
        title: "Instagram",
        blank: true,
        link: "https://www.instagram.com/blancfashionglobal/",
      },
      {
        title: "Linkedin",
        blank: true,
        link: "https://www.linkedin.com/company/blanc-fashion/",
      },
    ];
    this.lastMenu = [
      { title: t("Who We Are", "Who We Are"), link: "/whoweare" },
      { title: t("Our Team", "Our Team"), link: "/whoweare#ourteam" },
    ];

    this.state = {
      TermsAndConditions: "",
      privacyPolicy: "",
    };
  }

  openRegister(companyType = "") {
    this.setState({
      registerCompanyType: companyType,
      showRegister: true,
    });
  }

  closeRegister() {
    this.setState({
      showRegister: false,
    });
  }

  render() {
    const { t } = this.props;
    const accountTitle = localStorage.getItem("xAccountTitle");
    const accountName = localStorage.getItem("xAccountName");
    const xAccountPoweredByBlanc = localStorage.getItem("xAccountPoweredByBlanc");


    return (
      <footer>
        <div className="centerComponent contentFooter">
          {(this.props.hideInfos || window.localStorage.getItem("user")) && (
            <TermsAndPrivacyPolicyComponent />
          )}
          {(accountTitle === 'Blanc' || accountTitle === 'BLANC* | Fashion and Beauty wholesale products for your store') && (
            <div className="infos">
            {!this.props.hideInfos && !window.localStorage.getItem("user") && (
              <>
                <div className="menuFooter">
                  <MenuFooterComponent
                    header={t("COMPANY", "COMPANY")}
                    navItens={this.firstMenu}
                  />
                  <MenuFooterComponent
                    header={t("CONNECT", "CONNECT")}
                    navItens={this.secondMenu}
                  />
                  <div id="MenuFooterComponent"></div>
                </div>

                <div className="logo">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/BLANC_Symbol_WHITE_RGB.svg`}
                    alt="logo"
                  />
                </div>
              </>
            )}
          </div>
          )}
          {!this.props.hideInfos && !window.localStorage.getItem("user") && (
            <TermsAndPrivacyPolicyComponent />
          )}
          <div className="copyright">
            {accountName !== '' && xAccountPoweredByBlanc !== '0' ? (
              `©2023 ${accountName} powered by BLANC* Fashion. ${t('All Rights Reserved', 'All Rights Reserved')}`
            ) : 
              t(
                "©2023 BLANC* Fashion. All Rights Reserved",
                "©2023 BLANC* Fashion. All Rights Reserved"
              )
            }
          </div>
        </div>

        {this.state.showRegister && (
          <RegisterModalComponent
            companyType={this.state.registerCompanyType}
            close={this.closeRegister}
          />
        )}
      </footer>
    );
  }
}

export default withTranslation()(withRouter(FooterComponent));
