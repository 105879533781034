import { isMobile } from "is-mobile";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";
import DOMPurify from 'dompurify';
import OrderService from "../../../services/OrdersService";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import SpinnerComponent from "../../shared/spinner";
import { ToastContainer, toast } from 'react-toastify'
import { format, parseISO } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css'

class OrderSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      sendingMessage: false,
      orderService: new OrderService(),
      order: props.order,
      initialStatus: props.order.status,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order.id !== this.props.order.id) {
      this.setState({
        order: this.props.order,
      });
    }
  }

  onChangeMessage(event) {
    this.setState({ message: event.target.value });
  }

  sendMessage() {
    if (this.state.message) {
      this.setState({ sendingMessage: true });
      let order = this.props.order;
      this.state.orderService
        .sendMessage(order.id, this.state.message)
        .then((resp) => {
          order.messages.push({
            message: this.state.message,
            created: this.formatDate(new Date()),
          });
          this.setState({ order, message: "", sendingMessage: false });
        });
    }
  }

  formatDate(date) {
    const { i18n } = this.props;
    return i18n.language === "en"
      ? moment(date).format("MM/DD/YYYY")
      : moment(date).format("DD/MM/YYYY");
  }

  changeOrderStatus(status) {
    const userSType = localStorage.getItem("user_stype");

    if (userSType === "seller") {
      this.setState({
        order: { ...this.state.order, status: status },
      });
    }
  }

  normalizeAndFormatClassName(text) {
    if (text === null) {
      return '';
    }
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, '-');
  }

  renderStatusElement(status) {
    const { order } = this.state;
    const { t } = this.props;
    const {order: {fulfilment_status, payment_status, external_checkout_url }} = this.state
    const classNameFullFilment = this.normalizeAndFormatClassName(fulfilment_status);
    const classNamePaymentStatus = this.normalizeAndFormatClassName(payment_status);
    switch (status) {
      case "Open":
        return (
          <div className="returnStatusOrder">
            <div className={`paymentStatusOrder ${classNamePaymentStatus}`}>
              <span>{order.payment_status}</span>
            </div>
            <div className={`fullfimentStatusOrder ${classNameFullFilment}`}>
              <span>{order.fulfilment_status}</span>
            </div>
            {
              ["Pending", "Payment Pending", "Overdue", "Partially paid"].includes(payment_status) && external_checkout_url &&
              <div className="buttonPayNow">
                <a
                target="_blank"
                href={external_checkout_url}
                >
                  {t("PAY NOW", "PAY NOW")}
                </a>
              </div>
            }
          </div>
        );
      case "Closed":
        return (
          <div className="statusDefault orderClosed">
            <span>{status}</span>
          </div>
        );
      case "Cancelled":
        return (
          <div className="statusDefault orderCancelled">
            <span>{status}</span>
          </div>
        );
      default:
        return null;
    }
  }

  updateOrderStatus(status) {
    const userSType = localStorage.getItem("user_stype");

    if (userSType === "seller") {
      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to update the order status?`,
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#71cfc9",
        cancelButtonColor: "#C0C0C0",
        confirmButtonText: "Update",
        cancelButtonText: "Cancel",
        focusCancel: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const orderService = new OrderService();

          orderService.updateStatus(this.state.order.id, status).then(() =>
            this.setState({
              order: { ...this.state.order, status },
              initialStatus: status,
            })
          );
        }
      });
    }
  }

  handleCopy(item) {
    const container = document.createElement('div');
    container.innerHTML = item

    const button = container.querySelector('button[data-url]');
    const url = button ? button.getAttribute('data-url') : null;

    if (!url) return;

    navigator.clipboard.writeText(url).then(() => {
      toast.success(this.props.t("Link copied to clipboard", "Link copied to clipboard"))
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  render() {
    const userSType = localStorage.getItem("user_stype");
    const { order:{ status, history }} = this.state;
    const jsxToRender = this.renderStatusElement(status);
    const { order } = this.state;
    const { t } = this.props;
    return (
      <div className="order-details" id="order-details">
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <React.Fragment>
          <div className="header">
            <h1>{order.seller.name}</h1>
          </div>
          <div className="order-resume">
            <div className="header-resume" id="header-resume">
              <div className="header-status-new">
                <div>
                  <h4>
                    {t("Hello", "Hello")}{" "}
                    <span className="buyer-name">{order.buyer.name}</span>!
                  </h4>
                  <h4>
                    {/* {t("this is your", "this is your")}{" "} */}
                    {/* <b>
                      {t("PURCHASE ORDER", "PURCHASE ORDER")} #{order.id}
                    </b> */}
                  </h4>
                  <div className="border" />
                </div>
                <div className="newHeader-status">
                  {jsxToRender}
                  <div className="idOrderNumber">
                    <span>
                      #{order.id}
                    </span>
                  </div>
                </div>
              </div>
              <div className="order-values">
                <div>
                  {order.tracking_code && (
                    <div>
                      <b>{t("Tracking code", "Tracking code")}</b> |{" "}
                      {order.tracking_code}
                    </div>
                  )}
                  {order.shipping && (
                    <div>
                      <b>{t("Shipping", "Shipping")}</b> | {order.shipping}
                      {order.showShipping && (
                        <span ng-show="order.showShipping">
                          <b>
                            {t("Rate", "Rate")}: {order.currency}{" "}
                            {order.shipping.rate} - {order.shipping.time}{" "}
                            {t("days", "days")}
                          </b>
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {/* <h2>{t("PURCHASE ORDER", "PURCHASE ORDER")}</h2> */}
                <div className="total">
                  <div>
                    <b>{t("TOTAL", "TOTAL")}</b> |{" "}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: order.currency,
                    })
                      .format(order.total_price)
                      .replace("US$", "USD")}
                  </div>
                  <div>
                    <b>{t("UNITS", "UNITS")}</b> | {order.total_items}
                  </div>
                </div>
              </div>
              <hr />
              {/* <div className="order-status">
                <div
                  className={`status-container ${
                    userSType === "seller" ? "status-container--seller" : ""
                  }`}
                  onMouseOver={() => this.changeOrderStatus("s")}
                  onMouseOut={() =>
                    this.changeOrderStatus(this.state.initialStatus)
                  }
                  onClick={() => this.updateOrderStatus("s")}
                >
                  <div className="circle">
                    <div className="inner">
                      <span>01</span>
                    </div>
                  </div>
                  <div className="description">{t("Accepted", "Accepted")}</div>
                </div>
                <div className="order-separator"></div>
                <div
                  className={`status-container ${
                    userSType === "seller" ? "status-container--seller" : ""
                  }`}
                  onMouseOver={() => this.changeOrderStatus("p")}
                  onMouseOut={() =>
                    this.changeOrderStatus(this.state.initialStatus)
                  }
                  onClick={() => this.updateOrderStatus("p")}
                >
                  <div
                    className={`circle ${
                      ["b", "r", "s"].indexOf(order.status) !== -1
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <div
                      className={`inner ${
                        ["b", "r", "s"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <span
                        className={`${
                          ["b", "r", "s"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        02
                      </span>
                    </div>
                  </div>
                  <div className="description">
                    {t("Processing", "Processing")}
                  </div>
                </div>
                <div
                  className={`order-separator ${
                    ["b", "s", "r", "p"].indexOf(order.status) !== -1
                      ? "disabled"
                      : ""
                  }`}
                ></div>
                <div
                  className={`status-container ${
                    userSType === "seller" ? "status-container--seller" : ""
                  }`}
                  onMouseOver={() => this.changeOrderStatus("i")}
                  onMouseOut={() =>
                    this.changeOrderStatus(this.state.initialStatus)
                  }
                  onClick={() => this.updateOrderStatus("i")}
                >
                  <div
                    className={`circle ${
                      ["b", "s", "r", "p"].indexOf(order.status) !== -1
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <div
                      className={`inner ${
                        ["b", "s", "r", "p"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <span
                        className={`${
                          ["b", "s", "r", "p"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        03
                      </span>
                    </div>
                  </div>
                  <div className="description">
                    {t("In transit", "In transit")}
                  </div>
                </div>
                <div
                  className={`order-separator ${
                    ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                      ? "disabled"
                      : ""
                  }`}
                ></div>
                <div
                  className={`status-container ${
                    userSType === "seller" ? "status-container--seller" : ""
                  }`}
                  onMouseOver={() => this.changeOrderStatus("f")}
                  onMouseOut={() =>
                    this.changeOrderStatus(this.state.initialStatus)
                  }
                  onClick={() => this.updateOrderStatus("f")}
                >
                  <div
                    className={`circle ${
                      ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <div
                      className={`inner ${
                        ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <span
                        className={`${
                          ["b", "s", "r", "p", "i"].indexOf(order.status) !== -1
                            ? "disabled"
                            : ""
                        }`}
                      >
                        04
                      </span>
                    </div>
                  </div>
                  <div className="description">{t("Finished", "Finished")}</div>
                </div>
              </div> */}
              <div className="order-note-wrapper">
                <div className="order-note">
                  {t("ORDER NOTE", "ORDER NOTE")}
                </div>
                <p>{order.note}</p>
              </div>
              <div className="days-to-ship">
                {order.external_checkout_url ? (
                  <a
                    href={`${order.external_checkout_url}`}
                    className="button order-button"
                    target={"_blank"}
                  >
                    {t("VIEW INVOICE", "VIEW INVOICE")}
                  </a>
                ) : null}
                <a
                  href={`${process.env.REACT_APP_NEW_API_URL}/v1/orders/${order.id}/po-xlsx`}
                  className="button order-button"
                >
                  {t("DOWNLOAD PO (.XLS)", "DOWNLOAD PO (.XLS)")}
                </a>
                <a
                  href={`${process.env.REACT_APP_NEW_API_URL}/v1/orders/${order.id}/po-pdf`}
                  className="button order-button"
                >
                  {t("DOWNLOAD PO (.PDF)", "DOWNLOAD PO (.PDF)")}
                </a>
                <a
                  href={`${process.env.REACT_APP_NEW_API_URL}/v1/orders/${order.id}/order-products-shopify-csv`}
                  className="button order-button"
                >
                  {t("DOWNLOAD SHOPIFY FILE", "DOWNLOAD SHOPIFY FILE")}
                </a>
              </div>
              {order.payments.length > 0 &&
                <div className="order-payments-wrapper">
                  <div className="order-payments">
                    {t("PAYMENTS", "PAYMENTS")}
                  </div>
                  {order.payments.map((payment, index) => {
                    return (
                      <div className="single-order-payment" key={index}>
                        <div className={`paymentStatusOrder ${this.normalizeAndFormatClassName(payment.status)}`}>
                          <span>{payment.status}</span>
                        </div>
                        <p>
                          {`Payment #${payment.reference} of ${order.currency} ${payment.amount.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })} due at ${format(parseISO(payment.due_date), "MMM. dd, yyyy")} `}
                          <a href={payment.url} target="blank" className="button">Pay</a>
                        </p>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
            {!isMobile() && (
              <>
                <div className="messages-box">
                  <h3 className="title">
                    {t("MESSAGES WITH", "MESSAGES WITH")} {order.seller.name}
                  </h3>

                  {order.messages.map((message, index) => (
                    <div key={index} className="message">
                      <h3 className="buyer-name">
                        {message.sent_from
                          ? message.sent_from.seller
                            ? message.sent_from.seller.name
                            : message.sent_from.buyer.name
                          : order.buyer.name}
                      </h3>
                      <h4>{this.formatDate(message.created)}</h4>
                      <p>{message.message}</p>
                    </div>
                  ))}
                </div>
                <div className="message-control">
                  <input
                    onChange={(event) => this.onChangeMessage(event)}
                    value={this.state.message}
                    type="text"
                  />
                  {!this.state.sendingMessage ? (
                    <ButtonComponent action={() => this.sendMessage()}>
                      {t("SEND", "SEND")}
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent className="sending">
                      <SpinnerComponent />
                    </ButtonComponent>
                  )}
                </div>
                <div className="timelineOrder">
                  <div className="timeline-order-title">
                    <span>{t("Timeline", "Timeline")}</span>
                  </div>
                  <div className="list-timeline-order">
                    <ul>
                      {history.map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item) }} onClick={() => this.handleCopy(item)}></li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default withTranslation()(OrderSummary);
