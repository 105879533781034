export default class LandingBrandsInterector {

  constructor(service) {
    this.service = service
  }

  getData() {
    return this.service.getData();
  }
}
