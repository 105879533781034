import React from "react";
import InputProfile from "../../../../../components/InputProfile";

import Axios from "axios";
import ButtonBgProfile from "../../../../../components/buttonWithBgProfile/index.jsx";
import CardUsersManagement from "../../../../../components/cardUsersManagement/index.jsx";
import "./styles.scss";
import close from "../../../../../imgs/close.svg";
import { testForm } from "../../../../../utils/formValidate.js";
import Loading from "../../../../../components/Loading/index.jsx";

export default function FormManagement(props) {
  const [valuesForm, setValuesForm] = React.useState({
    name: "",
    email: "",
    domains: "",
  });
  const [messageRequest, setMessageRequest] = React.useState("");
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [domains, setDomains] = React.useState();
  const [domainLoading, setDomainLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState({
    name: false,
    email: false,
  });

  function changeValuesForm(inputName, newValue) {
    const values = { ...valuesForm };
    values[inputName] = newValue;

    setValuesForm(values);
  }

  async function updateUser() {
    var validate = [];

    if (!testForm("text1", valuesForm.name)) {
      setErrorState((state) => ({ ...state, name: true }));
      validate.push("name");
    } else {
      setErrorState((state) => ({ ...state, name: false }));
    }

    if (!testForm("email", valuesForm.email)) {
      setErrorState((state) => ({ ...state, email: true }));
      validate.push("email");
    } else {
      setErrorState((state) => ({ ...state, email: false }));
    }

    if (validate.length > 0) {
      return;
    }
    setDisabledItem(true);

    const data = {
      email: valuesForm.email,
      first_name: valuesForm.name,
    };

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/buyer/invite-user/`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    try {
      const response = await Axios(config);
      let storageData = JSON.parse(localStorage.getItem("data1"));
      let storageUsers = JSON.parse(localStorage.getItem("data1")).data.buyer
        .users;
      storageUsers.push(response.data.data);
      storageData.data.buyer.users = storageUsers;
      localStorage.setItem("data1", JSON.stringify(storageData));

      setMessageRequest({ msg: response.data.message, id: 1 });

      setValuesForm((state) => ({ ...state, name: "", email: "" }));
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    } finally {
      setInterval(() => {
        setMessageRequest("");
      }, 6200);
      setDisabledItem(false);
    }
  }

  async function sendDomain(e) {
    const target = e.key;

    if (target === "Enter") {
      if (e.target.value !== "") {
        const arr = [];
        arr.push(...domains);
        arr.push(e.target.value);
        e.target.value = "";
        var data = {
          domains: arr,
        };

        var config = {
          method: "put",
          url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/domains`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
          data: data,
        };

        try {
          setDomainLoading(true);
          const response = await Axios(config);
          setDomains(response.data.data);
        } catch (err) {
          setMessageRequest({ msg: err.response.data.message, id: 2 });
        } finally {
          setDomainLoading(false);
        }
      }
    }
  }

  async function removeDomain(value) {
    let arr = [];
    arr.push(...domains);

    const newArr = arr.filter((item) => item !== value.item);

    const data = {
      domains: newArr,
    };

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/domains`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    try {
      setDomainLoading(true);
      const response = await Axios(config);
      setDomains(response.data.data);
    } catch (err) {
      setMessageRequest({ msg: err.response.data.message, id: 2 });
    } finally {
      setDomainLoading(false);
    }
  }

  React.useEffect(() => {
    const loadDomains = async () => {
      try {
        const response = await Axios({
          method: "get",
          url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/domains`,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
            "Access-Control-Allow-Origin": "*",
          },
        });
        setDomains(response.data.data);
      } catch (err) {
        setMessageRequest({ msg: err.response.data.message, id: 2 });
      }
    };
    loadDomains();
  }, []);

  return (
    <div className="container-form-management">
      <h1 className="container-form-management__title">Users</h1>
      {JSON.parse(localStorage.getItem("data1")).data.buyer.users &&
        JSON.parse(localStorage.getItem("data1")).data.buyer.users.map((el) => {
          return (
            <CardUsersManagement
              key={`${el.first_name}${el.last_name}`}
              name={el.first_name}
              email={el.email}
              status={el.status.charAt(0).toUpperCase() + el.status.slice(1)}
            />
          );
        })}

      <h2 className="container-form-management__title">Add new user</h2>
      <div className="container-form-management__box-inputs">
        <InputProfile
          onchange={changeValuesForm}
          typeValidation="text1"
          value={valuesForm.name}
          typeInput="text"
          titleInput="Name"
          nameInput="name"
          nameValue="name"
          error={errorState.name && "Name is required."}
        />
        <InputProfile
          onchange={changeValuesForm}
          typeValidation="email"
          value={valuesForm.email}
          typeInput="text"
          titleInput="E-mail"
          nameInput="email"
          nameValue="email"
          error={
            errorState.email
              ? "The email field is required."
              : messageRequest.msg && messageRequest.msg
          }
        />
      </div>
      <div className="container-form__personal-btns">
        <ButtonBgProfile
          disabled={disabledItem}
          onclick={updateUser}
          text="SEND INVITE"
        />
      </div>

      {/* <h2 className="container-form-management__title">
        Auto-approved domains
      </h2>
      <p className="container-form-management__subtitle">
        If a new user registers with this domain it will be automatically linked
        to your company
      </p>
      <p className="container-form-management__subsubtitle">
        Store locations (country)
      </p>
      <section className="container-form-management__domains">
        <div>
          {domains &&
            domains.map((item) => (
              <p>
                {item}
                <button onClick={() => removeDomain({ item })}>
                  <img src={close} />
                </button>
              </p>
            ))}
        </div>
        {domainLoading ? (
          <Loading borderColor={"black"} borderTopColor={"white"} />
        ) : (
          <input type="text" onKeyDown={(e) => sendDomain(e)} />
        )}
      </section> */}
      {/* <SelectComponent 
                wd="100%" 
                title="Store locations (country)" 
                valueType='domains' 
                values={props[1]} 
                multiple 
                changeValue={changeValuesForm}
            /> */}
      {/* {messageRequest.msg && (
        <p className="container-form-management__validation">
          {messageRequest.msg}
        </p>
      )} */}
    </div>
  );
}
