import React from "react";
import InputProfile from "../../../../../components/InputProfile";
import iconDelete from "../../../../../imgs/delete.svg";
import iconEdit from "../../../../../imgs/edit.svg";
import iconError from "../../../../../imgs/icon-error.svg";
import iconSucess from "../../../../../imgs/icon-success.svg";

import Axios from "axios";
import ButtonBgProfile from "../../../../../components/buttonWithBgProfile/index.jsx";
import SelectComponent from "../../../../../components/selectGeneral/index.jsx";
import { testForm } from "../../../../../utils/formValidate.js";
import "./styles.scss";

export default function FormCompany(props) {
  const [changeItem, setChange] = React.useState(0);
  const [valuesForm, setValuesForm] = React.useState({
    name: (props.data[0].buyer && props.data[0].buyer.name) || "",
    facebook: props.data[0].buyer && props.data[0].buyer.facebook,
    instagram: props.data[0].buyer && props.data[0].buyer.instagram,
    website: props.data[0].buyer && props.data[0].buyer.website,
    text: (props.data[0].buyer && props.data[0].buyer.text) || "",
    locations:
      (props.data[0].buyer &&
        props.data[0].buyer_locations.map((el) => el.id)) ||
      [],
    logo: (props.data[0].buyer && props.data[0].buyer.logo) || "",
    year_established:
      (props.data[0].buyer && props.data[0].buyer.year_established) || "",
    delete_logo: 0,
  });
  const [messageRequest, setMessageRequest] = React.useState("");
  const [disabledItem, setDisabledItem] = React.useState(false);
  const [errorState, setErrorState] = React.useState({
    name: false,
    facebook: false,
    instagram: false,
    website: false,
    text: false,
    locations: false,
    year: false,
  });

  function changeValuesForm(inputName, newValue) {
    // Year established aceita apenas NUM
    if (inputName === "year_established" && isNaN(newValue)) {
      return;
    }

    const values = { ...valuesForm };
    values[inputName] = newValue;
    setValuesForm(values);
  }

  async function sendData() {
    var validate = [];

    // Validação text
    if (valuesForm.text !== "" && !testForm("text2", valuesForm.text)) {
      setErrorState((state) => ({ ...state, text: true }));
      validate.push("text");
    } else {
      setErrorState((state) => ({ ...state, text: false }));
    }

    // Validação website
    if (valuesForm.website !== "" && !testForm("url", valuesForm.website)) {
      setErrorState((state) => ({ ...state, website: true }));
      validate.push("website");
    } else {
      setErrorState((state) => ({ ...state, website: false }));
    }

    // Validação instagram
    if (
      valuesForm.instagram !== "" &&
      !testForm("instagram", valuesForm.instagram)
    ) {
      setErrorState((state) => ({ ...state, instagram: true }));
      validate.push("instagram");
    } else {
      setErrorState((state) => ({ ...state, instagram: false }));
    }

    // Validação facebook
    if (
      valuesForm.facebook !== "" &&
      !testForm("facebook", valuesForm.facebook)
    ) {
      setErrorState((state) => ({ ...state, facebook: true }));
      validate.push("facebook");
    } else {
      setErrorState((state) => ({ ...state, facebook: false }));
    }

    // Validação name
    if (!testForm("text1", valuesForm.name)) {
      setErrorState((state) => ({ ...state, name: true }));
      validate.push("name");
    } else {
      setErrorState((state) => ({ ...state, name: false }));
    }

    // Validação campo de Ano
    if (
      valuesForm.year_established !== "" &&
      testForm("year", +valuesForm.year_established)
    ) {
      setErrorState((state) => ({ ...state, year: true }));
      validate.push("year");
    } else {
      setErrorState((state) => ({ ...state, year: false }));
    }

    if (
      valuesForm.locations[0].length !== undefined &&
      valuesForm.locations[0].length === 0
    ) {
      setErrorState((state) => ({ ...state, locations: true }));
      validate.push("locations");
    } else {
      setErrorState((state) => ({ ...state, locations: false }));
    }

    if (validate.length > 0) {
      return;
    }

    let data = new FormData();

    data.append("_method", "PUT");

    for (let value in valuesForm) {
      if (
        value !== "logo" &&
        value !== "locations" &&
        value !== "website" &&
        value !== "facebook"
      ) {
        data.append(value, valuesForm[value]);
      }

      if (value === "locations") {
        data.append(`${value}[]`, valuesForm[value]);
      }
    }

    if (!valuesForm.facebook.startsWith("https://")) {
      valuesForm.facebook
        ? data.append("facebook", `https://${valuesForm.facebook}`)
        : data.delete("facebook");
    } else {
      data.append("facebook", valuesForm.facebook);
    }

    if (!valuesForm.website.startsWith("https://")) {
      valuesForm.website
        ? data.append("website", `https://${valuesForm.website}`)
        : data.delete("website");
    } else {
      data.append("website", valuesForm.website);
    }

    if (valuesForm.delete_logo === 0) {
      // Se a imagem já existir
      typeof valuesForm.logo == "string"
        ? data.delete("logo")
        : data.append("logo", valuesForm.logo);

      data.delete("delete_logo");
    } else {
      data.delete("logo");
      data.append("delete_logo", valuesForm.delete_logo);
    }

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/buyer/`,
      headers: {
        Accept: "application/json",
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };

    try {
      let response = await Axios(config);
      setValuesForm((state) => ({
        ...state,
        logo: response.data.data.logo,
        website: response.data.data.website,
        facebook: response.data.data.facebook,
      }));

      setMessageRequest({ msg: "Success", id: 1 });
    } catch (error) {
      setMessageRequest({ msg: error.response.data.message, id: 2 });
    } finally {
      setTimeout(() => {
        setMessageRequest("");
      }, 6200);
      setDisabledItem(false);
    }
  }

  function handleImage(e) {
    const file = e.target.files[0];
    setValuesForm((state) => ({ ...state, logo: file, delete_logo: 0 }));
  }

  function clickFile() {
    const $element = document.getElementById("picture-input");
    $element.click();
  }

  function deleteLogo() {
    setValuesForm((state) => ({ ...state, logo: "", delete_logo: 1 }));
  }

  return (
    <div className="container-form-company">
      <div className="container-form-company__box-inputs">
        <div className="box-inputs__box-img-name">
          {!!valuesForm.logo ? (
            <>
              <label htmlFor="picture-input">
                <button type="button" onClick={() => clickFile()}>
                  <img src={iconEdit} alt="" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    deleteLogo();
                  }}
                >
                  <img src={iconDelete} alt="" />
                </button>
                <div className="box-inputs__box-img-name__img">
                  <img
                    src={
                      typeof valuesForm.logo == "string"
                        ? valuesForm.logo
                        : URL.createObjectURL(valuesForm.logo)
                    }
                    alt="logo da empresa"
                  />
                </div>
                <input
                  onChange={(e) => handleImage(e)}
                  type="file"
                  accept="image/*"
                  name="picture-input"
                  id="picture-input"
                />
              </label>
            </>
          ) : (
            <label
              htmlFor="picture-input"
              className="box-inputs__box-img-name__send-image"
            >
              <span>Upload logo</span>
              <span>Optimal size: 346x219px</span>
              <input
                onChange={(e) => handleImage(e)}
                type="file"
                accept="image/*"
                name="picture-input"
                id="picture-input"
              />
            </label>
          )}
          <InputProfile
            value={valuesForm.name}
            wd="100%"
            onchange={changeValuesForm}
            nameValue="name"
            typeValidation="text1"
            typeInput="text"
            titleInput="Company name"
            nameInput="companyName"
            isRequired
            error={
              errorState.name && "Name must have to be 3 character at least."
            }
          />
        </div>
        <InputProfile
          onchange={changeValuesForm}
          nameValue="year_established"
          typeValidation="text3"
          value={valuesForm.year_established || ""}
          typeInput="text"
          titleInput="Year established"
          nameInput="yearEstablished"
          error={errorState.year && "Year must be valid."}
        />
        <InputProfile
          onchange={changeValuesForm}
          nameValue="website"
          typeValidation="url"
          value={valuesForm.website}
          typeInput="text"
          titleInput="Company website"
          nameInput="companyWebsite"
          error={errorState.website && "The website must be a valid URL."}
        />
        <InputProfile
          onchange={changeValuesForm}
          nameValue="instagram"
          typeValidation="instagram"
          value={valuesForm.instagram}
          typeInput="text"
          titleInput="Instagram"
          nameInput="instagram"
          error={errorState.instagram && "The instagram must be a valid URL."}
        />
        <InputProfile
          onchange={changeValuesForm}
          nameValue="facebook"
          typeValidation="facebook"
          value={valuesForm.facebook}
          typeInput="text"
          titleInput="Facebook"
          nameInput="facebook"
          error={errorState.facebook && "The facebook must be a valid URL."}
        />

        <div className="container-form-company__box-textarea">
          <label htmlFor="aboutCompany">About the company</label>
          <textarea
            onChange={(e) => {
              changeValuesForm("text", e.target.value);
              setChange(1);
            }}
            name="aboutCompany"
            value={valuesForm.text}
            className="container-form-company__box-textarea"
          ></textarea>

          <p className="box-input-profile__validation">
            {errorState.text && "Text must have to be 8 characters at least."}
          </p>
        </div>

        <div className="container-form-company__countries">
          <SelectComponent
            title="Store locations (country)"
            values={props.data[1]}
            valueType="locations"
            value={valuesForm.locations}
            changeValue={changeValuesForm}
            multiple
          />
          {errorState.locations && (
            <p className="box-input-location_validation">
              You must have to select 1 country at least.
            </p>
          )}
        </div>
      </div>
      <p
        className="container-form-company__message-request"
        style={{ color: `${messageRequest.id === 1 ? "green" : "red"}` }}
      >
        {messageRequest && (
          <>
            {messageRequest.msg}
            <img
              src={messageRequest.id === 1 ? iconSucess : iconError}
              alt="icone de feedback da request"
            />
          </>
        )}
      </p>
      <div className="container-form__personal-btns">
        <ButtonBgProfile disabled={disabledItem} onclick={sendData} />
      </div>
    </div>
  );
}
