import React, {Component} from 'react';
import "../style/AddToCartStyle.scss"

class AddToCartComponents extends Component {

    render() {

        const {children, disabled} = this.props;

        return (
            <button type='button' disabled={disabled} className='addToCart'>
                {children}
            </button>
        );
    }
}

export default AddToCartComponents;