import React, {Component} from 'react';
import "../style/LightBoxFullScreen.scss"

class LightBoxFullScreenComponent extends Component {
    render() {
        const {children, handleClick, title, subTitle, isVideo, extraClass} = this.props;
        return (
            <div className="lightBoxFullScreenWrapper">
                <div className={"lightBoxFullScreen " + (isVideo ? 'videoLightBox' : '') + (extraClass ? extraClass : '')}>
                    <div className="bgLightBox" onClick={() => handleClick()} />
                    <div className="containerLightBox">
                        <div className="topLightBox">
                            <div className="closed" onClick={() => handleClick()}>
                                X
                            </div>

                            <p className="titleBox">
                                { title }

                                {subTitle &&
                                    <span>{subTitle}</span>
                                }
                            </p>
                        </div>

                        <div className="blockLightBox">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LightBoxFullScreenComponent;
