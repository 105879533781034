import React, { Component } from "react";
import MenuComponent from "../../../menu/components/MenuComponent";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import LoadingComponent from "../../loading/components/LoadingComponent";
import "../style/CreateMyOwnListStyle.scss";
import ProductComponent from "../../products/components/ProductComponent";
import FeedBackMsgComponents from "../../feedbackmsg/components/FeedBackMsgComponents";
import JumboTitleComponent from "../../jumbotitle/components/JumboTitleComponent";
import store from "../../../../data/store";
import { save } from "../../../../data/pages/products/showNotificationItemAddedActions";
import { isMobile } from "is-mobile";
import ButtonComponent from "../../button/components/ButtonComponent";
import SpinnerComponent from "../../spinner";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

class CreateMyOwnListComponent extends Component {
  constructor(props) {
    super(props);
    this.filter = {};
    this.state = {
      products: [],
      loading: true,
      productList: [],
      selectedAll: false,
      loadingAddCart: false,
    };
    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
  }

  headers = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  list = [];
  filters = ''

  urlSearchParams = new URLSearchParams(this.props.history.location.search);

  url = `${process.env.REACT_APP_NEW_API_URL}/v1/products?collections=${this.props.match.params.id
    }&create_my_own_list=true&limit=500&return_type=variations&${this.urlSearchParams.toString()}`;

  getCreateMyOwnList() {
    axios.get(this.url, this.headers).then((response) => {
      this.setState({
        products: response.data,
        loading: false,
      });
    });
  }

  componentDidMount() {
    this.getCreateMyOwnList();
  }

  setFilters(api) {
    this.filters = api;
  }

  makeCreateMyOwnList(value, product) {
    this.setState({
      productList: product,
    });

    var unique = value.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })

    let api = "";
    unique.forEach((id) => {
      api += "variations[]=" + id + "&";
    });

    this.setFilters(api);
  }

  async addCart(quantity = 0) {
    if (this.state.loadingAddCart) {
      return;
    }

    this.setState({
      loadingAddCart: true,
    });
    let list = this.state.productList;
    if (list.length > 0) {
      const pricelistId = Number(localStorage.getItem("user.pricelist"));

      let data = {
        product_variation_id: list,
        pricelist_id: pricelistId,
      }

      if (quantity > 0) {
        data.quantity = quantity
      }

      axios
        .post(`${process.env.REACT_APP_NEW_API_URL}/v1/cart/add-product`, data)
        .then((response) => {
          this.setState({
            loadingAddCart: false,
            msg: true,
          });
          this.showFeedback();
        })
        .catch((ex) => {
          this.setState({
            loadingAddCart: false,
          });
          if (ex.response.status === 409) {
            this.setState({
              isProductInCart: true,
            });
            this.showFeedback();
          }
        });
    }
  }

  goLineSheet() {
    if (this.filters) {
      const pricelistId = Number(localStorage.getItem("user.pricelist"));
      window.open(
        `${process.env.REACT_APP_NEW_API_URL}/v1/products/create-my-own-list?${this.filters}pricelist_id=${pricelistId}`,
        "_blank"
      );
    }
  }
  showFeedback() {
    if (isMobile()) {
      this.setState({
        showFeedback: true,
      });
    } else if (this.state.isProductInCart) {
      store.dispatch(save("isProductInCart"));
    } else {
      store.dispatch(save(true));
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }
  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      msg: false,
      isProductInCart: false,
    });
    store.dispatch(save(false));
  }

  handleClickSelectAll() {
    const checkboxes = document.querySelectorAll("input[type=checkbox]");

    checkboxes.forEach((element) => {
      element.checked = this.state.selectedAll;
      element.click();
    });

    this.setState({
      selectedAll: !this.state.selectedAll,
    });
  }

  render() {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (this.state.loading) {
      return <LoadingComponent />;
    }

    const { t } = this.props;

    return (
      <div id="create-my-own-list-component">
        <MenuComponent />
        <JumboTitleComponent
          title={`${t(
            "Customized line sheet from",
            "Customized line sheet from"
          )} ${this.state.products.meta.sellers[0].name}`}
        >
          <div className="textLineSheet">
            <p>
              {t(
                "Customize your own line sheet selecting the products you liked",
                "Customize your own line sheet selecting the products you liked"
              )}{". "}
              {t(
                "Then, you can download as PDF file, by clicking on the button",
                "Then, you can download as PDF file, by clicking on the button"
              )}{" "}
              <span className="downloadText">&nbsp;</span>,{" "}
              {t(
                "or add these products straight to your cart, by clicking on the button",
                "or add these products straight to your cart, by clicking on the button"
              )}{" "}
              <span className="cartText">&nbsp;</span>.
            </p>
          </div>
        </JumboTitleComponent>
        {this.state.showFeedback && this.state.msg && (
          <FeedBackMsgComponents close={this.closeFeedback}>
            {t(
              "GREAT! THE ITEMS HAS BEEN ADDED TO YOUR CART. CLICK",
              "GREAT! THE ITEMS HAS BEEN ADDED TO YOUR CART. CLICK"
            )}{" "}
            <span className="here">
              <Link
                to={{
                  pathname: "/cart",
                  state: { sellerId: this.state.products.meta.sellers[0].id },
                }}
              >
                {t("HERE", "HERE")}
              </Link>
            </span>{" "}
            {t("TO CHECK IT OUT.", "TO CHECK IT OUT.")}
          </FeedBackMsgComponents>
        )}

        {this.state.showFeedback && this.state.isProductInCart && (
          <FeedBackMsgComponents
            msg={t(
              "THIS PRODUCT IS ALREADY IN YOUR CART.",
              "THIS PRODUCT IS ALREADY IN YOUR CART."
            )}
            close={this.closeFeedback}
          />
        )}
        <section className="centerComponent" id="lineSheetColletion">
          <div className="select-all-container">
            <ButtonComponent
              action={() => this.handleClickSelectAll()}
              className={`select-all-button ${this.state.selectedAll ? "select-all-button--selected" : ""
                }`}
            >
              {this.state.selectedAll
                ? t("DESELECT", "DESELECT")
                : t("SELECT", "SELECT")}{" "}
              {t("create-my-own-list_all", "ALL")}
            </ButtonComponent>
          </div>
          <div className="containerProduct">
            <ProductComponent
              makeCreateMyOwnList={this.makeCreateMyOwnList.bind(this)}
              isCheckBox={true}
              products={this.state.products.results}
            />
          </div>

          <div className="cartAndDownload">
            <ul>
              {window.localStorage.getItem("user_type") === "buyer" &&
                userData.allow_auto_add_one_unit_cart ? (
                <li className="cart" onClick={() => this.addCart(1)}>
                  <span />
                  <p>
                    {this.state.loadingAddCart ? (
                      <SpinnerComponent />
                    ) : (
                      t("Autofill 1 unit", "Autofill 1 unit")
                    )}
                  </p>
                </li>
              ) : null}
              {window.localStorage.getItem("user_type") === "buyer" && (
                <li className="cart" onClick={() => this.addCart()}>
                  <span />
                  <p>
                    {this.state.loadingAddCart ? (
                      <SpinnerComponent />
                    ) : (
                      t("Add to cart", "Add to cart")
                    )}
                  </p>
                </li>
              )}
              <li className="download" onClick={() => this.goLineSheet()}>
                <span></span>
                <p>{t("Download as PDF", "Download as PDF")}</p>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CreateMyOwnListComponent));
