const SAVE = "SAVE_BUYER";
const SAVE_LOCATIONS = "SAVE_LOCATIONS";

export function buyersReducer(state = [], action) {
  switch (action.type) {
    case SAVE:
      return action.payload;
    default:
      return state;
  }
}

export function locationsReducer(state = [], action) {
  switch (action.type) {
    case SAVE_LOCATIONS:
      return action.payload;
    default:
      return state;
  }
}