import React from "react";
import "../styles/BackToBrands.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const BackToBrands = (props) => {
  const { t } = props;
  return (
    <div id="backBrands">
      <Link to="/">
        {`<<`} <span>{t("Back to Brands", "Back to Brands")}</span>
      </Link>
    </div>
  );
};

export default withTranslation()(BackToBrands);
