import React from "react";
import { withTranslation } from "react-i18next";

const SizesComponent = (props) => {
  const { sizes, sizechart, handleClick, t } = props;

  return (
    <div className="containerSizes">
      <p className="titleContainerSizes">{t("SIZES", "SIZES")}</p>

      <ul>
        {sizes.map((size, index) => {
          return <li key={index}> {size.name} </li>;
        })}
      </ul>

      {sizechart && (
        <ul>
          <li onClick={() => handleClick()} className="viewSize">
            {t('VIEW SIZE CHART', 'VIEW SIZE CHART')}
          </li>
        </ul>
      )}
    </div>
  );
};

export default withTranslation()(SizesComponent);
