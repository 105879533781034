import React from 'react';
import SelectCollectionBox from "./SelectCollectionBox";


const SelectCollectionComponent = ({ seller, name }) => (
  <div>
      <SelectCollectionBox
          seller={seller}
          name={name}
      />
  </div>
);


export default SelectCollectionComponent;
