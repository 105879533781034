import { save } from "./CartActions";
import store from "../../store";
import CartEntity from "../../../domain/pages/cart/CartEntity";
import axios from "axios";
import OrderInterector from "../../../domain/pages/order/OrderInterector";
import OrderService from "../order/OrderService";
import mixpanel from "mixpanel-browser";

export default class CartService {
  headers = {
    Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    "Access-Control-Allow-Origin": "*",
  };
  HEADERS = { headers: this.headers };

  dispatch = store.dispatch;

  async addCart(id) {
    try {
      const pricelistId = Number(localStorage.getItem("user.pricelist"));
      const res = await axios.post("api/cart/add/", {
        id,
        pricelist_id: pricelistId,
      });

      this.getData();

      const produto = res.data;

      if (
        process.env.REACT_APP_MIXPANEL_ENABLED === true ||
        process.env.REACT_APP_MIXPANEL_ENABLED === "true"
      ) {
        if(window.localStorage.getItem("userData")) {
        mixpanel.identify(
          `${JSON.parse(window.localStorage.getItem("userData")).id}`
        );
      }
        mixpanel.track("Add to Cart", {
          seller: produto.collection.seller.name,
          seller_id: produto.collection.seller.id,
          collection: produto.collection.name,
          collection_id: produto.collection.id,
          product_name: produto.name,
          product_id: produto.id,
          category_id: produto.categories.map((item) => item.id).join(","),
          category: produto.categories.map((item) => item.name).join(","),
          tags: produto.tags.map((item) => item.name).join(","),
          tags_id: produto.tags.map((item) => item.id).join(","),
        });
      }

      return res;
    } catch (e) {
      return e.response.status;
    }
  }

  async addVariationsToCart(ids) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/cart/add-product`,
        {
          product_variation_id: ids,
          pricelist_id: Number(localStorage.getItem("user.pricelist")),
        }
      );

      this.getData();

      return res;

    } catch (e) {
      return e.response;
    }
  }

  async removeProductsAll(cartId) {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_NEW_API_URL}/v1/cart/${cartId}`);
      this.getData();
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  async removeCart(cartId, productId) {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_NEW_API_URL}/v1/cart/${cartId}/products/${productId}`
      );
      this.getData();
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  async getData() {
    if (
      localStorage.getItem("user") !== null &&
      localStorage.getItem("user_type") === "buyer"
    ) {
      let obj = [];
      try {
        let result = await fetch(
          `${process.env.REACT_APP_NEW_API_URL}/v1/cart`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "new_access_token"
              )}`,
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          }
        );
        let response = await result.json();
        obj.push(response);
        this.entityMount(obj);
      } catch (err) {
        window.console.log(`[Erro] ${err}`);
        return Promise.reject(400);
      }
    }
  }

  async finalizeOrder(id, message) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/cart/${id}/finalize`,
        { message }
      );
      new OrderInterector(new OrderService().getData());
      return res;
    } catch (e) {
      return e.response;
    }
  }

  entityMount(response) {
    let entityCart = [];

    response.forEach((item) => {
      entityCart.push(new CartEntity(item));
    });

    this.dispatchSave(entityCart);
  }

  dispatchSave(entityList) {
    this.dispatch(save(entityList));
  }
}
