import React from "react";
import "../style/callToActionStyle.scss";
import ButtonComponent from "../../button/components/ButtonComponent";

const CallToAction = (props) => {
  const { callToAction, meta } = props;

  let backgroundImage = callToAction.image;

  if (window.screen.width < 768) {
    backgroundImage = callToAction.image_mobile;
  }

  const renderCallToAction = () => {
    return (
      <div
        id="callToAction"
        className={`${callToAction.title || callToAction.text ? " callToAction--overlay" : ""} ${meta !== null && meta.draft_changes.call_to_action.includes("image") ? "border-marker" : ""}`}
        style={{ backgroundImage: "url(" + backgroundImage + ")" }}
      >
        <div className="textCallToAction centerComponent">
          <div>
            <h2 className={`${meta !== null && meta.draft_changes.call_to_action.includes("title") ? "border-marker" : ""}`}>{callToAction.title}</h2>
            <p className={`${meta !== null && meta.draft_changes.call_to_action.includes("text") ? "border-marker" : ""}`}>{callToAction.text}</p>
            {callToAction.button_label && callToAction.link && (
              <div className="button-container">
                <ButtonComponent
                  action={() => {
                    return null;
                  }}
                >
                  {callToAction.button_label}
                </ButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {backgroundImage !== null && (
        <>
          {callToAction.link ? (
            <a
              href={callToAction.link || "#"}
              target={callToAction.link ? "_blank" : "_self"}
            >
              {renderCallToAction()}
            </a>
          ) : (
            renderCallToAction()
          )}
        </>
      )}
    </div>
  );
};

export default CallToAction;
