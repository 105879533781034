import React from "react";
import { testForm } from "../../utils/formValidate";
import "./styles.scss";

export default function InputProfile(props){
    const { titleInput, typeInput, isRequired, nameInput, width, value, onchange, typeValidation, nameValue, wd, disabled, error} = props
    const [ changeItem, setChange] = React.useState(0)
    const validationInput = changeItem != 0 && value.length > 0 | value === ''
    
    return(
        <div className={`box-input-profile`} style={{width: `${wd}`}}>
            <label className={ `${!!isRequired && "input-required"}`} htmlFor={`${nameInput}`}>
                {titleInput}
            </label>
            <input onChange={e => {
                onchange(nameValue, e.target.value)
                setChange(1)
            }} 
            value={value} 
            className="box-input-profile__input" 
            type={`${typeInput}`} 
            name={`${nameInput}`} 
            id={`${nameInput}`}
            disabled={disabled}
            />
            <p className="box-input-profile__validation">{error}</p>
        </div>
    )
}