import React, { Component } from "react";
import "../../styles/SocialIcons.scss";

class SocialIconsComponent extends Component {
  render() {
    return (
      <div className="social-icons">
        {this.props.facebook && (
          <a
            href={this.props.facebook}
            className="social-icon facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;
          </a>
        )}
        {this.props.instagram && (
          <a
            href={this.props.instagram}
            className="social-icon instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;
          </a>
        )}
        {this.props.website && (
          <a
            href={this.props.website}
            className="social-icon website"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;
          </a>
        )}
      </div>
    );
  }
}

export default SocialIconsComponent;
