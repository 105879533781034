import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import "../../styles/MessagesView/MessagesChatComponent.scss";
import MessageChatBlockComponent from "./MessageChatBlockComponent";
import SpinnerComponent from "../../../shared/spinner";
import { withTranslation } from "react-i18next";

class MessagesChatComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingMessage: false,
      messages: [],
      message: "",
      subject: null,
      subjectId: null,
      arquivo: null,
      lastHashMessage: null,
    };
    this.messagesChatBlockContainer = React.createRef();
    this.inputArquivo = React.createRef();
  }

  componentDidMount() {
    this.fetchSubject();
    this.fetchMessages();
  }

  componentDidUpdate(prevProps) {
    if (this.state.subjectId !== this.props.match.params.subjectId) {
      this.setState({
        loading: true,
        messages: [],
        subject: null,
        lastHashMessage: null,
      });
      this.fetchSubject();
      this.fetchMessages();
    } else {
      const hashMessage = window.location.hash;

      if (this.state.lastHashMessage !== hashMessage) {
        this.moveScroll();
      }
    }
  }

  fetchSubject() {
    const { subjectId } = this.props.match.params;

    this.setState({
      subjectId: subjectId,
    });

    const userStype = localStorage.getItem("user_stype");

    const type = userStype === "seller" ? "buyers" : "sellers";

    axios
      .get(`${process.env.REACT_APP_NEW_API_URL}/v1/${type}/${subjectId}`)
      .then((response) => {
        if (response.data.data) {
          this.setState({
            subject: response.data.data,
            loading: false,
          });
        }
      });
  }

  fetchMessages() {
    const { subjectId } = this.props.match.params;
    const self = this;

    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages/${subjectId}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState(
            {
              messages: response.data.data,
              loading: false,
            },
            () => {
              this.moveScroll();
            }
          );
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 404) {
          self.props.history.push("/messages");
        }
      });
  }

  moveScroll() {
    const node = this.messagesChatBlockContainer.current;
    let messageNode = null;
    const hashMessage = window.location.hash;
    if (hashMessage) {
      messageNode = document.querySelector(hashMessage);
    }

    if (messageNode) {
      messageNode.scrollIntoView(true);

      setTimeout(() => {
        window.scrollBy(0, -70);
      }, 100);

      this.setState({
        lastHashMessage: hashMessage,
      });
    } else if (node) {
      node.scroll({
        top: node.scrollHeight,
      });
    }
  }

  renderChatMessages() {
    return this.state.messages.map((message) => (
      <MessageChatBlockComponent key={message.id} message={message} />
    ));
  }

  handleSendMessage() {
    if (!this.state.message && !this.state.arquivo) {
      return;
    }

    const { subjectId } = this.props.match.params;

    const formData = new FormData();
    formData.append("message", this.state.message);
    if (this.state.arquivo) {
      formData.append("arquivo", this.state.arquivo);
    }

    this.setState({
      loadingMessage: true,
      message: "",
      arquivo: null,
    });

    axios
      .post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/inbox-messages/${subjectId}/send`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then(() => {
        this.setState({
          loadingMessage: false,
        });

        this.fetchMessages();
      })
      .catch(() => {
        alert("An error occured!");
      });
  }

  handleArquivoChange(e) {
    if (!e.target.files || !e.target.files.length < 0) {
      return;
    }

    this.setState({
      arquivo: e.target.files[0],
    });
  }

  handleClickAttach() {
    if (this.inputArquivo.current) {
      this.inputArquivo.current.click();
    }
  }

  handleChange(e) {
    this.setState({
      message: e.target.value,
    });
  }

  removeAnexo() {
    this.setState({
      arquivo: null,
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div id="messages-chat-component">
        {this.state.loading && <LoadingComponent />}
        {this.state.subject && this.state.subject.name && (
          <div className="title">{this.state.subject.name}</div>
        )}
        <div
          className="messages-chat-block-container"
          ref={this.messagesChatBlockContainer}
        >
          {this.state.messages.length > 0 && this.renderChatMessages()}
        </div>
        <div className="message-text-area-container">
          <div
            className="attach"
            onClick={() => this.handleClickAttach()}
          ></div>
          <div className="emoji"></div>
          <textarea
            className="message-text-area"
            rows="4"
            placeholder={t(
              "Text your message here...",
              "Text your message here..."
            )}
            onChange={(e) => this.handleChange(e)}
            value={this.state.message}
          ></textarea>
          <div
            className={`send ${this.state.loadingMessage && "send--loading"}`}
            onClick={() => this.handleSendMessage()}
          >
            {this.state.loadingMessage && <SpinnerComponent />}
          </div>
          {this.state.arquivo && (
            <div className="input-anexo">
              <span className="close" onClick={this.removeAnexo.bind(this)}>
                x
              </span>
              {this.state.arquivo.name}
            </div>
          )}
        </div>
        <input
          type="file"
          name="arquivo"
          className="hiddenInput"
          onChange={this.handleArquivoChange.bind(this)}
          ref={this.inputArquivo}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(MessagesChatComponent));
