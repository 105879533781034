import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import MenuComponent from "../../menu/components/MenuComponent";
import BrandsBoxComponent from "../../shared/brandsbox/components/BrandsBoxComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import WelcomeModal from "../../welcomemodal/WelcomeModal";
import { Link } from "react-router-dom";
import "../styles/NewHome.scss";
import isMobile from "is-mobile";
import SliderComponent from "../../shared/Slider/components/SliderComponent";
import CartInterector from "../../../domain/pages/cart/CartInterector";
import CartService from "../../../data/pages/cart/CartService";
import FeedBackMsgComponents from "../../shared/feedbackmsg/components/FeedBackMsgComponents";
import { GetVariationColorStyle } from "../../../utils/productHelper.js";
import JumboTitleComponent from "../../shared/jumbotitle/components/JumboTitleComponent";
import { withTranslation } from "react-i18next";

class HomeSugestedViewComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedProductName: "",
      selectedProductSellerId: "",
      showFeedback: false,
      msg: false,
      showWelcomeMessage: false,
      user: {
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
      },
    };
    this.timer = null;
    this.closeFeedback = this.closeFeedback.bind(this);
    this.closeWelcomeModal = this.closeWelcomeModal.bind(this);
  }

  getAllInfoHome() {
    axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/home`).then((response) => {
      this.setState({
        loading: false,
        brands: response.data,
      });
    });
  }

  addToCart(id, name, sellerId) {
    new CartInterector(
      new CartService()
        .addCart(id)
        .then((res) => {
          if (res === 409) {
            this.setState({
              isProductInCart: true,
            });
            this.showFeedback();
          } else {
            this.setState({
              msg: true,
              selectedProductName: name,
              selectedProductSellerId: sellerId,
            });
            this.showFeedback();
          }
        })
        .catch((reason) => {
          console.log(reason, 123);
        })
    );
  }

  showFeedback() {
    this.setState({
      showFeedback: true,
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(this.closeFeedback, 4000);
  }

  closeFeedback() {
    clearTimeout(this.timer);
    this.setState({
      showFeedback: false,
      msg: false,
      isProductInCart: false,
    });
  }

  static whatIsTheVariationType(res, index) {
    const variationStyle = GetVariationColorStyle(res);
    return (
      <li key={index} style={variationStyle.li}>
        <span
          data-tip={res.label}
          data-for={res.label}
          className="colorListNoTexture"
          style={variationStyle.span}
        ></span>
        <ReactTooltip
          className="variation-tooltip"
          id={res.label}
          place="top"
          type="light"
          effect="solid"
        />
      </li>
    );
  }

  componentDidMount() {
    this.getAllInfoHome();
    let show_welcome_msg = false;
    console.log("estou aqq", window.localStorage.getItem("user_stype"));
    if (
      (window.localStorage.getItem("show_welcome_msg") === "true" ||
        window.localStorage.getItem("show_welcome_msg") === true) &&
      window.localStorage.getItem("user_stype") === "buyer"
    ) {
      show_welcome_msg = true;
    }

    this.setState({ showWelcomeMessage: show_welcome_msg });
  }

  closeWelcomeModal() {
    this.setState({ showWelcomeMessage: false });
  }

  getUniqueCollections(collections) {
    var collectionsUnique = [];

    for (let index = 0; index < collections.length; index++) {
      const element = collections[index];

      if (
        collectionsUnique.filter((item) => item.id === element.id).length <= 0
      ) {
        collectionsUnique.push(element);
      }
    }

    return collectionsUnique;
  }

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return <LoadingComponent />;
    }

    return (
      <div>
        <MenuComponent />

        {this.state.showWelcomeMessage && (
          <WelcomeModal close={this.closeWelcomeModal} />
        )}
        {this.state.showFeedback && this.state.msg && (
          <FeedBackMsgComponents close={this.closeFeedback}>
            {t("GREAT! THE ITEM", "GREAT! THE ITEM")}{" "}
            <span className="product-name">
              {" "}
              {this.state.selectedProductName}{" "}
            </span>{" "}
            {t(
              "HAS BEEN ADDED TO YOUR CART. CLICK",
              "HAS BEEN ADDED TO YOUR CART. CLICK"
            )}{" "}
            <span className="here">
              <Link
                to={{
                  pathname: "/cart",
                  state: { sellerId: this.state.selectedProductSellerId },
                }}
              >
                {t("HERE", "HERE")}
              </Link>
            </span>{" "}
            {t("TO CHECK IT OUT.", "TO CHECK IT OUT.")}
          </FeedBackMsgComponents>
        )}

        {this.state.showFeedback && this.state.isProductInCart && (
          <FeedBackMsgComponents
            msg={t(
              "THIS PRODUCT IS ALREADY IN YOUR CART.",
              "THIS PRODUCT IS ALREADY IN YOUR CART."
            )}
            close={this.closeFeedback}
          />
        )}

        <main className="sellerView">
          <JumboTitleComponent title={t("Our Brands", "Our Brands")} />

          <section className="brands centerComponent">
            <BrandsBoxComponent brands={this.state.brands.brands} />
          </section>

          {this.state.brands.collections.length > 0 && (
            <>
              <JumboTitleComponent title="Latest Linesheets" />

              <section className="linesheet">
                <div className="containerLineSheetHome">
                  <SliderComponent
                    dots={true}
                    infinite={false}
                    speed="500"
                    isMobile={isMobile()}
                    activeSlide={false}
                    slidetoshow={4}
                    slideScroll={4}
                    slidesPerRow={1}
                  >
                    {this.getUniqueCollections(
                      this.state.brands.collections
                    ).map((item, index) => {
                      return (
                        <div className="containerImage" key={index}>
                          <div className="blockBrand">
                            <Link
                              to={`products/?collections=${item.id}`}
                              className="blockBrand"
                              key={index}
                            >
                              <img
                                src={item.list_image_thumbnail}
                                alt={item.name}
                              />
                            </Link>
                          </div>

                          <p>
                            <Link
                              to={`products/?collections=${item.id}`}
                              className="blockBrand"
                              key={index}
                            >
                              {item.name}
                            </Link>
                          </p>

                          <p>{item.seller.name}</p>
                        </div>
                      );
                    })}
                  </SliderComponent>
                </div>
              </section>
            </>
          )}

          {this.state.brands.products.length > 0 && (
            <>
              <JumboTitleComponent
                title={t("Suggested Products", "Suggested Products")}
              />

              <section id="suggested">
                <div className="containerLineSheetHome">
                  <div className="contentSlider">
                    <SliderComponent
                      dots={true}
                      infinite={false}
                      speed="500"
                      slidetoshow={4}
                      slideScroll={4}
                      slidesPerRow={1}
                    >
                      {this.state.brands.products.map((product, index) => {
                        return (
                          <div
                            className="blocksProducts blocksProductsInfo"
                            key={index}
                          >
                            <div
                              className="topBlockProducts"
                            >
                              <Link to={`/product/${product.id}`}>
                                <>
                                  <div className="first-image-wrapper">
                                    {product.images[0] && (
                                      <img
                                        className="first-image"
                                        src={`${product.images[0].picture_thumbnail}`}
                                        alt="Products"
                                      />
                                    )}
                                  </div>
                                  {product.images.length > 1 && (
                                    <div className="hover-image-wrapper">
                                      <img
                                        className="hover-image"
                                        src={`${product.images[1].picture_thumbnail}`}
                                        alt="Products"
                                      />
                                    </div>
                                  )}
                                </>
                              </Link>
                            </div>

                            <div className="mainProduct">
                              <div className="nameBrand">
                                {product.seller_name}
                              </div>
                              <div className="nameBrand">{product.name}</div>

                              <ul className="variations">
                                {product.variations.map((variation, i) => {
                                  return HomeSugestedViewComponent.whatIsTheVariationType(
                                    variation,
                                    i
                                  );
                                })}
                              </ul>

                              <div className="prices">
                                <p>
                                  <span className="bold">W</span>
                                  {" | "}
                                  {product.wholesale_price.currency}{" "}
                                  {parseFloat(
                                    product.wholesale_price.amount
                                  ).toFixed(2)}
                                </p>
                                <p>
                                  {" "}
                                  <span className="bold">R</span>
                                  {" | "}
                                  {product.retailer_price.currency}{" "}
                                  {parseFloat(
                                    product.retailer_price.amount
                                  ).toFixed(2)}
                                </p>
                              </div>
                              {window.localStorage.getItem("user_type") ===
                                "buyer" && (
                                <button
                                  className="buttonProduct add-to-cart-btn"
                                  onClick={() =>
                                    this.addToCart(
                                      product.id,
                                      product.name,
                                      product.seller_id
                                    )
                                  }
                                >
                                  {t("ADD TO CART", "ADD TO CART")}
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </SliderComponent>
                  </div>
                </div>
              </section>
            </>
          )}
        </main>

        <FooterComponent />
      </div>
    );
  }
}

export default withTranslation()(HomeSugestedViewComponent);
