import Axios from "axios";
import React from "react";
import { createPortal } from "react-dom";
import iconError from "../../imgs/icon-error.svg";
import ButtonBgProfile from "../buttonWithBgProfile";
import "./styles.scss";

export default function VerifyCode(props){
  const { changeStep, email } = props
  const [ messageRequest, setMessageRequest ] = React.useState('')
  const [valuesForm, setValuesForm] = React.useState({
    email: email,
    code: ''
  })
  const [ disabledItem, setDisabledItem ] = React.useState(false)

  React.useEffect(() => {
    document.body.style.overflow = "hidden" 
    
    return(() => document.body.style.overflow = "auto"  
    )
  },[])

  function changeValuesForm(inputName, newValue) {
    const values = {...valuesForm}
    values[inputName] = newValue
    
    setValuesForm(values)
  }

  async function verifyCode() {    
    setMessageRequest('')
    setDisabledItem(true)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/auth/change-password/verify-code?code=${valuesForm.code}`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      setDisabledItem(true)
      let response = await Axios(config);
      localStorage.setItem("codePassChange", valuesForm.code)
      changeStep(1)
    } 
    catch(error){
      setDisabledItem(false)
      setMessageRequest(error.response.data.message)
    }
  }

  return (
    <>
      {   
        createPortal(
          <div className="container-verify-code" >
            <div className="box-change-password">
              <div onClick={() => changeStep('')} className="closed-box">X</div>
              <h3>PASSWORD CHANGE</h3>
              <p>
                Confirm your identity to change your password. 
                Enter the one-time code that we sent your email and set a new password.
              </p>
              <p>CODE</p>
              <input value={valuesForm.code} onChange={(e) => changeValuesForm('code', e.target.value)} type="text" />

              {messageRequest && (
                <p className="container-renew-pass__message-request">
                  {messageRequest}
                  <img src={iconError} alt="icone de feedback de erro" />
                </p>
              )}

              <ButtonBgProfile disabled={disabledItem} onclick={() => verifyCode()} w="133" margin="15px 0 0 0 "/>
            </div>
          </div>,
          document.body
        )
      }
    </>
  )
}