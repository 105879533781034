import React, { Component } from 'react';
import "../style/ourRetailers.scss"


export default class Retailer extends Component {
    render() {
        return (
          <div className="retailer">
            <div className="retailer-image-wrapper">
              <img src={this.props.retailer.logo_landing} alt={this.props.retailer.name} />
            </div>
          </div>
        );
    }
}
