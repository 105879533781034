import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../style/SortByFilterStyle.scss";

class SortByFilter extends Component {
  handleChange(ev) {
    this.props.setFilters("ordering", ev.target.value);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="SortByFilter">
        <div className="titleSortBy">{t("sort by", "sort by")}:</div>

        <select
          name="selectFilter"
          className="selectFilter"
          onChange={(ev) => this.handleChange(ev)}
        >
          <option value=""> {t("Select ordering", "Select ordering")}</option>
          <option value="collection_order">
            {t("Relevance", "Relevance")}
          </option>
          <option value="-created">{t("Newest", "Newest")}</option>
          <option value="wholesale_price">
            {t("Wholesale Price", "Wholesale Price")}:{" "}
            {t("Low to High", "Low to High")}
          </option>
          <option value="-wholesale_price">
            {t("Wholesale Price", "Wholesale Price")}:{" "}
            {t("High to Low", "High to Low")}
          </option>
          <option value="retailer_price">
            {t("Retail Price", "Retail Price")}:{" "}
            {t("Low to High", "Low to High")}
          </option>
          <option value="-retailer_price">
            {t("Retail Price", "Retail Price")}:{" "}
            {t("High to Low", "High to Low")}
          </option>
          <option value="order_delivery_days">
            {t("Shipping days", "Shipping days")}:{" "}
            {t("Low to High", "Low to High")}
          </option>
          <option value="-order_delivery_days">
            {t("Shipping days", "Shipping days")}:{" "}
            {t("High to Low", "High to Low")}
          </option>
        </select>
      </div>
    );
  }
}

export default withTranslation()(SortByFilter);
