import React, { useState, useEffect, Fragment } from 'react';
import '../styles/LandingPageHKH.scss';
import NavbarComponent from '../../../landing/shared/navbar/components/NavbarComponent';
import MenuComponent from '../../../menu/components/MenuComponent';
import { withTranslation } from 'react-i18next';
import LightBoxComponent from '../../../shared/lightbox/components/LightBoxComponent';
import LoginComponent from '../../../shared/Login/components/LoginComponent';
import axios from 'axios';
import FooterComponent from '../../../shared/footer/components/FooterComponent';
import { Helmet } from 'react-helmet-async';
import 'react-id-swiper/lib/styles/scss/swiper.scss';
import isMobile from 'is-mobile';
import ReactIdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom';
import Brand from '../../../landing/shared/ourBrands/components/Brand';
import { Swiper, Navigation, Pagination } from 'swiper/dist/js/swiper.esm';
import RegisterModalComponent from '../../../shared/registermodal/components/RegisterModalComponent';
import HighlightD from '../../../shared/highlights/components/highlightD/HighlightD';

const LandingPageHKH = () => {
  const [dataApiHKHBrands, setDataApiHKHBrands] = useState(null);
  const [dataHeaderHkh, setDataHeaderHkh] = useState(null);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [registerCompanyType, setRegisterCompanyType] = useState('');

  const getDataHkh = async () => {
    try {
      const resultBrands = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/landing?events[]=hkh`
      );
      const resultHeader = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/heroes?events[]=hkh`
      );

      setDataApiHKHBrands(resultBrands.data);
      setDataHeaderHkh(resultHeader.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const openLogin = (eventUrl = '') => {
    if (eventUrl !== '') {
      localStorage.setItem('vincular_evento', `/${eventUrl}`);
    }

    setShowLogin(true);
  };

  const closeLogin = () => {
    localStorage.removeItem('redirectBrand');
    localStorage.removeItem('vincular_evento');
    setShowLogin(false);
  };

  const openRegister = (companyType = '', eventUrl = '') => {
    if (eventUrl !== '') {
      localStorage.setItem('defaultEvent', `/${eventUrl}`);
    }

    setRegisterCompanyType(companyType);
    setShowRegister(true);
  };

  const closeRegister = () => {
    localStorage.removeItem('defaultEvent');
    setShowRegister(false);
  };

  useEffect(() => {
    getDataHkh();
  }, []);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const isMobileDevice = isMobile();

  const settings = {
    Swiper,
    modules: [Navigation, Pagination],
    preloadImages: true,
    updateOnImagesReady: true,
    slidesPerGroup: isMobile() ? 2 : 1,
    slidesPerView: isMobile() ? 2 : 4,
    slidesPerColumn: 1,
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    spaceBetween: 10,
    slideVisibleClass: 'active-slider'
  };

  const next = () => {
    if (swiper) {
      swiper.slideNext();
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const previous = () => {
    if (swiper) {
      swiper.slidePrev();
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }
  };

  const generateStyleTags = () => {
    if (!dataHeaderHkh) {
      return;
    }

    return dataHeaderHkh.map((item, index) => (
      <Fragment key={index}>
        <Helmet>
          <style type="text/css" id={`style-hkh-${index}`}>
            {item.styles}
          </style>
        </Helmet>
      </Fragment>
    ));
  };

  return (
    <Fragment>
      {
        window.localStorage.getItem('user') === null
          ? <NavbarComponent showLogin={() => openLogin()} />
          : <MenuComponent />
      }
      <div className="mainLpHkh">
        {/* Hero Section */}
        <section className="bannerHeroLp">
          {generateStyleTags()}
          {dataHeaderHkh &&
            dataHeaderHkh.map((item, index) => (
              <HighlightD
                id={`highlight-${index}`}
                key={index}
                openRegister={openRegister}
                open={openLogin}
                data={item}
                slug={item.slug && item.slug}
              />
            ))}
        </section>

        {/* About section */}
        <section className="aboutHKH">
          <div className="container">
            <div class="row">
              <div class="col-40">
                <div class="boxTittleSection">
                  <h1>About HKH</h1>
                </div>
              </div>
              <div class="col-60">
                <div class="textoAbout">
                  <p>
                    We are passionate beauty experts. We offer tailor-made services to introduce and
                    expand luxury & beauty brands into the European market. From strategy to operation,
                    we’ve helped some relevant brands to grow internationally, such as Sol de Janeiro,
                    Costa Brazil, Biossance, Veronique Gabai, Deva Curl, Fekkai, and Goldfaden, among others.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="brandHkh">
          <div className="container">
            <div className="boxTitle">
              <h1>Meet the Brands*</h1>
            </div>
            <div className="boxAllSwiper">
              <div className="slider-wrapper">
                <div className="slider-hkh">
                  <ReactIdSwiper
                    {...settings}
                    getSwiper={(swiperInstance) => setSwiper(swiperInstance)}
                  >
                    {dataApiHKHBrands &&
                      dataApiHKHBrands.map((brand) => (
                        <Brand
                          key={brand.id}
                          brand={brand}
                          eventSegment={brand.event_segments[0]}
                        />
                      ))}
                  </ReactIdSwiper>
                </div>
              </div>
              <div className="buttons buttons-list">
                <div
                  className={`swiper-button-prev ${
                    isBeginning && 'swiper-button-disabled'
                  }`}
                  onClick={previous}
                ></div>
                <div
                  className={`swiper-button-next ${
                    isEnd && 'swiper-button-disabled'
                  }`}
                  onClick={next}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {showRegister && (
        <RegisterModalComponent
          companyType={registerCompanyType}
          close={() => {
            closeRegister();
            setShowRegister(false);
          }}
        />
      )}
      {showLogin && (
        <LightBoxComponent
          isOpen={showLogin}
          handleClick={() => closeLogin()}
          handleEventClose={() => closeLogin()}
        >
          <LoginComponent
            closeRegister={closeRegister}
            OpenRegister={openRegister}
          />
        </LightBoxComponent>
      )}
      <FooterComponent />
    </Fragment>
  );
};

export default withTranslation()(LandingPageHKH);
