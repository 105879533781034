import React, { Component } from 'react';
import "../style/Grid2Style.scss"
import ShowcaseComponent from "../../../showcase/components/ShowcaseComponent";
import TitleShadowComponent from "../../../titleShadow/components/TitleShadowComponent";
import InfoComponent from "../../../info/components/InfoComponent";
import ButtonComponent from "../../../button/components/ButtonComponent";
import isMobile from 'is-mobile';

class Grid1Component extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEnable: false
        }
    }

    toggleReadMore(isEnable) {
        this.setState({
            isEnable: !isEnable
        })
    }

    render() {

        const { info, title, images, imageOrientation, listInfo, label, fullText, linkTo, changes } = this.props;

        if (!isMobile()) {
            return (
                <div className="grid2">
                    <div className="containerGrid">
                        <div className="leftGrid">
                            {info &&
                                <InfoComponent info={listInfo} />
                            }

                            <TitleShadowComponent
                                position="right"
                                type="h2"
                                tagClass={changes && changes.properties.length > 0 && changes.properties.includes("title") ? "border-marker" : ""}
                                disableMargin={images.filter(item => item.video_url).length > 0}>
                                {title}
                            </TitleShadowComponent>

                            <p className={`${changes && changes.properties.length > 0 && changes.properties.includes("text") ? "border-marker" : ""}`}>
                                {/* {
                                    this.state.isEnable ? (
                                        <span>{ fullText }</span>
                                    ) : (
                                        <span>{ text }</span>
                                    )
                                } */}
                                {fullText}
                            </p>

                            <div className="contentLinkButton">
                                {/* <div>
                                    <div className="linkShowCase" onClick={() => this.toggleReadMore(this.state.isEnable)}>
                                    {this.state.isEnable ? (
                                        "<<"
                                    ) : (
                                        <span>Read more >></span>
                                    )}

                                    </div>
                                </div> */}
                                {linkTo &&
                                    <ButtonComponent type="link" action={linkTo}>
                                        {label === "" ? "SEE PRODUCTS" : label}
                                    </ButtonComponent>
                                }
                            </div>
                        </div>

                        <div className={`rightGrid ${imageOrientation}`}>
                            <ShowcaseComponent markChange={changes && changes.images ? true : false} imageOrientation={imageOrientation} images={images} type="landscape_image" />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="grid2">
                    <div className="main-image">
                        <ShowcaseComponent imageOrientation={imageOrientation} images={images} />
                    </div>
                    <div className={`grid-title mob ${images.filter(item => item.video_url).length > 0 ? 'disable-margin' : ''}`}>
                        <TitleShadowComponent
                            position="left"
                            type="h2"
                            tagClass={changes && changes.properties.length > 0 && changes.properties.includes("title") ? "border-marker" : ""}
                            disableMargin={images.filter(item => item.video_url).length > 0}>
                            {title}
                        </TitleShadowComponent>
                    </div>
                    <div className="grid4-mobile-content">
                        <p className={`description ${changes && changes.properties.length > 0 && changes.properties.includes("text") ? "border-marker" : ""}`}>
                            {/* {
                                this.state.isEnable ? (
                                    <span>{ fullText }</span>
                                ) : (
                                    <span>{ text }</span>
                                )
                            } */}
                            {fullText}
                        </p>

                        <div className="content-buttons">
                            {/* <div className="read-more-link">
                                <div className="link-show-case">
                                    <span onClick={() => this.toggleReadMore(this.state.isEnable)}>Read more</span> >>
                                </div>
                            </div> */}

                            {linkTo &&
                                <div className="see-products-button">
                                    <ButtonComponent type="link" action={linkTo}>
                                        {label === "" ? "SEE PRODUCTS" : label}
                                    </ButtonComponent>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Grid1Component;
