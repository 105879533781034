import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";

function PrivateRoute({ component: Component, ...rest }) {
  let next = "";

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  function tokenLogin() {
    localStorage.setItem("temp_token", token);
    AuthenticationService.loginByToken();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        window.localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect
              to={{
                pathname: "/",
                search: next,
                state: { from: props.location },
              }}
            />

            {token && tokenLogin()}
          </>
        )
      }
    />
  );
}

export default PrivateRoute;
