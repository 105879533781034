import { Component } from "react";
import axios from "axios";

class BaseComponent extends Component {
  endPoint = "";
  filters = {};
  requestName = "";

  _getFilters() {
    let filters = "";

    for (let filter in this.filters) {
      filters += filter + "=" + this.filters[filter] + "&";
    }

    if (this.endPoint.match(/\?/)) {
      filters = "&" + filters;
    } else {
      filters = "/?" + filters;
    }

    if (filters.endsWith("&")) {
      filters = filters.slice(0, -1);
    }

    return filters;
  }

  _make_url(newEndPoint) {
    if (newEndPoint) {
      this.endPoint = newEndPoint;
    }

    let filters = this._getFilters();
    
    if(this.endPoint.includes('pricelist') && filters.includes('pricelist')){
      if(this.requestName === 'pricelist' && filters.includes('page')){
        const urlObj = new URLSearchParams(filters);
        urlObj.delete('page');
        return this.url + this.updateEndpoint(this.endPoint, urlObj.toString())
      }
      return this.url + this.updateEndpoint(this.endPoint, filters);
    }

    return this.url + this.endPoint + filters;
  }

  // Checa se o parâmetro já existe e substitui
  updateEndpoint(endpoint, param) {
    endpoint = endpoint.startsWith('?') ? endpoint.slice(1) : endpoint;

    const urlParams = new URLSearchParams(endpoint);

    // &
    const newParams = param.replace(/^&|&$/g, '').split('&');

    newParams.forEach(paramPair => {
        const [key, value] = paramPair.split('=');
        urlParams.set(key, value);
    });

    return '?' + urlParams.toString();
}


  getProducts(newEndPoint, retry = false) {
    const defaultCallBackResponse = (response) => {
      if (response.data.results.length === 0 && !retry) {
        // Remove valor da pricelist 
        const params = new URLSearchParams(newEndPoint);
        if (params.has('pricelist')) {
            params.set('pricelist', '');
        }
        const endpoint = '?' + params.toString();
        // Refaz requisição sem pricelist - recursividade
        this.getProducts(endpoint, true);

        // Retira valor da pricelist da url atual e do localstorage
        const urlparams = new URLSearchParams(window.location.search);
        localStorage.setItem('user.pricelist', '');
        urlparams.set('pricelist', '');
        this.props.history.replace({ search: urlparams.toString() });
        return;
      }
      this.setState({
        products: response.data,
        loading: false,
        loadingProduct: false,
      });
    };

    this.setState({
      loadingProduct: true,
    });

    const url = this._make_url(newEndPoint);
    
    axios.get(url, this.headers).then(defaultCallBackResponse);
  }

  getFilters() {
    return this.filters;
  }

  clearArrayFilters() {
    this.filters = {
      pricelist: window.localStorage.getItem("user.pricelist") || new URLSearchParams(this.props.location.search).get("pricelist") || "",
    };

    const urlObj = new URL(this.endPoint, window.location.origin);
    const params = new URLSearchParams(urlObj.search);
    params.has('segments') && params.delete('segments');
    const newUrl = `?${params.toString()}`;
    this.endPoint = newUrl;
  }

  clearAllFilter() {
    let inputs = document.querySelectorAll("input");
    let select = document.querySelector(".selectFilter");

    if (select) {
      select.value = "";
    }

    this.clearArrayFilters();

    inputs.forEach((el) => {
      el.checked = false;
    });

    this.setState({
      currentPage: 1,
    });
  }

  setFilters(name, value) {
    this.filters[name] = value;
    this.requestName = name;

    if(name !== 'page') {
      this.filters['page'] = '1';
    }

    this.getProducts();
  }
}

export default BaseComponent;
