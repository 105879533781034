import React, {Component} from 'react';
import "../style/LookBookLightBoxStyle.scss"

class LookBookLightBoxComponent extends Component {
    render() {
        const { children, handleClick } = this.props;

        return (
            <div>
                <div className="lightBoxLookBook">
                    <div className="bgLightBox" onClick={() => handleClick()} />
                    <div className="containerLightBox">
                        <div className="topLightBox">
                            <div className="closed" onClick={() => handleClick()}>
                                <img src={`${process.env.PUBLIC_URL}/images/closedLightBoxLookBook.png`} alt="" />
                            </div>
                        </div>

                        <div className="blockLightBox">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LookBookLightBoxComponent;