import React from "react";
import close from "../../../src/imgs/remove-address.svg";
import correct from "../../../src/imgs/correct.svg";
import "./styles.scss";
import Axios from "axios";
import Loading from "../../components/Loading/index";

export default function CardUsersManagement(props) {
  const { name, email, status } = props;
  const [currentStatus, setCurrentStatus] = React.useState({
    load: false,
    status: "",
    error: "",
  });

  async function updateStatus(body) {
    // Estado de loading
    setCurrentStatus((state) => ({ ...state, load: true }));

    try {
      // Request
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/buyer/toggle-user`,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
          "Access-Control-Allow-Origin": "*",
        },
        data: { email: body },
      });

      setCurrentStatus((state) => ({
        ...state,
        status: response.data.data.status,
      }));

    } catch (error) {
      setCurrentStatus((state) => ({
        ...state,
        error: error.response.data.message,
      }));
    } finally {
      setCurrentStatus((state) => ({ ...state, load: false }));
    }
  }

  React.useEffect(() => {
    setCurrentStatus((state) => ({ ...state, status: status }));
  }, []);

  return (
    <>
      <div className="container-card-users-management">
        <div className="itens">
          <p>{name}</p>
          <p>{email}</p>
          <p>
            {currentStatus.load ? (
              <Loading borderColor={"black"} borderTopColor={"white"} />
            ) : (
              currentStatus.status.charAt(0).toUpperCase() +
              currentStatus.status.slice(1)
            )}
          </p>
        </div>
        <button
          onClick={() => updateStatus(email)}
          disabled={currentStatus.status === "Pending"}
        >
          {currentStatus.status === "inactive" || currentStatus.status === "Inactive" ? (
            <img src={correct} alt="" />
          ) : (
            <img src={close} alt="" />
          )}
        </button>
      </div>
      {currentStatus.error.length > 0 && <p>{currentStatus.error}</p>}
    </>
  );
}
