import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import "../styles/OrderFilterComponent.scss";

class OrderFilterComponent extends Component {
  handleClickCurrentOrders() {
    this.props.onFilter("current");
  }

  handleClickPastOrders() {
    this.props.onFilter("past");
  }

  render() {
    const { t } = this.props;
    return (
      <div id="order-filter-component">
        <div id="buttons">
          <span className="title">{t("Your Orders", "Your Orders")}</span>
          <ButtonComponent action={() => this.handleClickCurrentOrders()}>
            {t("Current Orders", "Current Orders")}
          </ButtonComponent>
          <ButtonComponent action={() => this.handleClickPastOrders()}>
            {t("Past Orders", "Past Orders")}
          </ButtonComponent>
        </div>
      </div>
    );
  }
}

export default withTranslation()(OrderFilterComponent);
