const initialState = {
  data: [],
  loading: true
};

export function ConnectReducer(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_DATA':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: [action.payload],
      };
    default:
      return state;
  }
};
