import React from "react";
import "../style/BuyerBoxStyle.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

const BuyerBoxComponent = (props) => {
  return (
    <div className="buyer-box">
      <div
        className="containerImage"
        key={props.buyer.id}
        style={{
          backgroundImage: props.buyer.logo
            ? `url(${props.buyer.logo})`
            : "none",
        }}
        onClick={() => props.history.push(`/buyers/${props.buyer.id}`)}
      >
        <div
          className={`blockBuyer ${props.buyer.logo ? "blockBuyer--bg" : ""}`}
        >
          <Link to={`/buyers/${props.buyer.id}`}>{props.buyer.name}</Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BuyerBoxComponent);
