const SAVE = 'SAVE_CATEGORIES';

export function categoriesReducer (state = [], action) {
    switch (action.type) {
        case SAVE:
            return action.payload;
        default:
            return state;
    }
}
