import React, {Component} from 'react';

import CategoriesInterector from "../../../domain/pages/categories/CategoriesInterector";
import CategoriesService from "../../../data/pages/categories/categoriesService";
import {connect} from "react-redux";
import CategoryBrandsBoxComponent from "../../shared/brandsbox/components/CategoryBrandsBoxComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import MenuComponent from "../../menu/components/MenuComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";


class CategoriesViewComponent extends Component {

    CategoriesInterector = new CategoriesInterector(new CategoriesService().getData());
    render() {
        return (
            <div>
                <MenuComponent />
                <main>
                    <br />
                    <br />

                    { this.props.brandsData.length > 0 ? (
                        <section className="brands centerComponent">
                            <CategoryBrandsBoxComponent brands={this.props.brandsData} />
                        </section>
                    ) : (
                            <LoadingComponent />
                        )
                    }

                </main>

                <FooterComponent/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        brandsData: state.categoriesEntity
    }
}

export default connect(mapStateToProps)(CategoriesViewComponent);
