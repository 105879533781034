import React, { Component } from "react";
import "../style/ConnectionsBlockComponent.scss";
import { withRouter } from "react-router";
import moment from "moment";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import SpinnerComponent from "../../shared/spinner";
import axios from "axios";
import { withTranslation } from "react-i18next";

class ConnectionsBlockComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  formatDate(date) {
    const { i18n } = this.props;
    return moment(date).format(i18n.language === "en" ? "MM/DD" : "DD/MM");
  }

  formatTime(date) {
    const { i18n } = this.props;
    return moment(date).format(i18n.language === "en" ? "hh:mm A" : "HH:mm");
  }

  handleAcceptConnection() {
    const { connection } = this.props;

    this.setState({
      loading: true,
    });

    axios
      .put(`${process.env.REACT_APP_NEW_API_URL}/v1/connections/accept`, {
        connection_id: connection.id,
      })
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });

          if (response.data.data.status.status === "accepted") {
            this.props.popConnection();
          }
        }
      });
  }

  handleDenyConnection() {
    const { connection } = this.props;

    this.setState({
      loading: true,
    });

    axios
      .put(`${process.env.REACT_APP_NEW_API_URL}/v1/connections/decline`, {
        connection_id: connection.id,
      })
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });

          if (response.data.data.status.status === "declined") {
            this.props.popConnection();
          }
        }
      });
  }

  render() {
    const userSType = localStorage.getItem("user_stype");
    const { connection, t } = this.props;
    return (
      <div className={`connections-block-component`}>
        <div className="left">
          <div className={`title`}>
            {userSType === "seller"
              ? connection.buyer.name
              : connection.seller.name}
          </div>
          <div className="buttons">
            {this.state.loading ? (
              <SpinnerComponent />
            ) : (
              <div>
                <ButtonComponent action={() => this.handleAcceptConnection()}>
                  {t("ACCEPT", "ACCEPT")}
                </ButtonComponent>
                <ButtonComponent
                  action={() => this.handleDenyConnection()}
                  className="deny"
                >
                  {t("DENY", "DENY")}
                </ButtonComponent>
              </div>
            )}
          </div>
        </div>
        <div className="right dates">
          <div className="time">
            <div className="clock"></div>
            {this.formatTime(connection.created_at)}
          </div>
          <div className="date">
            <div className="calendar"></div>
            {this.formatDate(connection.created_at)}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ConnectionsBlockComponent));
