export const GetVariationColorStyle = (variation) => {
  let liStyle = {};
  let spanStyle = {};
  let classList = "listVariation";

  if (variation.texture !== null) {
    liStyle = { backgroundImage: `url(${variation.texture.picture_texture})` };
    classList += " texture";
    if (variation.color !== null) {
      spanStyle = {
        borderBottomColor: variation.color.color,
        display: "inline-block",
      };
    }
  } else {
    classList += " color";
    liStyle = {
      backgroundColor:
        variation.color !== null ? variation.color.color : "#000000",
    };
    if (variation.color !== null && variation.color.extra_color) {
      spanStyle = {
        borderBottomColor: variation.color.extra_color,
        display: "inline-block",
      };
    }
  }
  return {
    li: liStyle,
    span: spanStyle,
    classList: classList,
  };
};

export const getCurrentPriceByPriceList = (product, pricelistId: null) => {
  if (product.hasOwnProperty("pricelists") && pricelistId !== 0) {
    const currentPriceList = pricelistId || localStorage.getItem("user.pricelist");
    const pricelistPrices = product.pricelists.find(
      (item) => {
          return Number(item.pricelist.id) === Number(currentPriceList)
        }
    );

    if (pricelistPrices) {
      return {
        retailer_price: {
          amount: pricelistPrices.retailer_price,
          currency: pricelistPrices.pricelist.currency,
          tip: pricelistPrices.pricelist.name,
        },
        wholesale_price: {
          amount: pricelistPrices.wholesale_price,
          currency: pricelistPrices.pricelist.currency,
          tip: pricelistPrices.pricelist.name,
        },
      };
    }
  }

  return {
    wholesale_price: product.wholesale_price || product.wholesalePrice,
    retailer_price: product.retailer_price || product.retailerPrice,
  };
};
