import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './SelectInput.scss';

const SelectInput = ({ title, data, handleFilter, typeOf, clearOptions, setClearOptions, params }) => {
    let dpDown = React.createRef();
    const [checkedItems, setCheckedItems] = useState([]);
    const [refresh, setRefresh] = useState(false);

    function toggleChecked(id) {
        setCheckedItems((prevCheckedItems) => {
            if (prevCheckedItems.includes(id)) {
                return prevCheckedItems.filter((item) => item !== id);
            } else {
                return [...prevCheckedItems, id];
            }
        });
    }

    function applyFilters() {
        handleFilter(checkedItems, typeOf);
        dpDown.current.classList.remove("open");

        if (checkedItems.length !== 0) {
            dpDown.current.classList.add("active")
        } else {
            dpDown.current.classList.remove("active")
        }
    }

    var urlCheck = new URL(window.location.href);
  
    var paramsCheck = new URLSearchParams(urlCheck.search);

    useEffect(() => {
        if(paramsCheck.size === 0 && dpDown.current.classList.contains("active")){
            dpDown.current.classList.remove("active");
            setCheckedItems([]);
        }
    }, [paramsCheck])

    useEffect(() => {
        if(paramsCheck.size === 1 && window.location.href.includes('az=true')){
            setCheckedItems([])
            setRefresh(true)
        }
    }, [window.location.href])

    function openMenu() {
        dpDown.current.classList.toggle("open");

        if (clearOptions) {
            setCheckedItems([]);
            setClearOptions(false);
        }
    }
    function closeMenu(){
        if (dpDown.current.classList.contains('active')) {
            dpDown.current.classList.remove("active");
            setCheckedItems([]);
            setRefresh(true);
        }
    }

    function handleClickOutside(event) {
        if(event === null) {
            return;
        }
    
        if (!dpDown.current || !dpDown.current.contains(event.target)) {
            dpDown.current.classList.remove("open");
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    })

    useEffect(() => {
        if (refresh) {
            handleFilter(checkedItems, typeOf);
            setRefresh(false)
        } else {
            return;
        }
    }, [refresh])

    useEffect(() => {
        if(params){
            setCheckedItems(params)
            handleFilter(params, typeOf);
            dpDown.current.classList.remove("open");

            if (params.length !== 0) {
                dpDown.current.classList.add("active")
            } else {
                dpDown.current.classList.remove("active")
            }
        }
    }, [])

    return (
        <div className='btnDropDown' ref={dpDown}>
            <div className='label' onClick={() => openMenu()}>
                <span>{title}</span>
                <span onClick={(e) => {
                    closeMenu()
                    e.stopPropagation()
                    }}>
                    <svg className='xMark' viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    <svg className='settings' viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" /></svg>
                </span>
            </div>
            <ul className='list-items'>
                <li className='titleSelection'>
                    <span className='selectionTitle'>BRANDS {title}</span>
                    <span className='clearBtn' onClick={() => {
                        setCheckedItems([]);
                        setRefresh(true);
                    }}>CLEAR</span>
                </li>

                {data.map((e) => {
                    const isChecked = checkedItems.includes(e.id);
                    
                    return (
                        <li
                            className={`item ${isChecked ? 'checked' : ''}`}
                            key={e.id}
                            onClick={() => toggleChecked(e.id)}
                        >
                            <span className='checkbox'>
                                <svg className='check-icon' viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            </span>
                            <span className='item-text'>{e.name}</span>
                        </li>
                    )
                })}

                <span className='traco'></span>
                <button type='button' onClick={() => {
                    applyFilters()
                }}>
                    apply
                </button>
            </ul>
        </div>
    )
};

export default SelectInput;
