import React, { Component } from "react";
import "../style/FilterCollectionsStyle.scss";
import "../style/FilterBuyersComponent.scss";

import NavBrandLocationsComponent from "../../shared/navBrands/components/NavBrandLocationsComponent";
import { withRouter } from "react-router";

class FilterBuyersComponent extends Component {
  render() {
    return (
      <div className="filterCollection filterBuyersComponent">
        <NavBrandLocationsComponent locations={this.props.locations} />
      </div>
    );
  }
}

export default withRouter(FilterBuyersComponent);
