import React, { Component } from 'react';

import "../style/LightBoxStyle.scss"

export default class LightBoxComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        }
    }

    render(){
        const {children, handleClick, handleEventClose, isOpen, extraClass} = this.props;

        return (
            <div>
                { isOpen &&
                    <div className={ "lightBox " + ( extraClass ? extraClass : "") }>
                        <div className="bgLightBox" onClick={() => handleClick()} />
                        <div className="containerLightBox">
                            <span className="closed" onClick={() => handleEventClose()}>X</span>
                            {children}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
