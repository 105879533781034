import React, { Component } from 'react';
import {connect} from "react-redux";

import '../style/ourRetailers.scss';

import RetailersService from "../../../../../data/pages/retailers/retailersService";
import RetailerInterector from "../../../../../domain/pages/retailers/RetailersInterector";
import LoadingComponent from "../../../../shared/loading/components/LoadingComponent";
import SlideComponent from "../../../shared/slide/components/slideComponent";

import Retailer from "./Retailer"

class OurRetailersComponent extends Component {
    componentDidMount() {
      new RetailerInterector(new RetailersService().getData());
    }

    render() {
        return (
          <div id='our-retailers-items'>
            { this.props.retailersData.length > 0 ? (
                <SlideComponent
                    dots={false}
                    infinite={false}
                    speed="500"
                    slidetoshow={4}
                    slideScroll={4}
                    slidesPerRow={2}
                    extraSettings={
                      {responsive: [
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          }
                        },
                      ]}
                    }
                >
              {this.props.retailersData.map(retailer => {
                return (
                  <Retailer key={retailer.name} retailer={retailer} />
                );
              })}
              </SlideComponent>
            ) : (
                    <LoadingComponent />
                )
            }
          </div>

        );
    }
}
function mapStateToProps(state) {
    return {
        retailersData: state.retailersEntity
    }
}

export default connect(mapStateToProps)(OurRetailersComponent)
