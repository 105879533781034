import { createStore, combineReducers } from "redux";
import { theBrandReducer } from "./pages/thebrand/TheBrandReducer";
import { brandsReducer } from "./pages/brands/brandsReducer";
import { buyersReducer, locationsReducer } from "./pages/buyers/buyersReducer";
import { retailersReducer } from "./pages/retailers/retailersReducer";
import { landingBrandsReducer } from "./pages/landing/landingBrandsReducer";
import { collectionReducer } from "./pages/collection/CollectionReducer";
import { productReducer } from "./pages/products/ProductReducer";
import { cartReducer } from "./pages/cart/CartReducer";
import { orderReducer } from "./pages/order/OrderReducer";
import { infoProductReducer } from "./pages/inforproduct/InfoProductReducer";
import { categoriesReducer } from "./pages/categories/categoriesReducer";
import { showNotificationItemAddedReducer } from "./pages/products/showNotificationItemAddedReducer";
import { ConnectReducer } from "./pages/connect/ConnectReducer";

const rootReducer = combineReducers({
  homeEntity: theBrandReducer,
  landingBrandsEntity: landingBrandsReducer,
  retailersEntity: retailersReducer,
  brandsEntity: brandsReducer,
  locationsEntity: locationsReducer,
  buyersEntity: buyersReducer,
  collectionEntity: collectionReducer,
  productsEntity: productReducer,
  cartEntity: cartReducer,
  orderEntity: orderReducer,
  categoriesEntity: categoriesReducer,
  showNotificationItemAdded: showNotificationItemAddedReducer,
  infoProductEntity: infoProductReducer,
  connectEntity: ConnectReducer
});

export default createStore(
  rootReducer,
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
