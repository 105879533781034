import Axios from "axios";
import React from "react";
import MenuComponent from "../../../menu/components/MenuComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import FormAdress from "./formAdress/formAdress";
import FormCompany from "./formCompany/formCompany";
import FormManagement from "./formManagement/formManagement";
import FormPersonal from "./formPersonal/formPersonal";
import "./profileStyle.scss";
import Loading from "../../../../components/Loading";

export default function ProfileRoute() {
  const [step, setStep] = React.useState(false);
  const [dataAddress, setDataAddress] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      let responseMe = await Axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/auth/me`
      );
      let responseDataUser = await Axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/auth/user-data`
      );
      let locationsData = await Axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/locations`
      );
      let dataDomains = await Axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/v2/profile/domains`
      );

      try {
        const response = await Promise.all([
          responseMe,
          responseDataUser,
          locationsData,
          dataDomains,
        ]);
        setDataAddress([
          response[0].data,
          response[1].data,
          response[2].data.data,
          response[3].data.data,
        ]);
        setStep(0);
        localStorage.setItem("buyer_id", response[1].data.buyer.id);

        localStorage.setItem("data0", JSON.stringify(response[0]));
        localStorage.setItem("data1", JSON.stringify(response[1]));
      } catch {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <section className="container-profile">
      <MenuComponent />
      <div className="container-profile__box">
        <h1>My Profile</h1>

        <div className="container-profile__box-steps">
          <button
            type="button"
            className={`${
              step === 0 && "container-profile__box-steps--active"
            }`}
            onClick={() => {
              setStep(0);
            }}
            disabled={loading}
          >
            Personal profile
          </button>
          <button
            type="button"
            className={`${
              step === 1 && "container-profile__box-steps--active"
            }`}
            onClick={() => setStep(1)}
            disabled={loading}
          >
            Company profile
          </button>
          <button
            type="button"
            className={`${
              step === 2 && "container-profile__box-steps--active"
            }`}
            onClick={() => setStep(2)}
            disabled={loading}
          >
            Company Address
          </button>
          <button
            type="button"
            className={`${
              step === 3 && "container-profile__box-steps--active"
            }`}
            onClick={() => setStep(3)}
            disabled={loading}
          >
            User management
          </button>
        </div>

        {loading && (
          <div className="loader-component">
            <Loading borderColor={"black"} borderTopColor={"white"} />
          </div>
        )}

        {step === 0 && <FormPersonal data={dataAddress[0]} />}
        {step === 1 && <FormCompany data={[dataAddress[1], dataAddress[2]]} />}
        {step === 2 && <FormAdress data={dataAddress[1]} />}
        {step === 3 && (
          <FormManagement data={[dataAddress[1], dataAddress[3]]} />
        )}
      </div>

      <FooterComponent />
    </section>
  );
}
