import { save } from "./InfoProductActions";
import store from "../../store"
import InfoProductEntity from "../../../domain/pages/infoproduct/infoProductEntity";
import axios from "axios";

export default class InfoProductService {

    headersNewApi = {
        Authorization: `Bearer ${window.localStorage.getItem("new_access_token")}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    dispatch = store.dispatch;

    async getData(id) {
        let obj = [];
        try {
            let result = await fetch(`${process.env.REACT_APP_NEW_API_URL}/v2/products/${id}`, { headers: this.headersNewApi });
            let response = await result.json();

            obj.push(response.data);
            this.entityMount(obj);

            return result
        } catch (err) {
            if(err.status === 401) {
                window.localStorage.clear();
                window.location.href = "/";
            }

            window.console.log(`[Erro] ${err}`);

            return Promise.reject(400);
        }
    }

    async getVariations(id) {
        try {
            let result = await fetch(`${process.env.REACT_APP_NEW_API_URL}/v1/products/${id}/variations`, { headers: this.headersNewApi });
            let response = await result.json();

            return response.data
        } catch (err) {
            window.console.log(`[Erro] ${err}`);
            return Promise.reject(400);
        }
    }

    entityMount(response) {
        let entityInfoProduct = [];

        response.forEach(item => {
            entityInfoProduct.push(new InfoProductEntity(item));
        });

        this.dispatchSave(entityInfoProduct)

    }

    dispatchSave(entityList) {
        this.dispatch(save(entityList));
    }
}
