import Axios from "axios";

export default class OrderService {
  getOrders({ page = 1, past = false } = {}) {
    return Axios.get(`${process.env.REACT_APP_NEW_API_URL}/v2/orders?page=${page}${past ? '&past=1' : ''}`);
  }

  sendMessage(orderId, message) {
    return Axios.post(`${process.env.REACT_APP_NEW_API_URL}/v2/orders/${orderId}/messages`, { message });
  }

  readMessages(orderId) {
    return Axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/orders/${orderId}/messages`);
  }

  updateStatus(orderId, status) {
    return Axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/orders/${orderId}/change-status`, { status });
  }
}
