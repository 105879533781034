import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelopeOpen,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PatternViewComponent from "./PatternViewComponent";

import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import SegmentService from "../../../data/pages/segments/SegmentService";
import i18n from "../../../i18n";
import AuthenticationService from "../../../services/AuthenticationService";
import ConnectedCollaboration from "../../connectedcollaboration/components/ConnectedCollaboration";
import LandingPageHKHMain from "../../hkh/views/components/LandingPageHKHMain";
import LandingComponent from "../../landing/views/components/LandingComponent";
import LandingPageSegments from "../../landingsegments/views/components/LandingPageSegments";
import CreateMyOwnListComponent from "../../shared/createmyownlist/components/CreateMyOnListComponent";
import InfoProduct from "../../shared/infoProduct/components/infoProductComponent";
import LoadingComponent from "../../shared/loading/components/LoadingComponent";
import ToTheWorld from "../../totheworld/components/ToTheWorld";
import WhoWeAre from "../../whoweare/views/components/WhoWeAre";
import BrandPageViewComponent from "./BrandPage/BrandPageViewComponent";
import BrandsViewComponent from "./BrandsViewComponent";
import BuyerPageViewComponent from "./BuyerPage/BuyerPageViewComponent";
import BuyersViewComponent from "./BuyersViewComponent";
import CartViewComponent from "./CartViewComponent";
import CategoriesViewComponent from "./CategoriesViewComponent";
import ConnectionsPage from "./ConnectionsPage/ConnectionsPage";
import HomeSugestedViewComponent from "./HomeSugestedViewComponent";
import MessagesViewComponent from "./MessagesPage/MessagesViewComponent";
import OrderViewComponent from "./OrderViewComponent";
import PrivateRoute from "./PrivateRouteComponent";
import ProductsViewComponents from "./ProductsViewComponents";
import ProfileRoute from "./Profile/Profile";
import PublicRoute from "./PublicRouteComponent";
import mixpanel from "mixpanel-browser";
import RecoveryViewComponent from "./RecoveryViewComponent";
import RegisterViewComponent from "./RegisterViewComponent";
import NewPassRoute from "./RenewPassword";
import VincularEventSegmentViewComponent from "./VincularEventSegmentViewComponent";

class RouterSwitchComponent extends Component {
  constructor() {
    super();
    library.add(faPlusCircle, faTimesCircle, faEnvelopeOpen);

    this.state = {
      segments: null,
      loading: true,
      xAccount: null,
    };
  }

  async componentDidMount() {
    localStorage.setItem("X-Account-Host", window.location.host);

    const response = await axios.get(
      `${process.env.REACT_APP_NEW_API_URL}/v1/x-account`
    );

    if (
      response.status !== 200 ||
      !response.data.data ||
      !response.data.data.hash
    ) {
      Swal.fire({
        title: "Error!",
        html: "An error occurred. Please try again later.",
        confirmButtonColor: "#000000",
      });

      setTimeout(() => {
        window.location.reload();
      }, 30000);

      return;
    }

    const xAccountHash = response.data.data.hash;
    const xAccountPoweredByBlanc = response.data.data.poweredByBlanc;

    const xAccountTitle = response.data.data.title;
    const xAccountLogo = response.data.data.logo;
    const xAccountStyle = response.data.data.style;
    const xAccountLanguageCode = response.data.data.language_code;
    const xAccountName = response.data.data.name;
    const rdstation_token = response.data.data.rdstation_token;
    const gtm_id_main = response.data.data.gtm_id_main;
    const mixPanelToken = response.data.data.mixpanel_token;
    const ga_id = response.data.data.ga_id

    i18n.changeLanguage(xAccountLanguageCode);

    localStorage.setItem("xAccountHash", xAccountHash);
    localStorage.setItem(
      "xAccountPoweredByBlanc",
      xAccountPoweredByBlanc ? 1 : 0
    );
    localStorage.setItem("xAccountTitle", xAccountTitle);
    localStorage.setItem("xAccountName", xAccountName);
    localStorage.setItem("xAccountLogo", xAccountLogo);
    localStorage.setItem("xAccountStyle", xAccountStyle);
    localStorage.setItem("xAccountLanguageCode", xAccountLanguageCode);
    localStorage.setItem("rdstation_token", rdstation_token);
    localStorage.setItem("gtm_id_main", gtm_id_main);
    localStorage.setItem("mixPanelToken", mixPanelToken);
    localStorage.setItem("ga_id", ga_id);

    if ([true, 'true'].includes(process.env.REACT_APP_MIXPANEL_ENABLED) && mixPanelToken && !mixpanel.__loaded) {
      mixpanel.init(mixPanelToken)
    }

    this.setState({
      xAccount: response.data.data,
    });

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.interceptors.request.use((request) => {
      request.headers["X-Account-Hash"] = xAccountHash;
      request.headers["Access-Control-Allow-Origin"] = "*";

      let accessToken = localStorage.getItem("access_token");
      let newAccessToken = localStorage.getItem("new_access_token");

      if (accessToken) {
        request.headers["Authorization"] = `Bearer ${accessToken}`;
        request.headers["Accept-Language"] = xAccountLanguageCode;
        request.headers["Content-Language"] = xAccountLanguageCode;
      }

      if (request.url.includes(process.env.REACT_APP_NEW_API_URL)) {
        let token_ref = localStorage.getItem("temp_token");

        if (token_ref) {
          request.headers["Authorization"] = `Bearer ${token_ref}`;
          request.headers["Accept-Language"] = null;
          request.headers["Content-Language"] = null;
        } else {
          request.headers["Authorization"] = `Bearer ${newAccessToken}`;
          request.headers["Accept-Language"] = null;
          request.headers["Content-Language"] = null;
        }
      }

      return request;
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("temp_token", token);
      AuthenticationService.loginByToken();
    } else {
      AuthenticationService.manageToken();
    }

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error.response.data.error !== "invalid_grant") {
            if (401 === error.response.status) {
              // window.localStorage.clear();
              // const params = new URLSearchParams(window.location.search);
              // let pathname = window.location.pathname;
              // let params_string = "";
              // let has_next = params.get("next", null);
              // if (pathname && has_next === null) {
              //   for (var key of params.keys()) {
              //     let value = params.get(key);
              //     if (key !== "next") {
              //       params_string += `&${key}=${value}`;
              //     }
              //   }
              // }
              // window.location.href = `?next=${pathname}${params_string}`;
            }
          }
        }
        return Promise.reject(error);
      }
    );

    try {
      let segmentService = new SegmentService();
      let segments = await segmentService.get();

      this.setState({
        segments: segments.results,
        loading: false,
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        html: "An error occurred. Please try again later.",
        confirmButtonColor: "#000000",
      });

      setTimeout(() => {
        window.location.reload();
      }, 30000);
    }
  }

  renderSegmentsRoutes() {
    const rotas = this.state.segments.map((segment) => {
      if (segment.event) {
        if (!segment.is_active_event) {
          return (
            <Redirect from={`/${segment.slug}`} key={segment.slug} to="/" />
          );
        }

        return (
          <Route
            key={segment.slug}
            path={`/${segment.slug}`}
            exact
            render={() => {
              if (window.localStorage.getItem("user")) {
                if (window.localStorage.getItem("user.event_segments")) {
                  const possuiEventoSegment =
                    JSON.parse(
                      window.localStorage.getItem("user.event_segments")
                    ).filter((item) => item === segment.slug).length > 0;

                  if (possuiEventoSegment) {
                    return (
                      <Route
                        key={segment.slug}
                        path={"/" + segment.slug}
                        component={BrandsViewComponent}
                      />
                    );
                  }
                }

                return <Redirect to={`/vincular/${segment.slug}`} />;
              }

              // return <LandingComponent evento={segment} />;
            }}
          />
        );
      } else {
        return (
          <Route
            key={segment.slug}
            path={"/" + segment.slug}
            component={BrandsViewComponent}
          />
        );
      }
    });

    return rotas;
  }

  isBuyer() {
    return window.localStorage.getItem("user_stype") === "buyer";
  }

  render() {
    const accountTitle = localStorage.getItem("xAccountTitle");
    const accountStyle = localStorage.getItem("xAccountStyle");

    return (
      <div>
        <React.Suspense fallback={null}>
          <Helmet>
            <title>{accountTitle || "BLANC"}</title>
            <style>{accountStyle}</style>
          </Helmet>
          {!this.state.loading && (
            <Switch>
              <Route
                path="/"
                exact={true}
                render={() =>
                  window.localStorage.getItem("user") ? (
                    window.localStorage.getItem("user_type") === "buyer" ? (
                      <Redirect to="/brands" />
                    ) : (
                      <Redirect
                        to={`/${window.localStorage.getItem("seller_slug")}`}
                      />
                    )
                  ) : (
                    <LandingComponent />
                  )
                }
              />
              <PublicRoute
                path="/brands/:segment_slug?"
                exact={true}
                component={BrandsViewComponent}
              />
              <PublicRoute
                path="/define-password"
                exact={true}
                component={NewPassRoute}
              />


              <PublicRoute
                path="/brands"
                exact={true}
                component={
                  window.localStorage.getItem("user_stype") === "seller"
                    ? HomeSugestedViewComponent
                    : BrandsViewComponent
                }
              />
              <PublicRoute
                path="/register"
                exact={true}
                component={RegisterViewComponent}
              />
              <Route
                path="/recovery/:token"
                exact={true}
                component={RecoveryViewComponent}
              />
              <PublicRoute
                path="/:slug/register/"
                component={BrandPageViewComponent}
              />
              <PublicRoute path="/whoweare" exact={true} component={WhoWeAre} />
              <PublicRoute
                path="/hkhparis"
                exact={true}
                component={LandingPageHKHMain}
              />
              <PublicRoute
                path="/project/:slug"
                exact={true}
                component={LandingPageSegments}
              />
              <PublicRoute
                path="/connectedcollaboration"
                exact={true}
                component={ConnectedCollaboration}
              />
              <PublicRoute
                path="/totheworld"
                exact={true}
                component={ToTheWorld}
              />

              <PrivateRoute
                path="/newhome"
                exact={true}
                component={BrandsViewComponent}
              />
              <PrivateRoute
                path="/categories"
                exact={true}
                component={CategoriesViewComponent}
              />
              {this.renderSegmentsRoutes()}
              <PrivateRoute
                path="/connections"
                exact={true}
                component={ConnectionsPage}
              />
              {!this.isBuyer() && (
                <PrivateRoute
                  path="/buyers"
                  exact={true}
                  component={BuyersViewComponent}
                />
              )}
              {!this.isBuyer() && (
                <PrivateRoute
                  path="/buyers/:id"
                  exact={true}
                  component={BuyerPageViewComponent}
                />
              )}
              <PrivateRoute
                path="/vincular/:segment_slug"
                exact={true}
                component={VincularEventSegmentViewComponent}
              />
              {this.isBuyer() && (
                <PrivateRoute
                  path="/profile"
                  exact={true}
                  component={ProfileRoute}
                />
              )}
              <PrivateRoute
                path="/pattern"
                exact={true}
                component={PatternViewComponent}
              />
              <PrivateRoute
                path="/products"
                exact={true}
                component={ProductsViewComponents}
              />
              <PrivateRoute
                path="/product/:id"
                exact={true}
                component={InfoProduct}
              />
              <PrivateRoute
                path="/cart/:cartId?"
                exact={true}
                component={CartViewComponent}
              />
              <PrivateRoute
                path="/messages/:subjectId?"
                exact={true}
                component={MessagesViewComponent}
              />
              <PrivateRoute
                path="/order"
                exact={true}
                component={OrderViewComponent}
              />
              <PrivateRoute
                path="/order/:id"
                exact={true}
                component={OrderViewComponent}
              />
              <PrivateRoute
                path="/createmyownlist/:id"
                exact={true}
                component={CreateMyOwnListComponent}
              />
              <PrivateRoute
                path="/:slug/collections/"
                component={BrandPageViewComponent}
              />

              <PublicRoute path="/:slug/" component={BrandPageViewComponent} />
            </Switch>
          )}
          {this.state.loading && <LoadingComponent />}
        </React.Suspense>
      </div>
    );
  }
}

export default RouterSwitchComponent;
