import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
import Axios from "axios";
import React from "react";
import "./styles.scss";

export default function SelectComponent(props) {
  const { value, changeValue, multiple, title, values, wd, valueType } = props;
  const [valuee, setValuee] = React.useState([]);

  React.useEffect(() => {
    if (!value) return;

    setValuee([...value]);
  }, []);

  async function sendDomains() {
    const data = {
      domains: valuee,
    };

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_NEW_API_URL}/v2/profile/domains`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("new_access_token")}`,
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    };
    
    try {
      await Axios(config);
    } catch (error) {
    } finally {
    }
  }

  return (
    <div className="select" style={{ width: `${wd}` }}>
      <label htmlFor="select_item">{title}</label>
      <Select
        multiple={multiple}
        name="select_item"
        className="select-item"
        value={valuee}
        onChange={(e) => {
          setValuee([...e.target.value]);
          {valueType === "domains" && sendDomains();}
          {valueType !== "domains" && changeValue(valueType, [[...e.target.value]]);}
        }}
        input={<OutlinedInput name="age" id="outlined-age-simple" />}
      >
        {values &&
          values.map((el) => {
            if (el.continent !== null) {
              return (
                <MenuItem
                  key={el.id}
                  value={valueType === "domains" ? el.value : el.id}
                >
                  {el.name}
                </MenuItem>
              );
            }
          })}
      </Select>
    </div>
  );
}
