import React, { Component } from "react";
import "../style/WhoWeAreHero.scss";

class WhoWeAreHero extends Component {
  render() {
    const defaultTitle = "*DESIGNED FOR BUYERS & BRANDS";
    const defaultDescription =
      "BLANC is a digital B2B platform built for the future of the fashion wholesale industry. We enable brands to connect with global retailers and deliver seamless orders, facilitating sustainable growth for their businesses.";
    const { title, description, image } = this.props;
    return (
      <div id="who-we-are-hero">
        <div className="left">
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: title || defaultTitle }}
          />
          <div className="description">{description || defaultDescription}</div>
        </div>
        <div className="right">
          <div className="image-wrapper">
            <img className="main-image" src={image} alt="main" />
          </div>
        </div>
      </div>
    );
  }
}

export default WhoWeAreHero;
