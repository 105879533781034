import React from 'react';
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';


function NavSegmentComponent(props) {

    const { segments, t } = props;

    return (
        <div className="contentFilter">
            <p>{t('SEGMENT', 'SEGMENT')}</p>

            <ul>
                {segments.map((item, index) => {
                    return (
                        <li
                            onClick={() => {
                                let url = props.endPoint || '';
                                const segmentParam = `segments=${item.id}`;
 
                                if (url.includes('segments=')) {
                                    url = url.replace(/segments=[^&]*/, segmentParam);
                                } else {
                                    if (url.includes('?')) {
                                        url += `&${segmentParam}`;
                                    } else {
                                        url += `?${segmentParam}`;
                                    }
                                }
                                props.changeSegment(url);
                            }}
                            key={index}>
                            {item.name}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

NavSegmentComponent.propTypes = {
    clearFilters: PropTypes.func.isRequired
};
export default withTranslation()(NavSegmentComponent);