
import React, { Component } from 'react';
import "./WelcomeModal.scss";

import LightboxHalfScreenComponent from "../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import axios from "axios";


class WelcomeModal extends Component {
    constructor(props){
        super(props);
        this.state = {

        };

    }
    componentDidMount() {
      localStorage.setItem("show_welcome_msg", false);
      axios.post(`api/user/inc_msg_viewed/`, {view: 1})
    }

    render() {
        return (
            <LightboxHalfScreenComponent extraClass="welcome-lh-modal" close={this.props.close}>
                <div id="welcome-message-modal">
                  <h3 className="modal-title" id="modal-title">WELCOME TO BLANCFASHION.COM!</h3>
                  <div className="text-container">
                    <p className="sub-title">
                    Here are some tips for your online wholesale experience:
                    </p>
                    <ol>
                      <li>Explore Brand Pages for exclusive content about each brand;</li>
                      <li>The complete line sheets are in the section <b>BUY</b>, inside a Brand Page - or you can
browse products by Segment and Category.</li>
                      <li><b>Future Delivery</b> collections are made on demand and some brands also have <b>In
Stock</b> products available for immediate delivery.</li>
                      <li>Add multiple carts simultaneously, at the end your pre-Orders will be sent
individually to each brand.</li>
                    </ol>
                    <p></p>
                    <p><b className="lblue">Good to know:</b></p>
                    <p>- Pre-Orders will be submitted to the brand&#39;s approval, which can take up to 3 business days. After approved, you&#39;ll receive the Purchase Order and further instructions by e-mail.</p>
                    <p>- Our global partner is UPS, however you can always use your own logistic partner for shipment.</p>
                    <p>- Follow your order status at your ORDERS page and track the actual status until it’s delivered to you.</p>
                    <p>- If you need further information or any assistance, please get in touch with us through the chat box (on your bottom right) or sending an email to info@blancfashion.com.</p>
                  </div>
                  <p></p>
                  <center className="enjoy"><b>Enjoy!</b></center>
                  <p></p>

                  <div className="modal-footer ng-scope" style={{display: 'flex', justifyContent: 'center'}}>
                    <button onClick={this.props.close} type="button" className="btn btn-warning">Close</button>
                  </div>
                </div>
            </LightboxHalfScreenComponent>
        );
    }

}

export default WelcomeModal;
