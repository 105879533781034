import React from 'react';
import Button from '../../button/components/ButtonComponent';
import axios from 'axios';
import {withTranslation} from 'react-i18next';

import '../style/Subscribe.scss';
import {t} from 'i18next';
import RdSendForm from '../../../../../services/RdSendForm';

class Subscribe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            companyType: '',
            success: false,
            error: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    handleSelectChange(event) {
        this.setState({
            companyType: event.target.value,
        });
    }

    getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function (el) {
            let [key, value] = el.split('=');
            cookie[key.trim()] = value;
        });
        return cookie[cookieName];
    }

    handleSubmit() {
        if (this.state.companyType === '') {
            return;
        }

        const rdtrkCookieValue = this.getCookie('rdtrk') || null;
        const trfsrcCookieValue = this.getCookie('__trf.src') || null;

        const companyTypeMap = {
            b: 'Retailer',
            s: 'Brand',
            r: 'Showroom',
            d: 'Distributor',
        };

        const companyType = companyTypeMap[this.state.companyType] || '';

        new RdSendForm.newsletter({
            mail: this.state.email,
            ctype: companyType,
            client_tracking_id: rdtrkCookieValue,
            traffic_source: trfsrcCookieValue,
        });

        axios
            .post(`api/maillist/`, {
                email: this.state.email,
                maillist: this.state.companyType,
            })
            .then((response) => {
                this.setState({success: true, error: false});
            })
            .catch((error) => {
                this.setState({error: true});
            });
    }

    render() {
        return (
            <>
                <section id='subscribe'>
                    {!this.state.success ? (
                        <>
                            <p className='description'>{this.props.text}</p>

                            <div className='form'>
                                <input
                                    className='subscribeInput'
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t('ENTER YOUR EMAIL ADDRESS', 'ENTER YOUR EMAIL ADDRESS')}
                                />
                                {this.state.error && (
                                    <span className='error-message'>
                                        {this.props.t('Email required', 'Email required')}
                                    </span>
                                )}
                                <select
                                    value={this.state.companyType}
                                    name='companyType'
                                    onChange={this.handleSelectChange}
                                    style={{
                                        marginBottom: '1rem',
                                        border: '1px solid #ccc',
                                        padding: '.5rem',
                                        borderRadius: '5px',
                                    }}>
                                    <option value='' selected disabled>
                                        {t('Who are you?', 'Who are you?')}
                                    </option>
                                    <option value='b'>{t('Retailer', 'Retailer')}</option>
                                    <option value='s'>{t('Brand', 'Brand')}</option>
                                    <option value='r'>{t('Showroom', 'Showroom')}</option>
                                    <option value='d'>{t('Distributor', 'Distributor')}</option>
                                </select>
                                <Button
                                    onClick={this.handleSubmit}
                                    size='large'
                                    text={this.props.t('SUBMIT', 'SUBMIT')}
                                />
                            </div>
                        </>
                    ) : (
                        <div className='success-message'>
                            {this.props.t('THANK YOU FOR SUBSCRIBING', 'THANK YOU FOR SUBSCRIBING')}!
                            <div className='small-message'>
                                {this.props.t('We will be in touch', 'We will be in touch')}.
                            </div>
                        </div>
                    )}
                </section>
            </>
        );
    }
}

export default withTranslation()(Subscribe);
