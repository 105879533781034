import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";

import "../style/ButtonStyle.scss";

class ButtonComponent extends Component {
  goHref(url) {
    window.open(`${url}`, "_blank");
  }

  render() {
    const { children, className, action, type, linkTo } = this.props;
    if (type === "link") {
      return (
        <button
          onClick={() => this.goHref(action)}
          className={`button ${className || ""}`}
        >
          {" "}
          {children}
        </button>
      );
    } else if (type === "navLink") {
      return (
        <button onClick={() => this.props.history.push(linkTo)} className={`button ${className || ""}`} disabled={this.props.disabled}>
          {" "}
          {children}
        </button>
      );
    } else {
      return (
        <button
          onClick={() => action()}
          className={`button ${className || ""}`}
          disabled={this.props.disabled}
        >
          {" "}
          {children}
        </button>
      );
    }
  }
}

export default withTranslation()(withRouter(ButtonComponent));
