import React, { Component } from 'react';
import "../style/showcase.scss"
import SliderComponent from "../../Slider/components/SliderComponent";
import isMobile from 'is-mobile';

class ShowcaseComponent extends Component {
    componentDidMount() { setTimeout(() => { window.dispatchEvent(new Event('resize')) }, 0); }

    renderYoutubeVideo(images) {
        const image = images.length > 0 ? images[0] : images;
        return <iframe width="560" style={{ height: 'calc(65vw)' }} src={image.video_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="youtube-container youtube-container--slider"></iframe>;
    }

    renderImage(image, imageOrientation) {
        if (image.video_url) {
            return this.renderYoutubeVideo(image);
        }

        return !image.featured_image ?
            imageOrientation === "portrait" ? (
                <img key={1} src={`${image.portrait_image}`} alt="1" className="showcase-image--portrait" />
            ) : (
                <img key={2} src={`${image.landscape_image}`} alt="2" className="showcase-image--landscape" />
            ) : (
                <img key={3} src={`${image.featured_image}`} alt="1" className="showcase-image--featured" />
            );
    }

    renderImages(images, imageOrientation) {
        return !images[0].featured_image ?
            imageOrientation === "portrait" ? (
                <img src={`${images[0].portrait_image}`} alt="1" className="showcase-image--portrait" />
            ) : (
                <img src={`${images[0].landscape_image}`} alt="2" className="showcase-image--landscape" />
            ) : (
                <img src={`${images[0].featured_image}`} alt="1" className="showcase-image--featured" />
            )
    }

    renderUniqueImages(images, imageOrientation) {
        if (images[0].video_url) {
            return this.renderYoutubeVideo(images);
        }

        return this.renderImages(images, imageOrientation);
    }

    render() {
        const { images, imageOrientation, markChange } = this.props;

        if (images === undefined || images.length === 0) { return (<div>&nbsp;</div>) }

        return (
            <div id="showcase" className={`${markChange ? "border-marker" : ""}`}>
                {
                    images.length > 1 ? (
                        <SliderComponent
                            dots={true}
                            arrow
                            infinite={false}
                            speed="500"
                            slideScroll={1}
                            slidetoshow={1}
                            slidesPerRow={1}
                            isMobile={isMobile()}
                            activeSlide={true}>
                            {images.map((image, i) => {
                                return (
                                    // <div key={i} style={{height: '100%'}}>
                                    this.renderImage(image, imageOrientation)
                                    // </div>
                                )
                            })}
                        </SliderComponent>
                    ) : (
                        <div>
                            {this.renderUniqueImages(images, imageOrientation)}
                        </div>
                    )}

            </div>
        );
    }
}

export default ShowcaseComponent;
