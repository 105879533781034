import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../style/Breadcomb.scss";

class Breadcomb extends Component {
  renderLinks() {
    return this.props.links.map((link, index) => {
      if (!link) {
        return null;
      }

      if (link.link) {
        return this.renderLink(link, index);
      }

      return this.renderText(link, index);
    });
  }

  renderLink(link, index) {
    return (
      <li key={index}>
        <Link to={link.to}>{link.text}</Link> <span className="arrow">{'>'}</span>
      </li>
    );
  }

  renderText(link, index) {
    return (
      <li key={index}>
        {link.text} <span className="arrow">{'>'}</span>
      </li>
    );
  }

  render() {
    return (
      <div className={`breadcomb-component ${this.props.className || ''}`}>
        <ul>{this.renderLinks()}</ul>
      </div>
    );
  }
}

export default Breadcomb;
