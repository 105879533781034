import React, { Component } from "react";
import "../../styles/ConnectionsPage/ConnectionBox.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class ConnectionBox extends Component {
  renderLogoUrl() {
    const { connection } = this.props;
    if (this.props.type === "seller") {
      return (
        connection.seller.logo_image_thumbnail || connection.seller.logo_image
      );
    }

    return connection.buyer.logo;
  }

  renderLink() {
    const { connection } = this.props;
    return this.props.type === "seller"
      ? connection.seller.slug
      : `/buyers/${connection.buyer.id}`;
  }

  handleClick() {
    return this.props.history.push(this.renderLink());
  }

  render() {
    const { connection } = this.props;
    return (
      <div className="connection-box">
        <div
          className="containerImage"
          key={
            this.props.type === "seller"
              ? connection.seller.id
              : connection.buyer.id
          }
          style={{
            backgroundImage: this.renderLogoUrl()
              ? `url(${this.renderLogoUrl()})`
              : "none",
          }}
          onClick={() => this.handleClick()}
        >
          <div
            className={`blockBuyer ${
              this.renderLogoUrl() ? "blockBuyer--bg" : ""
            } ${this.props.type !== "seller" ? 'blockBuyer--buyer' : ''}`}
          >
            <Link to={this.renderLink()}>
              {this.props.type === "seller"
                ? connection.seller.name
                : connection.buyer.name}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConnectionBox);
