import React, {useEffect, useState} from 'react';
import BrandsBoxComponent from '../../shared/brandsbox/components/BrandsBoxComponent';
import FooterComponent from '../../shared/footer/components/FooterComponent';
import MenuComponent from '../../menu/components/MenuComponent';
import LoadingComponent from '../../shared/loading/components/LoadingComponent';
import JumboTitleComponent from '../../shared/jumbotitle/components/JumboTitleComponent';
import BrandsViewFilter from './BrandsViewFilter';
import '../styles/BrandsViewComponent.scss';
import WelcomeModal from '../../welcomemodal/WelcomeModal';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import NavbarComponent from '../../landing/shared/navbar/components/NavbarComponent';
import LoginComponent from '../../shared/Login/components/LoginComponent';
import LightBoxComponent from '../../shared/lightbox/components/LightBoxComponent';

const BrandsViewComponent = () => {
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
    const [brandsData, setBrandsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [segmentData, setSegmentData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [showroomData, setShowroomData] = useState([]);
    const [noDataSearch, setNoDataSearch] = useState(false);
    const [SeeAllBrands, setSeeAllBrands] = useState(false);
    const [dataDisplay, setDataDisplay] = useState([]);
    const [searchTermValue, setSearchTermValue] = useState('');
    const [segments, setSegments] = useState([]);
    const [showroom, setShowroom] = useState([]);
    const [categories, setCategories] = useState([]);
    const [seachFilter, setSearchFilter] = useState(false);
    const [verifyer, setVerifyer] = useState(false);
    const [newBrandsData, setNewBrandsData] = useState([]);
    const [featuredBrandsData, setFeaturedBrandsData] = useState([]);
    const [recentlyData, setRecentlyData] = useState([]);
    const [noCollection, setNoCollection] = useState([]);
    const [collectionDataFiltered, setCollectionDataFiltered] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [registerCompanyType, setRegisterCompanyType] = useState('');
    const [loadingFilterBrands, setLoadingFilterBrands] = useState(false)
    const letters = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ];

    var urlCheck = new URL(window.location.href);

    var paramsCheck = new URLSearchParams(urlCheck.search);

    const brandsLetters = dataDisplay.reduce((acc, brand) => {
        const letter = brand.name[0].toUpperCase();

        if (!acc.includes(letter)) {
            acc.push(letter);
        }

        return acc;
    }, []);

    const drawLinks = letters.map((letter) => {
        return {
            letter,
            href: `#letter-${letter}`,
            disabled: !brandsLetters.includes(letter),
        };
    });

    let timeout;

    function removeFiltersBrandPage() {
        window.localStorage.removeItem('brands_last_filter_name');
        window.localStorage.removeItem('brands_last_filter_pathname');
        window.localStorage.removeItem('brands_last_filter_category_name');
        window.localStorage.removeItem('brands_last_filter_category_url');
    }

    function closeWelcomeModal() {
        setShowWelcomeMessage(false);
    }

    useEffect(() => {
        if (window.location.pathname === '/brands' || window.location.pathname === '/brands') {
            removeFiltersBrandPage();
        }

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands`,
        })
            .then((response) => {
                setBrandsData(response.data.data);
                setDataDisplay(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands/new`,
        })
            .then((response) => {
                setNewBrandsData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands/featured`,
        })
            .then((response) => {
                setFeaturedBrandsData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands/recently-visited`,
        })
            .then((response) => {
                setRecentlyData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/segments`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setSegmentData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/categories`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setCategoriesData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/showrooms`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setShowroomData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        setLoading(false);
    }, []);
    
    async function searchTerm(value) {
        setSeeAllBrands(true);
        setSearchTermValue(value);
        setSearchFilter(true);
    }

    async function filterOptions(value, typeOf) {
        if (value.length === 0) {
            if (typeOf === 1) {
                setSegments([]);
                setSearchFilter(true);
            } else if (typeOf === 2) {
                setCategories([]);
                setSearchFilter(true);
            } else if (typeOf === 3) {
                setShowroom([]);
                setSearchFilter(true);
            } else if (typeOf === 4) {
                setSegments([]);
                setCategories([]);
                setShowroom([]);
                setSearchTermValue('');
                setSearchFilter(true);
            }
            setDataDisplay(brandsData);
            return;
        }

        if (typeOf === 1) {
            setSegments(value);
            setSearchFilter(true);
        } else if (typeOf === 2) {
            setCategories(value);
            setSearchFilter(true);
        } else if (typeOf === 3) {
            setShowroom(value);
            setSearchFilter(true);
        } else if (typeOf === 4) {
            setSegments([]);
            setCategories([]);
            setShowroom([]);
            setSearchTermValue('');
            setSearchFilter(true);
        }
    }

    async function collectionsOnly(value) {
        if (value) {
            setNoCollection([]);
            let noCollection = [];
            let Collection = [];

            dataDisplay.map((e) => {
                if (e.has_active_collection) {
                    Collection.push(e);
                } else {
                    noCollection.push(e);
                }
            });

            setCollectionDataFiltered(dataDisplay);
            setDataDisplay(Collection);
            setNoCollection(noCollection);
            setSeeAllBrands(true);
            setSearchFilter(false);
            defineAz();
        } else {
            if (collectionDataFiltered.length !== 0) {
                var url = new URL(window.location.href);
                var params = new URLSearchParams(url.search);
                if(params.size === 1 && params.has('az')){
                    setDataDisplay(dataDisplay);
                } else {
                    setDataDisplay(collectionDataFiltered);
                }
                setCollectionDataFiltered([]);
            }
            setNoCollection([]);
            if(window.location.href.includes('az=true') || paramsCheck.size >= 1){
              setSeeAllBrands(true);
            } else {
              setSeeAllBrands(false)
            }
            setSearchFilter(false);
            defineAz();
        }
    }

    async function defineAz() {
        let temp;
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            dataDisplay.map((brand) => {
                const newTemp = brand.name[0].toUpperCase();
                const withId = newTemp !== temp;
                temp = newTemp;

                if (withId) {
                    let brands = document.getElementsByClassName(brand.name);
                    if (brands.length !== 0) {
                        brands[0].setAttribute('id', `letter-${newTemp}`);
                    }
                }
            });
        }, 300);
    }

    async function setDataFilters() {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/segments`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setSegmentData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/categories`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setCategoriesData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });

        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/showrooms`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                setShowroomData(response.data.data);
            })
            .catch((response) => {
                console.error(response);
            });
    }

    useEffect(() => {
        if (seachFilter === false) {
            return;
        } else {
            setFilteredData();
            setDataFilters();
        }
    }, [seachFilter]);

    async function setFilteredData() {
        setLoadingFilterBrands(true);
        await axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands`,
            params: {
                search: searchTermValue,
                segments: segments,
                showroom: showroom,
                categories: categories,
            },
        })
            .then((response) => {
                if (response.data.data.length === 0) {
                    setNoDataSearch(true);
                } else {
                    setDataDisplay(response.data.data);
                    setNoDataSearch(false);
                }
            })
            .catch((response) => {
                console.error(response);
                setNoDataSearch(true);
            })
            .finally(() => {
                setSearchFilter(false);
                setVerifyer(true);
                setLoadingFilterBrands(false)
            });
    }

    useEffect(() => {
        if (verifyer) {
            collectionsOnly(document.getElementById('collectionsOnly').checked);
            setVerifyer(false);
        } else {
            return;
        }
    }, [verifyer]);

    // Pega parâmetros da url
    var url = window.location.search.substring(1);
    var urlParams = url.split("&");
    var params = {segments: [], categories: [], active: false, az: false, events: [], search: ""}

    // Verifica qual parâmetro existe na URl e envia como props para componente filho
    for(var i of urlParams){
        if(i.includes('az')){
            const result = i.split('=')
            params.az = result[1] === 'true' ? true : false
        }
        // Search
        if(i.includes('search')){
            const result = i.split('=')
            params.search = result[1].replaceAll("+", " ")
        }

        //Active
        if(i.includes('active')){
            const result = i.split('=')
            params.active = result[1] === 'true' ? true : false
        }

        // Segments
        if(i.includes('segments')){
            const result = i.split('=')
            const numberArr = result[1].replaceAll("%2C", ",").split(",").map(Number)
            params.segments = numberArr
        }

        // Categories
        if(i.includes('categories')){
            const result = i.split('=')
            const numberArr = result[1].replaceAll("%2C", ",").split(",").map(Number)
            params.categories = numberArr
        }

        // Events
        if(i.includes('events')){
            const result = i.split('=')
            const numberArr = result[1].split(",").map(Number)
            params.events = numberArr
        }
    }

    const openLogin = (eventUrl = '') => {
        if (eventUrl !== '') {
          localStorage.setItem('vincular_evento', `/${eventUrl}`);
        }

        setShowLogin(true);
      };

      const closeLogin = () => {
        localStorage.removeItem('redirectBrand');
        localStorage.removeItem('vincular_evento');
        setShowLogin(false);
      };

      const openRegister = (companyType = '', eventUrl = '') => {
        if (eventUrl !== '') {
          localStorage.setItem('defaultEvent', `/${eventUrl}`);
        }

        setRegisterCompanyType(companyType);
        setShowRegister(true);
      };

      const closeRegister = () => {
        localStorage.removeItem('defaultEvent');
        setShowRegister(false);
      };

      return (
        <div>
            <Helmet>
                <meta charSet='utf-8' />
                <title>Blanc | Brands</title>
                <meta name='keywords' content='collection, brands, fashion, buy, sell' />
                <meta http-equiv='content-language' content='en' />
                <meta
                    name='description'
                    content="Discover our comprehensive collection of fashion brands, ranging from global icons to emerging designers, all in one place."
                />
            </Helmet>
            {
            window.localStorage.getItem('user') === null
                ? <NavbarComponent showLogin={() => openLogin()} />
                : <MenuComponent />
            }
            {showWelcomeMessage && <WelcomeModal close={closeWelcomeModal} />}
            <main className='brands-view-component'>
                {brandsData.length > 0 && !loading ? (
                    <>
                        <JumboTitleComponent
                            title={'Brands'}>
                            <BrandsViewFilter
                                searchTerm={searchTerm}
                                filterOptions={filterOptions}
                                showroomData={showroomData}
                                segmentData={segmentData}
                                categoriesData={categoriesData}
                                collectionsOnly={collectionsOnly}
                                params={params}
                            />
                        </JumboTitleComponent>
                    </>
                ) : (
                    <LoadingComponent />
                )}
                <section id='mainCollection' className='componentCenter'>
                    {loading ? (
                        <LoadingComponent />
                    ) : (
                        <>
                            {SeeAllBrands ? (
                                <>
                                    <div className='BrandsCollections'>
                                        {noDataSearch ? (
                                            <>
                                                <p className='NoBrandsFound'>No Brands Found</p>
                                            </>
                                        ) : (
                                            <div className='dataDisplay'>
                                                <>
                                                    <ul className='azFilter'>
                                                        {drawLinks.map((e, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <p
                                                                        onClick={() => {
                                                                            if (!e.disabled) {
                                                                                let top =
                                                                                    document
                                                                                        .querySelector(e.href)
                                                                                        .getBoundingClientRect().top -
                                                                                    300;
                                                                                window.scrollTo(0, top);
                                                                            } else {
                                                                                return;
                                                                            }
                                                                        }}
                                                                        className={`${e.disabled}`}>
                                                                        {e.letter}
                                                                    </p>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </>
                                                {loadingFilterBrands && (
                                                <div className='loadingFilters'>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/images/loading.png`}
                                                        className="load"
                                                        alt=""
                                                    />
                                                </div>
                                                )}
                                                <BrandsBoxComponent brands={dataDisplay} />
                                                {noCollection.length !== 0 ? (
                                                    <>
                                                        <span className='titleSection'>
                                                            Brands without active collections
                                                        </span>
                                                        <BrandsBoxComponent brands={noCollection} />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {featuredBrandsData.length !== 0 ? (
                                        <>
                                            <div className='dataDisplay'>
                                                {newBrandsData.length !== 0 ? (
                                                    <>
                                                        <span className='titleSection'>New Brands</span>
                                                        <BrandsBoxComponent brands={newBrandsData} />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {featuredBrandsData.length !== 0 ? (
                                                    <>
                                                        <span className='titleSection'>Featured Brands</span>
                                                        <BrandsBoxComponent brands={featuredBrandsData} />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                {recentlyData.length !== 0 ? (
                                                    <>
                                                        <span className='titleSection'>Brands recently visited</span>
                                                        <BrandsBoxComponent brands={recentlyData} />
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <a
                                                    className='SeeAllBrands'
                                                    onClick={() => {
                                                        filterOptions('', 4);
                                                        window.scrollTo(0, 0);
                                                        
                                                        var url = new URL(window.location.href);
                                                        var params = new URLSearchParams(url.search);
                                                        params = new URLSearchParams();
                                                        params.append('az', true);
                                                        url.search = params.toString();
                                                        window.history.pushState({}, "", url.toString().replaceAll("%2C", ","));
                                                    }}>
                                                    SEE ALL BRANDS
                                                </a>
                                            </div>
                                        </>
                                    ) : (
                                        <LoadingComponent />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </section>
            </main>
            {showLogin && (
                <LightBoxComponent
                  isOpen={showLogin}
                  handleClick={() => closeLogin()}
                  handleEventClose={() => closeLogin()}
                >
                  <LoginComponent
                    closeRegister={closeRegister}
                    OpenRegister={openRegister}
                  />
                </LightBoxComponent>
              )}
            <FooterComponent />
        </div>
    );
};

export default BrandsViewComponent;
