import React from "react";

import MenuComponent from "../../menu/components/MenuComponent";

import FooterComponent from "../../shared/footer/components/FooterComponent";
import OrderService from "../../../services/OrdersService";

import "../../order/styles/OrderViewComponent.scss";
import OrderItem from "../../order/components/OrderItem";
import OrderSummary from "../../order/components/OrderSummary";
import OrderFilterComponent from "../../order/components/OrderFilterComponent";
import SpinnerComponent from "../../shared/spinner";
import { isMobile } from "is-mobile";
import { withTranslation } from "react-i18next";

class OrderViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      orders: [],
      currentOrder: 0,
      orderService: new OrderService(),
      selectedOrder: null,
      message: "",
      nextPage: false,
      isLoadingMore: false,
      currentTab: "current",
      pastOrder: [],
      feedBackOrder: false,
      sellerName: "",
    };
    this.loadMoreOrders = this.loadMoreOrders.bind(this);
  }

  getNextPaginationPage(data) {
    return data.next_page_url
      ? data.current_page + 1
      : null;
  }

  async getOrders() {
    this.setState({ isLoading: true });
    const response = await this.state.orderService.getOrders();
    const { id } = this.props.match.params;

    const orders = response.data && response.data.data;

    if (id || orders.length > 0) {
      this.setState(
        {
          isLoading: false,
          orders: orders,
          nextPage: this.getNextPaginationPage(response.data),
          selectedOrder: orders[0],
        },
        () => {
          return this.selectOrder(id ? id : orders[0].id);
        }
      );
    }


    if (orders.length > 0) {
      if (id) {
        const index = this.state.orders.findIndex(
          (item) => parseInt(item.id) === parseInt(id)
        );
        this.setState({ currentOrder: index });
      }
    }

    this.setState({ isLoading: false });
  }

  nextOrder(index) {
    if (index < this.state.orders.length) {
      this.setState({ currentOrder: this.state.currentOrder + 1 });
      this.selectOrder(this.state.orders[index].id);
    }
  }
  previousOrder(index) {
    if (index >= 0) {
      this.setState({ currentOrder: this.state.currentOrder - 1 });
      this.selectOrder(this.state.orders[index].id);
    }
  }

  loadMoreOrders() {
    if (this.state.currentTab !== "current") {
      this.getMorePastOrders();
    } else {
      this.getMoreOrders();
    }
  }
  getMoreOrders() {
    this.setState({ isLoadingMore: true });
    this.state.orderService.getOrders({ page: this.state.nextPage }).then((response) => {
      this.setState({
        nextPage: this.getNextPaginationPage(response.data),
        isLoadingMore: false,
        orders: [...this.state.orders, ...response.data.data],
      });
    });
  }

  getMorePastOrders() {
    this.setState({ isLoadingMore: true });
    this.state.orderService.getOrders({ page: this.state.nextPage, past: true }).then((response) => {
      this.setState({
        nextPage: this.getNextPaginationPage(response.data),
        isLoadingMore: false,
        orders: [...this.state.orders, ...response.data.data],
      });
    });
  }

  readMessages(id) {
    const orderService = new OrderService();
    orderService.readMessages(id);
  }

  selectOrder(id) {
    const orders = [...this.state.orders];
    let selectedOrder = null;
    const orderFind = orders.find((item) => parseInt(item.id) === parseInt(id));

    if (orderFind) {
      selectedOrder = {
        ...orderFind,
      };
    }

    this.readMessages(id);

    this.setState({
      selectedOrder: selectedOrder,
      orders: [
        ...orders.map((order) => {
          if (order.id === id) {
            return {
              ...order,
              messages: [
                ...order.messages.map((message) => ({
                  ...message,
                  read: true,
                })),
              ],
            };
          }

          return { ...order };
        }),
      ],
    });
    if (isMobile()) {
      document
        .getElementById("header-resume")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getOrders();
  }

  changeTab(tab) {
    this.setState({ currentTab: tab, nextPage: false });
    if (tab !== "current") {
      this.getPastOrder();
    } else {
      this.getOrders();
    }
  }

  async getPastOrder() {
    this.setState({ isLoading: true });

    const response = await this.state.orderService.getOrders({ past: true });
    const orders = response.data && response.data.data;

    this.setState({
      isLoading: false,
      orders: orders,
      nextPage: this.getNextPaginationPage(response.data),
      selectedOrder: orders[0],
    });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <MenuComponent />
        <div id="order">
          <OrderFilterComponent onFilter={(filter) => this.changeTab(filter)} />
          <div className="order-brands">
            {this.state.isLoading ? (
              <SpinnerComponent />
            ) : (
              <>
                {this.state.orders.map((item, index) => (
                  <OrderItem
                    key={index}
                    item={item}
                    selectOrder={this.selectOrder.bind(this)}
                    isActive={
                      typeof this.state.selectedOrder != "undefined" &&
                      this.state.selectedOrder &&
                      this.state.selectedOrder.id === item.id
                    }
                  />
                ))}
                {this.state.nextPage && (
                  <div onClick={this.loadMoreOrders} className="load-more">
                    {t("LOAD MORE", "LOAD MORE")}
                    {this.state.isLoadingMore && <div className="loader"></div>}
                  </div>
                )}
              </>
            )}
          </div>
          {this.state.isLoading ? (
            <SpinnerComponent />
          ) : (
            <>
              {this.state.selectedOrder ? (
                <OrderSummary order={this.state.selectedOrder} />
              ) : (
                <div className="no-orders">
                  {t("No orders yet.", "No orders yet.")}
                </div>
              )}
              <div className="order-pagination">
                <span
                  onClick={() =>
                    this.previousOrder(this.state.currentOrder - 1)
                  }
                  className="prev"
                >
                  {"<<"}
                </span>{" "}
                |{" "}
                <span
                  onClick={() => this.nextOrder(this.state.currentOrder + 1)}
                  className="next"
                >
                  {">>"}
                </span>
              </div>
            </>
          )}
        </div>

        <FooterComponent />
      </React.Fragment>
    );
  }
}

export default withTranslation()(OrderViewComponent);
