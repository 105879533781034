import React, { Component } from "react";
import "../../styles/ConnectionsPage/ConnectionFilter.scss";
import { withRouter } from "react-router-dom";
import ButtonComponent from "../../../shared/button/components/ButtonComponent";
import { withTranslation } from "react-i18next";

class ConnectionFilter extends Component {
  handleClickPending() {
    const pending = 1;
    this.props.onChange(pending);
  }

  handleClickAll() {
    const pending = 0;
    this.props.onChange(pending);
  }

  render() {
    const { pending, t } = this.props;
    return (
      <div className="connection-filter">
        <ButtonComponent
          action={() => this.handleClickPending()}
          className={pending === 1 ? "button--active" : ""}
        >
          {t("PENDING", "PENDING")}
        </ButtonComponent>
        <ButtonComponent
          action={() => this.handleClickAll()}
          className={pending === 0 ? "button--active" : ""}
        >
          {t("ALL", "ALL")}
        </ButtonComponent>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ConnectionFilter));
