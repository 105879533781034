import React, {Component} from 'react';
import Slider from 'react-slick';
import LightboxHalfScreenComponent from "../../../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import '../style/brandLightBox.scss';

export default class Brand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readMore: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickRegister = this.handleClickRegister.bind(this);
  }
  handleClick(){
    localStorage.setItem('redirectBrand', this.props.brand.slug)
    this.props.close();
    this.props.openLogin();
  }
  handleClickRegister(){
    this.props.close();
    this.props.openRegister();
  }

  render(){
    var settings = {
     dots: true,
     arrows: true,
     infinite: false,
     speed: 500,
     slidesToShow: 1,
     slidesToScroll: 1
   };
    return (
      <div className="brandLightBox">
        <LightboxHalfScreenComponent close={this.props.close}>
          {this.props.brand.landing_modal_images &&
            <div className="image-wrapper">
            {this.props.brand.landing_modal_images &&
              <Slider className="slide" {...settings}>
                {this.props.brand.landing_modal_images.map((image) =>
                  <img key={image.id} src={image.thumbnail} alt={this.props.brand.name}/>
                )}
              </Slider>

              }
            </div>
          }
          <div className="info-wrapper">
            <div className="tags">
              {this.props.brand.tags.map((tag) =>
                <span key={tag.name}>#{tag.name}</span>
              )}
            </div>
            <h1 className="brand-name">{this.props.brand.name}</h1>
            <div className="brand-text">
              <div className={`description ${this.state.readMore ? "hide" : ""}`}>{this.props.brand.landing_description}</div>
              <div className={`text ${this.state.readMore ? "show" : ""}`} >{this.props.brand.landing_modal_text}</div>
            </div>
            <div className={`read-more ${this.state.readMore ? "hide" : ""}`} onClick={()=>{this.setState({readMore: true})}}>
              read more
            </div>
            <div className={`footer ${this.state.readMore ? "show" : ""}`}>
              <div className="discover">Discover the Brand</div>
              <button to="/register" onClick={this.handleClickRegister} className="link register">Register</button>
              <button className="open-login" onClick={this.handleClick}> Login </button>
            </div>
          </div>
        </LightboxHalfScreenComponent>
      </div>
    )
  }
};
