import isMobile from 'is-mobile';
import mixpanel from 'mixpanel-browser';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BrandStyleComponent from '../../../brandstyle/components/BrandStyleComponent';
import SliderComponent from '../../../shared/Slider/components/SliderComponent';
import BackToBrands from '../../../shared/backtobrands/components/BackToBrands';
import BoxComponent from '../../../shared/box/components/BoxComponent';
import FiltersComponent from '../../../shared/filters/components/FiltersComponent';
import Grid1Component from '../../../shared/grids/grid1/components/Grid1Component';
import Grid2Component from '../../../shared/grids/grid2/components/Grid2Component';
import Grid4Component from '../../../shared/grids/grid4/component/Grid4Component';
import LookBookLightBoxComponent from '../../../shared/lookbooklightbox/components/LookBookLightBoxComponent';
import ReadMoreComponent from '../../../shared/readmore/components/ReadMoreComponent';
import TermsAndAboutRatingComponent from '../../../shared/termsandaboutrating/components/TermsAndAboutRatingComponent';
import ConnectionComponent from '../../../views/components/ConnectionComponent';
import '../../style/TheBrand.scss';

class TheBrandComponent extends Component {
    info = [
        { title: this.props.t('BRAND FROM', 'BRAND FROM'), text: this.props.theBrand.brand_from },
        { title: 'YEAR ESTABLISHED', text: this.props.theBrand.year_established },
        {
            title: this.props.t('MULTIBRAND RETAILERS', 'MULTIBRAND RETAILERS'),
            text: this.props.theBrand.multibrand_retailers,
        },
        {
            title: this.props.t('MULTIBRAND RETAILERS DESCRIPTION', 'MULTIBRAND RETAILERS DESCRIPTION'),
            text: this.props.theBrand.multibrand_retailers_full,
        },
    ];

    constructor(props) {
        super(props);

        this.state = {
            idFilter: '',
        };
    }

    closedLightBox() {
        this.setState({
            open: false,
        });
    }

    openLightBox(key) {
        this.setState({
            open: true,
            key: key,
        });
    }

    getIdFilter(id) {
        if (id === this.state.idFilter) {
            this.setState({
                idFilter: '',
            });
        } else {
            this.setState({
                idFilter: id,
            });
        }
    }

    getItemsSplitedByFive(items) {
        let items_by_5 = [];
        let i, j, temparray;
        for (i = 0, j = items.length; i < j; i += 5) {
            temparray = items.slice(i, i + 5);
            items_by_5.push(temparray);
        }
        return items_by_5;
    }

    componentDidMount() {
        if (process.env.REACT_APP_MIXPANEL_ENABLED === true || process.env.REACT_APP_MIXPANEL_ENABLED === 'true') {
            if (window.localStorage.getItem('userData')) {
                mixpanel.identify(`${JSON.parse(window.localStorage.getItem('userData')).id}`);
            }
            mixpanel.track('Seller Page', {
                seller: this.props.brandData.name,
                seller_id: this.props.brandData.id,
                content: 'ABOUT THE BRAND',
            });
        }
    }

    render() {
        const { t, theBrand, seller, brandStyleImages, tags, readMore, brandData } = this.props;
        const media = Math.round(brandStyleImages.length / 2);
        const userData = JSON.parse(localStorage.getItem('userData'));

        return (
            <div>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>{t(`${this.props.brandData.name} Wholesale Products | Connect and Buy online at BLANC`)}</title>
                    <meta name='keywords' content='about, brand, fashion, buy, sell' />
                    <meta http-equiv='content-language' content='en' />
                    <meta name='description' content='Discover the fascinating story behind our fashion brand.' />
                    <link rel='canonical' href={'https://' + t(`${window.location.hostname}/${this.props.seller}`)}/>
                </Helmet>
                {userData && (
                    <>
                        {!(
                            this.props.brandData.id === Number(userData.entity_id) && userData.entity_stype === 'seller'
                        ) && (
                                <section className='centerComponent'>
                                    <ConnectionComponent type='seller' subjectId={this.props.brandData.id} />
                                </section>
                            )}
                    </>
                )}
                <section className='centerComponent'>
                    <Grid1Component
                        info={true}
                        listInfo={this.info}
                        title={theBrand.featured_info_block.title}
                        link='/'
                        meta={brandData.meta}
                        images={theBrand.featured_info_block.images}
                        text={theBrand.featured_info_block.summary}
                        fullText={theBrand.featured_info_block.text}
                        linkTo={theBrand.featured_info_block.link}
                        label={theBrand.featured_info_block.button_label}
                        isReadMore={this.props.isReadMore.bind(this)}
                        readMore={readMore}
                    />
                </section>
                <section id='brandStyleFilters' className='centerComponent'>
                    <div className='containerBrandFilter'>
                        <div className='left'>
                            <BrandStyleComponent>
                                <h2>{t('Brand Style', 'Brand Style')}</h2>
                                <p
                                    className={`${brandData.meta !== null &&
                                            brandData.meta.draft_changes.seller.includes('brand_style')
                                            ? 'border-marker'
                                            : ''
                                        }`}>
                                    {theBrand.brand_style}
                                </p>
                            </BrandStyleComponent>
                        </div>
                        <div className='right'>
                            <div className='contentRight'>
                                <FiltersComponent
                                    seller={seller}
                                    link={true}
                                    makeFilter={this.getIdFilter.bind(this)}
                                    filters={theBrand.categories}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                {!isMobile() && (
                    <section id='sliderBrands' className='centerComponent brandStyle'>
                        <div className='centerComponent'>
                            <SliderComponent
                                dots={true}
                                infinite={false}
                                speed='500'
                                isMobile={isMobile()}
                                activeSlide={false}
                                templateType={theBrand.brand_style_template}
                                slidetoshow={4}
                                slideScroll={4}
                                slidesPerRow={2}
                                arrow={true}>
                                
                                {!isMobile() && theBrand.brand_style_template === 2
                                    ? this.getItemsSplitedByFive(brandStyleImages).map((itens, i) => {
                                        let boxes = [];
                                        let firstItem = itens.shift();
                                        boxes.push(
                                            <div className='box-big'>
                                                <BoxComponent
                                                    title={firstItem.title}
                                                    id={firstItem.id}
                                                    index={i}
                                                    image={firstItem.image_580_600}
                                                    buttonLabel={firstItem.button_label}
                                                    link={firstItem.link}
                                                    key={i}
                                                    newClass={`${brandData.meta &&
                                                            brandData.meta.draft_changes.brand_style_images.hasOwnProperty(
                                                                `${firstItem.id}`
                                                            )
                                                            ? 'border-marker '
                                                            : ''
                                                        }`}
                                                    openLightbox={this.openLightBox.bind(this)}
                                                    makeEffectFilter={this.state.idFilter}
                                                    collection={firstItem.collection}
                                                />
                                            </div>
                                        );

                                        let boxes_2 = itens.map((image, i) => {
                                            return (
                                                <BoxComponent
                                                    title={image.title}
                                                    id={image.id}
                                                    image={image.image_285}
                                                    buttonLabel={image.button_label}
                                                    link={image.link}
                                                    key={i}
                                                    index={i + 1}
                                                    newClass={`box-small ${brandData.meta &&
                                                            brandData.meta.draft_changes.brand_style_images.hasOwnProperty(
                                                                `${image.id}`
                                                            )
                                                            ? 'border-marker '
                                                            : ''
                                                        }`}
                                                    openLightbox={this.openLightBox.bind(this)}
                                                    makeEffectFilter={this.state.idFilter}
                                                    collection={image.collection}
                                                />
                                            );
                                        });
                                        boxes.push(<div className='boxes-small'>{boxes_2}</div>);
                                        return boxes;
                                    })
                                    : brandStyleImages.map((item, i) => {
                                        return (
                                            <BoxComponent
                                                title={item.title}
                                                id={item.id}
                                                image={item.image_285}
                                                buttonLabel={item.button_label}
                                                link={item.link}
                                                key={i}
                                                index={i}
                                                newClass={`box-small ${brandData.meta &&
                                                        brandData.meta.draft_changes.brand_style_images.hasOwnProperty(
                                                            `${item.id}`
                                                        )
                                                        ? 'border-marker '
                                                        : ''
                                                    }`}
                                                openLightbox={this.openLightBox.bind(this)}
                                                makeEffectFilter={this.state.idFilter}
                                                collection={item.collection}
                                            />
                                        );
                                    })}
                            </SliderComponent>
                        </div>
                    </section>
                )}
                {isMobile() && (
                    <section id='sliderBrands' className='centerComponent'>
                        <div className='centerComponent'>
                            <SliderComponent
                                dots={false}
                                infinite={false}
                                speed='500'
                                activeSlide={false}
                                templateType={theBrand.brand_style_template}
                                slidetoshow={2}
                                slideScroll={1}
                                slidesPerRow={2}
                                arrow={true}>
                                {brandStyleImages.map((image, i) => {
                                    return (
                                        <BoxComponent
                                            title={image.title}
                                            id={image.id}
                                            image={image.image_285}
                                            buttonLabel={image.button_label}
                                            link={image.link}
                                            key={i}
                                            openLightbox={() => image.link && window.open(image.link)}
                                            newClass={media === i ? 'breakLine' : ''}
                                            makeEffectFilter={this.state.idFilter}
                                            collection={image.collection}
                                        />
                                    );
                                })}
                            </SliderComponent>
                        </div>
                    </section>
                )}
                {theBrand.info_block.map((block, index) => {
                    return block.image_position === 'right' ? (
                        <section className='centerComponent margin shadowMob' key={index}>
                            <Grid2Component
                                info={false}
                                title={block.title}
                                link='/'
                                changes={
                                    brandData.meta !== null &&
                                        brandData.meta.draft_changes.info_blocks.hasOwnProperty(`${block.id}`)
                                        ? brandData.meta.draft_changes.info_blocks[`${block.id}`]
                                        : null
                                }
                                images={block.images.map((image) => ({
                                    id: image.id,
                                    order: image.order,
                                    portrait_image: image.portrait_image,
                                    landscape_image: image.landscape_image,
                                    video_url: image.video_url,
                                }))}
                                text={block.summary}
                                label={block.button_label}
                                linkTo={block.link}
                                fullText={block.text}
                                imageOrientation={block.image_orientation}
                            />
                        </section>
                    ) : (
                        <section className='centerComponent mgBottom zero lastGrid' key={index}>
                            <Grid4Component
                                info={false}
                                title={block.title}
                                link='/'
                                changes={
                                    brandData.meta &&
                                        brandData.meta.draft_changes.info_blocks.hasOwnProperty(`${block.id}`)
                                        ? brandData.meta.draft_changes.info_blocks[`${block.id}`]
                                        : null
                                }
                                images={block.images.map((image) => ({
                                    id: image.id,
                                    order: image.order,
                                    portrait_image: image.portrait_image,
                                    landscape_image: image.landscape_image,
                                    video_url: image.video_url,
                                }))}
                                text={block.summary}
                                label={block.button_label}
                                linkTo={block.link}
                                fullText={block.text}
                                imageOrientation={block.image_orientation}
                            />
                        </section>
                    );
                })}
                <section className='centerComponent'>
                    <div className='contentTerms'>
                        <TermsAndAboutRatingComponent
                            aboutRatingText={theBrand.about_rating}
                            termsAndConditionsText={theBrand.terms_conditions}
                        />
                    </div>
                </section>
                {this.state.open && (
                    <LookBookLightBoxComponent handleClick={this.closedLightBox.bind(this)}>
                        <SliderComponent
                            dots={false}
                            arrow={true}
                            infinite={false}
                            speed='500'
                            slidetoshow={1}
                            slideScroll={1}
                            slidesPerRow={1}
                            slidesToShow={1}
                            initialSlide={brandStyleImages.findIndex(
                                (item) => Number(item.id) === Number(this.state.key)
                            )}>
                            {brandStyleImages.map((image, index) => {
                                return (
                                    <div className='contentBox' key={index}>
                                        <div className='boxLookBook'>
                                            <img src={image.image_580_600} alt={image.name} />
                                        </div>
                                    </div>
                                );
                            })}
                        </SliderComponent>
                    </LookBookLightBoxComponent>
                )}
                <section id='readMore'>
                    <div className='centerComponent'>
                        <BackToBrands />
                        <ReadMoreComponent tags={tags} />
                    </div>
                </section>
            </div>
        );
    }
}

export default withTranslation()(withRouter(TheBrandComponent));
