import React, { Component } from "react";
import moment from "moment";
import "../../styles/MessagesView/MessageBlockComponent.scss";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

class MessageBlockComponent extends Component {
  formatDate(date) {
    const { i18n } = this.props;
    return moment(date).format(
      i18n.language === "en" ? "MM/DD/YY" : "DD/MM/YY"
    );
  }

  formatTime(date) {
    const { i18n } = this.props;
    return moment(date).format(i18n.language === "en" ? "hh:mm A" : "HH:mm");
  }

  renderSubjectName() {
    const { message } = this.props;
    const userStype = localStorage.getItem("user_stype");

    return userStype === "seller" ? message.buyer.name : message.seller.name;
  }

  renderShortMessage() {
    return this.props.message.message.substring(0, 80);
  }

  render() {
    const userStype = localStorage.getItem("user_stype");
    const { message } = this.props;
    const { params } = this.props.match;

    const subjectId =
      userStype === "seller" ? message.buyer.id : message.seller.id;
    return (
      <div
        className={`messages-block-component ${
          Number(params.subjectId) === subjectId
            ? "messages-block-component--active"
            : ""
        } ${
          message.flagged_messages ? "messages-block-component--flagged" : ""
        }`}
        onClick={() =>
          this.props.history.push(
            `/messages/${subjectId}${
              message.flagged ? "#message-" + message.id : ""
            }`
          )
        }
      >
        <div className="left">
          <img src="/images/messages/flag.svg" className="flag" alt="flag" />
          <div className="name">{this.renderSubjectName()}</div>
          <div className="message">{this.renderShortMessage()}</div>
        </div>
        <div className="dates">
          <div className="flag flag--box" />
          <div className="date">
            {this.formatDate(this.props.message.created_at)}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MessageBlockComponent));
