import React from 'react';
import classNames from 'classnames';

import '../styles/ButtonComponent.scss';

export default function ButtonComponent(props) {
    let btnClasses = classNames({
        "large": props.size === "large",
        "unfilled": props.type === "unfilled",
    });

    return (
        <button onClick={props.onClick} className={btnClasses}>{props.text}</button>
    );
}
