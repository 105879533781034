import React, {Component} from 'react';
import PatternContainerComponent from '../../pattern/components/PatternContainerComponent';
import PatternItemComponent from '../../pattern/components/PatternComponent';
import Button from '../../shared/button/components/ButtonComponent';
import TitleShadowComponent from "../../shared/titleShadow/components/TitleShadowComponent";
import BoxComponent from "../../shared/box/components/BoxComponent";

class PatternViewComponent extends Component {
    render() {
        return (
            <React.Fragment>
                <PatternContainerComponent>
                    
                    <PatternItemComponent title="Button Component">
                        <Button action={() => console.log('action method')}> Teste </Button>
                    </PatternItemComponent>
                    
                    <PatternItemComponent>component 2</PatternItemComponent>

                    <PatternItemComponent>
                        <TitleShadowComponent type="h2" position="right">
                            Bags that make the difference
                        </TitleShadowComponent>
                    </PatternItemComponent>

                    <PatternItemComponent>
                        <TitleShadowComponent type="h2" position="left">
                            Bags that make the difference
                        </TitleShadowComponent>
                    </PatternItemComponent>

                    <PatternItemComponent>
                        <BoxComponent image="image-01.png" title="Future Delivery" />
                    </PatternItemComponent>

                </PatternContainerComponent>
            </React.Fragment>
        );
    }
}

export default PatternViewComponent;