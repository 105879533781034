import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class Buttons extends Component {
    render() {
        return (
            <div className="buttons">
                {this.props.buttons && this.props.buttons.map(button => {
                    return {
                        register: <>
                            <button onClick={() => this.props.openRegister('', this.props.slug)} className="link register" style={{backgroundColor: button.color || null}}>
                                {button.text}
                            </button>
                        </>,
                        login: <>
                            <button onClick={() => this.props.open(this.props.slug)} className="link login" style={{backgroundColor: button.color || null}}>
                                {button.text}
                            </button>
                        </>,
                        link: <>
                            <a href={button.link} target="_blank" className="link" style={{backgroundColor: button.color || null}}>
                                {button.text}
                            </a>
                        </>,
                    }[button.type]
                })}
            </div>
        );
    }
}

export default withTranslation()(Buttons);
