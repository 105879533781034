import React, {Component}  from 'react';
import { withTranslation } from 'react-i18next';
import LightboxHalfScreenComponent from "../../lightboxHalfScreen/components/LightboxHalfScreenComponent";
import "../style/TermsAndAboutRatingComponent.scss"

class TermsAndAboutRatingComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showTermsAndConditionsModal: false,
            showAboutTheRatingModal: false
        }
    }

    closeTermsAndConditionsModal(){
        this.setState({
            showTermsAndConditionsModal: false
        })
    }

    closeAboutTheRatingModal(){
        this.setState({
            showAboutTheRatingModal: false
        })
    }

    handleAboutTheRatingClick() {
      this.setState(state => ({
        showAboutTheRatingModal: !state.showAboutTheRatingModal
      }));
    }

    handleTermsAndConditionsClick() {
      this.setState(state => ({
        showTermsAndConditionsModal: !state.showTermsAndConditionsModal
      }));
    }

    render(){
        const { aboutRatingText, termsAndConditionsText, t } = this.props;

        return (
            <div id="termsAndAboutRatings">
                <ul>
                  {/* <li>
                        <button onClick={() => this.handleAboutTheRatingClick()}>ABOUT THE RATING</button>
                    </li>

                    <li className="divisorTermsAndConditions">
                        {`//`}
                    </li> */}

                    <li>
                          <button onClick={() => this.handleTermsAndConditionsClick()}>{t('TERMS AND CONDITIONS', 'TERMS AND CONDITIONS')}</button>
                    </li>
                </ul>
                { this.state.showAboutTheRatingModal &&
                    <LightboxHalfScreenComponent
                        close={this.closeAboutTheRatingModal.bind(this)}
                    >
                      <h1 className="lh-modal-title">{t('ABOUT THE RATING', 'ABOUT THE RATING')}</h1>
                        <div dangerouslySetInnerHTML={{__html: aboutRatingText}} />
                    </LightboxHalfScreenComponent>
                }
                { this.state.showTermsAndConditionsModal &&
                    <LightboxHalfScreenComponent
                        close={this.closeTermsAndConditionsModal.bind(this)}
                    >
                      <h1 className="lh-modal-title">{t('TERMS AND CONDITIONS', 'TERMS AND CONDITIONS')}</h1>
                      <div dangerouslySetInnerHTML={{__html: termsAndConditionsText}} />
                    </LightboxHalfScreenComponent>
                }
            </div>
        );
    }
}


export default withTranslation()(TermsAndAboutRatingComponent);
