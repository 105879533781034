import React, { Component } from "react";
import "./style/HighlightE.scss";
import { withTranslation } from "react-i18next";
import Buttons from "../buttons/Buttons";

class HighlightE extends Component {
  render() {
    return (
      <section id="SecondHeader" className="highlightE">
        <div className="texture2" style={{
            '--image': `url(${this.props.data.image})`,
            '--image-mobile': `url(${this.props.data.image_mobile || this.props.data.image})`,
        }} />
        <div className="logoAndText">
          <div>
            <img src={this.props.data.logo} alt="" />
            <p>{this.props.data.text}</p>
            <Buttons
              buttons={this.props.data.buttons}
              openRegister={this.props.openRegister}
              open={this.props.open}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(HighlightE);
