import React, {Component, useState} from 'react';
import '../styles/BrandsViewFilter.scss';
import {withRouter} from 'react-router-dom';
import ButtonComponent from '../../shared/button/components/ButtonComponent';
import SelectInput from '../../shared/button/components/SelectInput';
import SegmentService from '../../../data/pages/segments/SegmentService';
import axios from 'axios';

let timeout;

class BrandsViewFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            segments: [],
            events: [],
            segmentData: [],
            showroomData: [],
            clearOptions: false,
            activeAz: false,
        };
    }

    handleSearchTermChange(value) {
        clearTimeout(timeout);
        this.setState({
            searchTerm: value,
        });
        
        var url = new URL(window.location.href);
        var params = new URLSearchParams(url.search);

        if (params.has("search")) {
            value.length > 0
                ? params.set("search", value)
                : params.delete("search");
        } else {
            params.append("search", value);
        }
        if(value.length === 0){
            this.handleFilter('', 4)
            return
        }
        
        url.search = params.toString();
        window.history.pushState({}, "", url.toString());

        timeout = setTimeout(() => {
            this.props.searchTerm(value);
            this.autoComplete(value);
        }, 1000);
    }

    handleCollectionsOnly(value) {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            // this.setState({
            //     collectionsOnly: value,
            // });

            this.props.collectionsOnly(value);
        }, 300);
    }

    handleFilter = (value, typeOf) => {
        this.setState({
            filterOptions: value,
        });
        
        this.props.filterOptions(value, typeOf);
        const types = {
          1: "segments",
          2: "categories",
          3: "events",
          4: "az",
          5: "active"
        };
       
        // URL atual
        var url = new URL(window.location.href);
    
        // Parâmetros URL
        var params = new URLSearchParams(url.search);
        
        // Verifica se o parâmetro já existe na URL
        if (typeOf === 4 && value.length === 0) {
            params = new URLSearchParams(); 
            params.append(types[typeOf], true); 
            this.setState({
                clearOptions: true,
            });
            this.props.filterOptions([], 4)
            
            var $btns = document.querySelectorAll('.btnDropDown.active')
            $btns.forEach((e) => {
                e.classList.remove('active')
            })
            var $checkbox = document.getElementById("collectionsOnly");
            $checkbox.checked = false;
            
            this.autoComplete('');
            this.setState({
                searchTerm: '',
            });
          } else {
            // Verifica se o parâmetro já existe na URL
            if (params.has(types[typeOf])) {
              value.length > 0
                ? params.set(types[typeOf], value)
                : params.delete(types[typeOf]);
              !params.has('az') && params.append('az', true)
            } else {
              value.length > 0 && params.append(types[typeOf], value);
            }
          }
        
        // Atualiza a URL atual sem recarregar a página
        url.search = params.toString();
        window.history.pushState({}, "", url.toString().replaceAll("%2C", ","));
      };

    autoComplete(value) {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_NEW_API_URL}/v2/brands/autocomplete`,
            params: {
                search: value,
            },
        })
            .then((response) => {
                this.setState({
                    brandsComplete: response.data.data,
                });
            })
            .catch((response) => {
                console.error(response);
            });
    }

    async componentDidMount() {
        if (window.localStorage.getItem('access_token')) {
            let segmentService = new SegmentService();
            let segments = await segmentService.get();
            let events = await segmentService.getEvents();

            events.results = events.results.filter((item) => item.is_active);

            this.setState({
                segments: segments.results,
                events: events.results,
                loading: false,
                searchTerm: '',
                filter: '',
                brandsComplete: [],
                clearOptions: false,
                activeAz: false,
            });
        } else {
            this.setState({
                loading: false,
                searchTerm: '',
                filter: '',
                brandsComplete: [],
                clearOptions: false,
                activeAz: false,
            });
        }
    
    // Search
    if(this.props.params.search && this.props.params.search.length > 0){
        this.handleSearchTermChange(this.props.params.search)
    }

    // Segments
    if (this.props.params.segments && this.props.params.segments.length > 0) {
      this.handleFilter(this.props.params.segments, 1);
    }

    // Categories
    if (
      this.props.params.categories &&
      this.props.params.categories.length > 0
    ) {
      this.handleFilter(this.props.params.categories, 2);
    }

    // Events
    if (this.props.params.events && this.props.params.events > 0) {
      this.handleFilter(this.props.params.events, 3);
    }

    // Az
    // if(this.props.params.az && this.props.params.az === true){
    //     this.handleFilter('', 4)
    //     this.setState({
    //         activeAz: this.props.params.az,
    //     });
    // }

    // Active
    if (this.props.params.active) {
      var $checkbox = document.getElementById("collectionsOnly");
      this.handleCollectionsOnly(this.props.params.active);

      if (this.props.params.active === true) {
        $checkbox.checked = true;
      }
    }
    }

    clearFilters = () => {
        this.setState({
            clearOptions: true,
        });

        var $checkbox = document.getElementById("collectionsOnly");
        $checkbox.checked = false;

        var $btns = document.querySelectorAll('.btnDropDown.active')
        $btns.forEach((e) => {
            e.classList.remove('active')
        })

        this.autoComplete('');
        this.setState({
            searchTerm: '',
        });
        this.handleFilter('', 4)

        window.history.pushState({}, "", `${window.location.origin}/brands?az=true`);
    };

    setClearOptions = (value) => {
        this.setState({
            clearOptions: value,
        });
    };
    render() {
        let inputSearch = React.createRef();
        
        return (
            <div className='brands-view-filter'>
                <div className='relative'>
                    <input
                        type='text'
                        placeholder='Search by brand'
                        onChange={(e) => {
                            this.handleSearchTermChange(e.target.value);
                        }}
                        value={this.state.searchTerm || ''}
                        ref={inputSearch}
                        className='brandSearch'
                        onBlur={() => {
                            this.autoComplete('');
                        }}
                    />
                    <span className='lupa'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                            <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z' />
                        </svg>
                    </span>
                    <ul className='list'>
                        {this.state.brandsComplete ? (
                            this.state.brandsComplete.map((e) => {
                                return (
                                    <li
                                        key={e.name}
                                        onClick={() => {
                                            this.setState({
                                                searchTerm: e.name,
                                            });
                                            this.handleSearchTermChange(e.name);
                                            this.autoComplete('');
                                        }}>
                                        <a href={`/${e.slug}`} className='brand--item--list'>
                                            {e.name}
                                        </a>
                                    </li>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </ul>
                </div>

                <ButtonComponent
                    action={() => {
                        this.handleFilter('', 4);
                        inputSearch.current.value = '';
                        this.setState({
                            activeAz: !this.state.activeAz,
                        });
                    }}
                    className={''}>
                    A-Z
                </ButtonComponent>

                {this.props.segmentData.length !== 0 ? (
                    <div className='relative'>
                        <SelectInput
                            title={'SEGMENTS'}
                            data={this.props.segmentData}
                            handleFilter={this.handleFilter}
                            typeOf={1}
                            clearOptions={this.state.clearOptions}
                            setClearOptions={this.setClearOptions}
                            params={this.props.params.segments}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {this.props.categoriesData.length !== 0 ? (
                    <div className='relative'>
                        <SelectInput
                            title={'CATEGORIES'}
                            data={this.props.categoriesData}
                            handleFilter={this.handleFilter}
                            typeOf={2}
                            clearOptions={this.state.clearOptions}
                            setClearOptions={this.setClearOptions}
                            params={this.props.params.categories}
                        />
                    </div>
                ) : (
                    <></>
                )}

                {this.props.showroomData.length !== 0 ? (
                    <div className='relative'>
                        <SelectInput
                            title={'SHOWROOMS'}
                            data={this.props.showroomData}
                            handleFilter={this.handleFilter}
                            typeOf={3}
                            clearOptions={this.state.clearOptions}
                            setClearOptions={this.setClearOptions}
                            params={this.props.params.events}
                        />
                    </div>
                ) : (
                    <></>
                )}

                <div className='lastOptions'>
                    <p onClick={this.clearFilters}>Clear filters</p>
                    <div className='ActivateCollectionsOnly'>
                        <label className='switch'>
                            <input
                                type='checkbox'
                                id='collectionsOnly'
                                name='collectionsOnly'
                                onClick={(e) => {
                                    if (e.currentTarget.checked) {
                                        this.handleCollectionsOnly(true);
                                        this.handleFilter('true', 5)
                                    } else {
                                        this.handleCollectionsOnly(false);
                                        this.handleFilter('', 5)
                                     }
                                }}
                            />
                            <span className='slider round'></span>
                        </label>
                        <label htmlFor='collectionsOnly'>Active collections only</label>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BrandsViewFilter);
