import React, { Component } from "react";
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import "../style/TermsOfUseAndPrivacyPolicy.scss";
import axios from "axios";
import { withTranslation } from "react-i18next";

class TermsAndPrivacyPolicyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsOfUse: false,
      showPrivacyPolicy: false,
      TermsAndConditions: "",
      privacyPolicy: "",
    };
  }

  async getTermsAndConditions() {
    let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/terms-and-conditions`);
    let response = await result;

    this.setState({
      TermsAndConditions: response.data.text,
    });
  }

  async getPrivacyPolicy() {
    let result = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/privacy-policy`);
    let response = await result;

    this.setState({
      privacyPolicy: response.data.text,
    });
  }

  componentDidMount() {
    this.getPrivacyPolicy();
    this.getTermsAndConditions();
  }

  render() {
    return (
      <>
        <div
          id="termsAndConditions"
          className={this.props.float ? "termsAndConditions--float-right" : ""}
        >
          <button
            onClick={() => {
              this.setState({ showTermsOfUse: true });
            }}
            className="button"
          >
            {this.props.t("TERMS OF USE")}
          </button>
          <button
            onClick={() => {
              this.setState({ showPrivacyPolicy: true });
            }}
            className="button"
          >
            {this.props.t("PRIVACY POLICY")}
          </button>
        </div>
        {this.state.showTermsOfUse && (
          <TermsOfUse
            text={this.state.TermsAndConditions}
            close={() => {
              this.setState({ showTermsOfUse: false });
            }}
          />
        )}
        {this.state.showPrivacyPolicy && (
          <PrivacyPolicy
            text={this.state.privacyPolicy}
            close={() => {
              this.setState({ showPrivacyPolicy: false });
            }}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(TermsAndPrivacyPolicyComponent);
