export function testForm(typeInput, valueInput) {
  const d = {
    text1: {
      validator: valueInput.length >= 3,
    },
    text2: {
      validator: valueInput.length >= 8,
    },
    text3: {
      validator: valueInput.length >= 1,
    },
    email: {
      validator: /^[^\s]+@[^\s]+\..+$/.test(valueInput),
    },
    instagram: {
      validator: new RegExp("^(http(s)?://)(www.)?instagram.com/(.*)").test(
        valueInput
      ),
    },
    url: {
      validator:
        /^(http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@@!\$&'\(\)\*\+,;=.]+$/gm.test(
          valueInput
        ),
    },
    facebook: {
      validator: new RegExp("^(?:http(s)?:\/\/)?(?:www\.)?facebook\.com\/(.*)").test(
        valueInput
      ),
    },
    year: {
      validator: valueInput <= 1950 || valueInput > new Date().getFullYear(),
    },
  };
  return d[typeInput].validator;
}
