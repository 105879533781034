import React, { Component } from "react";
import "../style/LoadingStyle.scss";
import { withTranslation } from "react-i18next";

class LoadingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="loading">
        <div className="containerLoading">
          <img
            src={`${process.env.PUBLIC_URL}/images/loading.png`}
            className="load"
            alt=""
          />
          <div className="containerLogo">
            {t("LOADING MORE", "LOADING MORE")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoadingComponent);
