import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./data/store";
import RouterSwitchComponent from "./presentation/views/components/RouterSwitchComponent";
import Analytics from "./presentation/analytics/Analytics";
import { Helmet, HelmetProvider } from "react-helmet-async";
import mixpanel from "mixpanel-browser";

window.mixpanel = mixpanel;

const mixPanelToken = localStorage.getItem("mixPanelToken")

if (
  process.env.REACT_APP_MIXPANEL_ENABLED === true ||
  process.env.REACT_APP_MIXPANEL_ENABLED === "true"
) {
  mixpanel.init(mixPanelToken);
}

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Analytics />
          <Helmet>
            {window.localStorage.getItem("user") &&
              (process.env.REACT_APP_MIXPANEL_ENABLED === true ||
                process.env.REACT_APP_MIXPANEL_ENABLED === "true") && (
                <script>
                  {`let USER_ID = "${
                    JSON.parse(window.localStorage.getItem("userData")).id
                  }";
                        mixpanel.identify(USER_ID);`}
                </script>
              )}
          </Helmet>
          <Route component={RouterSwitchComponent} />
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  );
}

serviceWorker.unregister();

export default App;
