import React, { useEffect } from "react";
import "../style/BuyersBoxStyle.scss";
import mixpanel from "mixpanel-browser";
import { withRouter } from "react-router-dom";
import BuyerBoxComponent from "./BuyerBoxComponent";

const BuyersBoxComponent = (props) => {
  const { buyers } = props;

  useEffect(() => {
    if (process.env.REACT_APP_MIXPANEL_ENABLED === true || process.env.REACT_APP_MIXPANEL_ENABLED === 'true') {
      if(window.localStorage.getItem("userData")) {
        mixpanel.identify(
          `${JSON.parse(window.localStorage.getItem("userData")).id}`
        );
      }
      mixpanel.track("Buyer List");
    }
  }, []);

  return (
    <div className="boxBuyers">
      {buyers.map((item, index) => {
        return <BuyerBoxComponent buyer={item} />;
      })}
    </div>
  );
};

export default withRouter(BuyersBoxComponent);
