import React, { Component } from "react";
import ButtonComponent from "../../shared/button/components/ButtonComponent";
import "../styles/ConnectionComponent.scss";
import axios from "axios";
import SpinnerComponent from "../../shared/spinner";
import { withRouter } from "react-router";
import LightboxHalfScreenComponent from "../../shared/lightboxHalfScreen/components/LightboxHalfScreenComponent";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { saveData } from "../../../data/pages/connect/ConnectActions";

class ConnectionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showModal: false,
    };
  }

  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/connections/${this.props.type}/${this.props.subjectId}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });
          this.props.saveData({
            data: response.data.data,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.setState({
            loading: false,
          });
          this.props.saveData({
            data: error.response.status,
          });
        }
      });
  }

  handleModal() {
    const userSType = localStorage.getItem("user_stype");

    if (this.props.primeiraRequest !== "" && userSType !== "buyer") {
      this.setState({
        showModal: true,
      });
    } else {
      this.handleRequestConnection();
    }
  }

  handleRequestConnection() {
    this.setState({
      showModal: false,
    });
    const userSType = localStorage.getItem("user_stype");

    this.setState({
      loading: true,
    });

    const self = this;

    axios
      .post(
        `${process.env.REACT_APP_NEW_API_URL}/v1/connections/${this.props.type}/${this.props.subjectId}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            loading: false,
          });
          this.props.saveData({
            data: response.data.data,
          });
          if (userSType === "buyer") {
            self.props.history.push(`/messages/${self.props.subjectId}`);
          }
        }
      });
  }

  render() {
    const userSType = localStorage.getItem("user_stype");
    const { history, t } = this.props;
    const {data} = this.props.data;

    return (
      <div className="connection-component">
        {this.state.loading ? (
          <ButtonComponent className="loading-button">
            <SpinnerComponent />
          </ButtonComponent>
        ) : (
          <div>
            {data === 404 && (
              <ButtonComponent action={() => this.handleModal()}>
                {userSType === "buyer"
                  ? t("SEND MESSAGE", "SEND MESSAGE")
                  : t("REQUEST CONNECTION", "REQUEST CONNECTION")}
              </ButtonComponent>
            )}
            {data && data.status &&
              data.status.status === "accepted" && (
                <ButtonComponent
                  action={() =>
                    history.push(`/messages/${this.props.subjectId}`)
                  }
                >
                  {t("SEND MESSAGE", "SEND MESSAGE")}
                </ButtonComponent>
              )}
            {data && data.status &&
              data.status.status === "pending" &&
              data.user_stype === userSType && (
                <ButtonComponent
                  action={() => null}
                  className="button--pending"
                >
                  {t("REQUEST PENDING", "REQUEST PENDING")}
                </ButtonComponent>
              )}
            {data && data.status &&
              data.status.status === "declined" &&
              userSType === "seller" && (
                <ButtonComponent
                  action={() => null}
                  className="button--pending"
                >
                  {t("REQUEST PENDING", "REQUEST PENDING")}
                </ButtonComponent>
              )}
            {data && data.status &&
              data.status.status === "pending" &&
              data.user_stype !== userSType && (
                <ButtonComponent action={() => this.handleModal()}>
                  {userSType === "buyer"
                    ? t("SEND MESSAGE", "SEND MESSAGE")
                    : t("ACCEPT CONNECTION", "ACCEPT CONNECTION")}
                </ButtonComponent>
              )}
          </div>
        )}

        {this.state.showModal && (
          <LightboxHalfScreenComponent
            extraClass="welcome-lh-modal"
            close={() => this.handleRequestConnection()}
          >
            <div id="welcome-message-modal">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.primeiraRequest }}
              />

              <div className="modal-footer ng-scope">
                <button
                  onClick={() => this.handleRequestConnection()}
                  type="button"
                  className="btn btn-warning"
                >
                  {t("Close", "Close")}
                </button>
              </div>
            </div>
          </LightboxHalfScreenComponent>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.connectEntity.data,
});

const mapDispatchToProps = {
  saveData,
};

ConnectionComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionComponent);

export default withTranslation()(withRouter(ConnectionComponent));
