
import React, { Component } from 'react';
import {connect} from "react-redux";

import '../style/ourBrands.scss';


import BrandLightBox from "./BrandLightBox";
import LandingBrandsService from "../../../../../data/pages/landing/landingBrandsService";
import LandingBrandsInterector from "../../../../../domain/pages/landing/LandingBrandsInterector";
import LoadingComponent from "../../../../shared/loading/components/LoadingComponent";

import BrandSlider from './BrandSlider';


class OurBrandsComponent extends Component {
    constructor(props){
        super(props);
        this.state = {
          showModal: false,
        }
        this.openModal = this.openModal.bind(this);
    }

    componentDidMount() {
      new LandingBrandsInterector(new LandingBrandsService().getData());
    }


    openModal(brand) {
        this.setState({
            showModal: true,
            activeBrand: brand
        });
    }

    closeModal() {
        this.setState({
            showModal: false,
            activeBrand: null
        });
    }

    renderBrands(){
      if(this.props.ourBrands.length > 0){
        let brands = this.props.ourBrands

        const { eventSegment } = this.props;

        if (eventSegment) {
          brands = this.props.ourBrands.filter(brand => {
            return brand.event_segments.some(segment => segment.id === eventSegment.id)
          });
        }

        return (
          <BrandSlider 
            ourBrands={brands} 
            eventSegment={eventSegment}
            openModal={this.openModal}
            />
        )
      }else{
        return ( <LoadingComponent /> )
      }
    }


    render() {
        return (
          <>
            <div id='our-brands-items'>

              <div className={`slider-wrapper ${this.props.marginRegular ? 'margin-regular' : ''}`}>
                { this.renderBrands() }
              </div>

            </div>
            {this.state.activeBrand &&
              <BrandLightBox
                  openRegister={this.props.openRegister}
                  openLogin={this.props.openLogin}
                  brand={this.state.activeBrand}
                  isOpen={this.state.showModal}
                  close={() =>this.closeModal()}>
              </BrandLightBox>
            }
          </>
        );
    }
}
function mapStateToProps(state) {
    return {
        ourBrands: state.landingBrandsEntity
    }
}

export default connect(mapStateToProps)(OurBrandsComponent)
