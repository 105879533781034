import React, {Fragment} from 'react';
import "../style/ToTheWorld.scss";
import NavbarComponent from "../../landing/shared/navbar/components/NavbarComponent";
import FooterComponent from "../../shared/footer/components/FooterComponent";
import MapImage from "../images/map.png";
import ClothesImage from "../images/clothes.png";

const ToTheWorld = () => {
    return (
        <Fragment>
            <NavbarComponent />
            <div className="subContainer">
                <div className="mainBox">
                    <span className="boxTitle">From Brazil <br/> to the World</span>
                    <span className="boxText">How it started</span>
                </div>

                <section className="textContainer">
                    <span className="quoteCall"><span className="emphasized">It all began on any other day</span> when our restless founder, while reading a fashion magazine, asked herself:</span>

                    <div className="quoteContent">
                        <p className="quoteText">
                            "Despite its immense production capacity and fashion brands with enormous potential, why is Brazil unable to establish itself internationally, except in some rare cases?"
                        </p>
                        <img className="mapImage" src={MapImage} alt="map" />
                    </div>
                    

                    <p>
                        The first answers came from her knowledge of the industry. Other answers came through conversations with close contacts, partners of associations and important brands. And some answers came from research and Internet searches.
                    </p>

                    <img className="clothesImage" src={ClothesImage} alt="clothes" />

                    <p>
                        What she discovered was that there was great opportunity within an industry that still did business the old way. It was not a matter of the fact that this industry was lacking processes, capillarity or scalability, but above all, one that did not allow or was not in place for building lasting relationship between Brazilian brands and international buyers.
                    </p>
                    <p>
                        In order to transform her idea into reality, she just had to find a group of people who shared the same vision, goals and ideals, speak with brands who were already exporting or had the potential to do so, obtain the support of the industry's associations and companies, as well as the government. These were the necessary to construct "from scratch" a fully customized platform that would enable handpicked, authentic Brazilian brands and international retailers with different profiles to do business together.
                    </p>
                    <p>
                        This would happen by seeking to preserve the best of Brazilian creativity and attitude, using the best international operational practices, and being responsible for all stages of the process. The idea was to not only connect the dots, but to bring cultures together. To promote fashion with content, with history, with tradition. To effectively position Brazil and its brands internationally, building lasting relationships and perfect marriages.
                    </p>
                </section>
                
            </div>
            <FooterComponent />
        </Fragment>
    );
};

export default ToTheWorld;