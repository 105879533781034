import React, {Component} from 'react';
import './style/HighlightF.scss';
import { withTranslation } from 'react-i18next';
import Buttons from '../buttons/Buttons';

class HighlightF extends Component {
    render() {
        return (
            <section id="HeaderHighLightF" className={this.props.id}>
                <div className="logoAndText">
                    <h1>{this.props.data.title || 'THE DIGITAL PLATFORM BUILT FOR THE FUTURE OF FASHION'}</h1>
                    <p>{this.props.data.text}</p>
                    <Buttons
                        buttons={this.props.data.buttons} 
                        openRegister={this.props.openRegister}
                        open={this.props.open}
                    />
                </div>
                <div className="headerImages" style={{backgroundImage: `url(${this.props.data.image})`}}></div>
            </section>
        );
    }
}

export default withTranslation()(HighlightF);
