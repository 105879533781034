import { save } from "./landingBrandsActions";
import store from "../../store";
import LandingBrandsEntity from "../../../domain/pages/landing/LandingBrandsEntity";

export default class LandingBrandsService {
  headers = {
    Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
    "X-Account-Hash": localStorage.getItem("xAccountHash"),
  };

  dispatch = store.dispatch;

  async getData() {
    const INIT = { headers: this.headers };
    try {
      let result = await fetch(
        `${process.env.REACT_APP_NEW_API_URL}/v1/sellers/landing`,
        INIT
      );
      let response = await result.json();
      this.entityMount(response);

      if (response.detail === "Authentication credentials were not provided.") {
        localStorage.clear();
      }
    } catch (err) {
      localStorage.clear();
      window.console.log(`[Erro] ${err}`);
      return Promise.reject(400);
    }
  }

  entityMount(response) {
    let entityLandingBrands = [];

    response.forEach((item) => {
      entityLandingBrands.push(new LandingBrandsEntity(item));
    });

    this.dispatchSave(entityLandingBrands);
  }

  dispatchSave(entityList) {
    this.dispatch(save(entityList));
  }
}
