import axios from "axios";

export default class CartService {
  addCart(id) {
    const pricelistId = Number(localStorage.getItem("user.pricelist"));
    return axios.post("api/cart/add/", { id, pricelist_id: pricelistId });
  }

  getCart(pageNumber = 1) {
    return axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/cart?page=${pageNumber}`).then((res) => {
      this.totalItems(res.data.results.length);
      return res;
    });
  }

  removeProduct(cartId, productId) {
    return axios.delete(
      `api/cart/item/?cart_id=${cartId}&product_id=${productId}`
    );
  }

  removeAllProducts(cartId) {
    return axios.delete(`api/cart/${cartId}/`);
  }

  addItem(id, quantity) {
    return axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/cart/products/items/${id}`, { quantity });
  }

  finalize(id, message) {
    return axios.post(`${process.env.REACT_APP_NEW_API_URL}/v1/cart/${id}/finalize`, { message });
  }

  totalItems(total) {
    return total;
  }

  getPricelists(cartId) {
    return axios.get(
      `${process.env.REACT_APP_NEW_API_URL}/v1/pricelists?cart_id=${cartId}`
    );
  }

  updatePricelist(cartId, pricelistId) {
    return axios.put(
      `${process.env.REACT_APP_NEW_API_URL}/v1/carts/${cartId}/pricelist`,
      {
        pricelist_id: pricelistId,
      }
    );
  }

  setNote(cartId, productId, note) {
    return axios.post(
      `${process.env.REACT_APP_NEW_API_URL}/v1/cart/${cartId}/products/${productId}/note`,
      { note }
    );
  }
}
