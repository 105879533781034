import axios from "axios";

export default class SegmentService {
    get(){
        return new Promise(async (resolve, reject) => {
            try {
                let response = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/segment`);
                resolve(response.data);
            } catch(ex){
                reject(ex);
            }
        })
    }

    getEvents() {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await axios.get(`${process.env.REACT_APP_NEW_API_URL}/v1/events`);
                resolve(response.data);
            } catch(ex){
                reject(ex);
            }
        })
    }
}