import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthenticationService from "../../../services/AuthenticationService";

function PublicRoute({ component: Component, ...rest }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('token');

  function tokenLogin() {
    localStorage.setItem("temp_token", token);
    AuthenticationService.loginByToken();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        <>
          <Component {...props} />

          {token && tokenLogin()}
        </>
      }
    />
  );
}

export default PublicRoute;
