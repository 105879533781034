import React, {Component} from 'react';
import "../style/SocialMediaStyle.scss"

class SocialMediaComponent extends Component {

    goHref(url) {
        window.open(
            `${url}`,
            '_blank'
        );
    }

    render() {

        return (
            <ul id="social-media">
            {this.props.instagram &&
                <li onClick={() => this.goHref(this.props.instagram)}>
                    <img src={`${process.env.PUBLIC_URL}/images/social-media/ico-instagram.png`} alt="Facebook" />
                </li>
              }
              {this.props.facebook &&
                <li onClick={() => this.goHref(this.props.facebook)}>
                    <img src={`${process.env.PUBLIC_URL}/images/social-media/ico-facebook.png`} alt="Facebook" />
                </li>
              }
              {this.props.site &&
                <li onClick={() => this.goHref(this.props.site)}>
                    <img src={`${process.env.PUBLIC_URL}/images/social-media/ico-website.svg`} alt={this.props.site} />
                </li>
              }
            </ul>
        );
    }
}

export default SocialMediaComponent;
