import React, { Component } from "react";
import "../style/FilterAlphabetBuyersComponent.scss";
import { withRouter } from "react-router";
import BuyersService from "../../../data/pages/buyers/buyersService";

class FilterAlphabetBuyersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alphabet: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      letterSelected: "",
    };
  }

  componentDidMount() {
    const historySearch = this.props.history.location.search;
    const urlSearchParams = new URLSearchParams(historySearch);
    const letter = urlSearchParams.get("letter");

    this.setState({
      letterSelected: letter,
    });
  }

  handleLetterClick(letter) {
    if (letter === this.state.letterSelected) {
      letter = "";
    }

    const historySearch = this.props.history.location.search;
    const urlSearchParams = new URLSearchParams(historySearch);
    const locations = urlSearchParams.get("locations")
      ? urlSearchParams.get("locations").split(",")
      : [];
    urlSearchParams.set("letter", letter);
    urlSearchParams.set("page", 1);

    new BuyersService().getData(urlSearchParams.get("page"), locations, letter);

    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      search: `?${urlSearchParams.toString()}`,
    });

    this.setState({
      letterSelected: letter,
    });
  }

  renderAlphabet() {
    return this.state.alphabet.map((item) => (
      <div
        className={`letter ${
          item === this.state.letterSelected ? "letter--selected" : ""
        }`}
        onClick={() => this.handleLetterClick(item)}
      >
        {item}
      </div>
    ));
  }

  render() {
    return (
      <div id="filterAlphabetBuyers">
        <div
          className={`letter ${
            "#" === this.state.letterSelected ? "letter--selected" : ""
          }`}
          onClick={() => this.handleLetterClick("#")}
        >
          #
        </div>
        {this.renderAlphabet()}
      </div>
    );
  }
}

export default withRouter(FilterAlphabetBuyersComponent);
