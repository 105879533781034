import React, { Component } from "react";

import "../../styles/global.scss";
import "../../../shared/style/home/HomeStyle.scss";
import "../../styles/BuyerPageView.scss";
import MenuComponent from "../../../menu/components/MenuComponent";
import FooterComponent from "../../../shared/footer/components/FooterComponent";
import LoadingComponent from "../../../shared/loading/components/LoadingComponent";
import axios from "axios";
import SocialIconsComponent from "./SocialIconsComponent";
import LocationsComponent from "./LocationsComponent";
import JumboTitleComponent from "../../../shared/jumbotitle/components/JumboTitleComponent";
import ConnectionComponent from "../ConnectionComponent";
import Breadcomb from "../../../menu/components/Breadcomb";
import { withTranslation } from "react-i18next";

class BuyerPageViewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      buyerData: null,
    };
  }

  getBuyerData() {
    axios
      .get(
        `${process.env.REACT_APP_NEW_API_URL}/v1/buyers/${this.props.match.params.id}`
      )
      .then((response) => {
        if (response.data.data) {
          this.setState({
            buyerData: response.data.data,
            loading: false,
          });
        }
      });
  }

  componentDidMount() {
    this.getBuyerData();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="buyer-page-view-component">
        <div className="header-menu-wrapper">
          <MenuComponent />
        </div>
        {this.state.loading && !this.state.buyerData ? (
          <LoadingComponent />
        ) : (
          <div>
            <main id="main">
              <section>
                <Breadcomb
                  links={[
                    {
                      text: t("Find retailers", "Find retailers"),
                      link: true,
                      to: "buyers",
                    },
                    { text: this.state.buyerData.name, link: false },
                  ]}
                />
              </section>
              <section>
                <JumboTitleComponent
                  title={this.state.buyerData.name}
                  className="jumbo-title--breadcomb-top"
                />
              </section>
              <section className="logo">
                {this.state.buyerData.logo && (
                  <img
                    src={this.state.buyerData.logo}
                    alt={this.state.buyerData.name}
                  />
                )}
              </section>
              <ConnectionComponent
                type="buyer"
                subjectId={this.state.buyerData.id}
                primeiraRequest={
                  this.state.buyerData.instrucoes_primeira_request
                }
              />
              <section className={`social-icons-container`}>
                <SocialIconsComponent
                  facebook={this.state.buyerData.facebook}
                  instagram={this.state.buyerData.instagram}
                  website={this.state.buyerData.website}
                />
              </section>

              <section
                className={`centerComponent year-location-container ${this.state.buyerData.locations.length <= 0
                    ? "year-location-container--year-only"
                    : ""
                  }`}
              >
                {this.state.buyerData.locations.length > 0 && (
                  <LocationsComponent
                    locations={this.state.buyerData.locations.map(
                      (item) => item.name
                    )}
                  />
                )}

                {this.state.buyerData.year_established && (
                  <div
                    className={`year-established ${this.state.buyerData.locations.length > 0
                        ? "year-established--end"
                        : ""
                      }`}
                  >
                    <span>
                      <div className="title">
                        {t("YEAR ESTABLISHED", "YEAR ESTABLISHED")}
                      </div>
                      <div className="year">
                        {this.state.buyerData.year_established}
                      </div>
                    </span>
                  </div>
                )}
              </section>

              <section className={`centerComponent buyer-text`}>
                {this.state.buyerData.text}
              </section>

              <FooterComponent />
            </main>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(BuyerPageViewComponent);
