import React from "react";
import "./style.scss";

export default function Loading({ borderColor, borderTopColor }) {
  return (
    <div
      className="loader"
      style={{ borderColor: borderColor, borderTopColor: borderTopColor }}
    ></div>
  );
}
